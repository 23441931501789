import { matchSorter } from 'match-sorter'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import * as React from 'react'
import { useEffect, useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { categoryList } from '../resources/category-list'
import { quotes } from '../resources/quotes'
import projectUtility from '../utility/projectUtility'
import utility from '../utility/utility'
import './Search.scss'

const animatedComponents = makeAnimated()

const Search = (props: any) => {
    useEffect(() => {
        if (props.genres?.value === '.all.' && props.tags?.value === '.all.') {
            //reset them. for example user clicked show another and it was the final matching quote.
            setOptions(getQuotes())
            setGenreOptions(getQuotes(props.categories, props.genres, ['genre']))
        }
    }, [props.genres, props.tags])

    const getQuotes = (categories = props.categories, genres = props.genres, keysToKeep: any = []) => {
        const results: any = []
        const tags = {}

        if (!keysToKeep?.length || keysToKeep?.[0] === 'genre') {
            results.push({
                value: '.all.',
                label: 'All',
            })
        }

        const keys = ['attribution', 'category', 'title', 'genre', 'tag1', 'tag2', 'tag3', 'tag4', 'tag5', 'tag6', 'tag7', 'tag8', 'tag9']

        if (!keysToKeep.length) {
            keysToKeep = keys
        }

        //using a dictionary to avoid duplicates
        for (let quote of quotes) {
            const thingsToCheck = [
                {
                    thisQuoteValues: [quote.category],
                    mustMatch: categories,
                },
                {
                    thisQuoteValues: quote.genre?.split(', ') || [],
                    mustMatch: genres,
                },
            ]

            //should we include this quote?
            const matches = projectUtility.quoteMatches(thingsToCheck)

            if (!matches) {
                //skip it
                continue
            }

            //add the relevant property of this quotes to our list
            for (let key of keysToKeep) {
                const value = quote[key]

                if (key === 'genre') {
                    const parts = value?.split(',')

                    for (let part of parts || []) {
                        if (part.trim()) {
                            tags[part.trim()] = true
                        }
                    }
                } else if (value) {
                    tags[value] = true
                }
            }
        }

        for (let key in tags) {
            results.push({
                value: key,
                label: key,
            })
        }

        //sort alphabetically
        utility.sort(results, 'value')

        return results
    }

    const [options, setOptions] = useState(getQuotes())
    const [genreOptions, setGenreOptions] = useState(getQuotes(props.categories, props.genres, ['genre']))

    const handleChangeCategories = (newCategories: any) => {
        setOptions(getQuotes(newCategories))
        setGenreOptions(getQuotes(newCategories, props.genres, ['genre']))
        props.handleChangeCategories(newCategories)
    }

    const handleChangeGenres = (newGenres: any) => {
        setOptions(getQuotes(props.categories, newGenres))
        props.handleChangeGenres(newGenres)
    }

    const handleChangeTags = (newTags: any) => {
        props.handleChangeTags(newTags)
    }

    return (
        <div className="custom-search">
            <div>
                <label className="d-block grey-text mb-1 mt-3">Choose quote categories</label>

                <MDBContainer className="ps-2">
                    <MDBRow className="align-items-center">
                        <MDBCol size="11" className="ps-1 pe-2">
                            <Select components={animatedComponents} options={categoryList} value={props.categories} name="categories" onChange={handleChangeCategories} />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>

            <div className="mt-3">
                <label className="d-block grey-text mb-1">Choose genres</label>

                <MDBContainer className="ps-2">
                    <MDBRow className="align-items-center">
                        <MDBCol size="11" className="ps-1 pe-2">
                            <Select components={animatedComponents} options={genreOptions} value={props.genres} name="genres" onChange={handleChangeGenres} />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>

            <div className="mt-3">
                <label className="d-block grey-text mb-1">Search people, titles and tags</label>

                <MDBContainer className="ps-2">
                    <MDBRow className="align-items-center">
                        <MDBCol size="11" className="ps-1 pe-2">
                            <Select
                                components={animatedComponents}
                                options={options}
                                value={props.tags}
                                name={props.categories?.[0]?.value}
                                onChange={handleChangeTags}
                                onInputChange={(inputValue) => {
                                    setOptions(matchSorter(getQuotes(), inputValue, { keys: ['label'] }))
                                }}
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        </div>
    )
}

export default Search
