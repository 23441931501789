import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './Header.scss'
import { MDBBadge, MDBBtn, MDBCol, MDBContainer, MDBRow, MDBTooltip } from 'mdb-react-ui-kit'
import Icon from '../components/Icon'

const Header = (props: any) => {
    return (
        <MDBContainer fluid className="header mt-4">
            <MDBRow className="justify-content-between align-items-center">
                <MDBCol size="8" className="d-flex align-items-center">
                    <a href="/">
                        <StaticImage src="../images/logos/logo-4c.png" alt="logo" width={210} />
                    </a>
                    <span className="fs-4 ms-2 pt-3 greeting">
                        Hello there{props.username && <span className="name">, {props.username}</span>}
                    </span>
                </MDBCol>
                <MDBCol size="4" className="d-flex justify-content-end align-items-center pt-3">
                    <MDBBtn
                        color="dark"
                        outline
                        size="sm"
                        className="me-2 log-in-button d-none d-lg-inline"
                        href="/my-account/orders/"
                    >
                        Check on Your Order / Log In
                    </MDBBtn>

                    {props.cartItemCount > 0 && (
                        <MDBTooltip tag="span" title="View your cart">
                            <a href="/cart" className="ms-2 pe-3">
                                <Icon icon="shopping-cart" className="fa-lg medium-color" />
                                <MDBBadge color="danger" notification pill>
                                    {props.cartItemCount}
                                </MDBBadge>
                            </a>
                        </MDBTooltip>
                    )}
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default Header
