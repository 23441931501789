import { toBlob } from 'html-to-image'
import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow, MDBSpinner, MDBSwitch, MDBTooltip } from 'mdb-react-ui-kit'
import * as React from 'react'
import { SyntheticEvent, useRef, useState } from 'react'
import Icon from '../components/Icon'
import apronBlack from '../images/apron-black.jpg'
import apronBlue from '../images/apron-blue.jpg'
import clearImage from '../images/clear.png'
import like from '../images/face-with-tears-of-joy_1f602.png'
import logo4 from '../images/logo-4c/4.svg'
import { quotes } from '../resources/quotes'
import apiManager from '../utility/apiManager'
import projectUtility from '../utility/projectUtility'
import utility from '../utility/utility'
import ColorButtons from './ColorButtons'
import ConfirmModal from './ConfirmModal'
import ItemPreview from './ItemPreview'
import LoginModal from './LoginModal'
import NamesSelect from './NamesSelect'
import './OrderStep2.scss'
import QuotesModal from './QuotesModal'
import Search from './Search'

const apronBackgroundImages = {
    black: apronBlack,
    blue: apronBlue,
} as any

const defaultHeight = 175

const OrderStep2 = (props: any) => {
    const defaultForSelect = { value: '.all.', label: 'All' }
    const [shuffled, setShuffled] = useState(false)
    const [quoteIndex, setQuoteIndex] = useState(0)
    const [matchingLength, setMatchingLength] = useState(0)
    const [previousCategories, setPreviousCategories] = useState(props.categories)
    const [previousGenres, setPreviousGenres] = useState(props.genres)
    const [previousTags, setPreviousTags] = useState(props.tags)
    const [clickedSelectQuote, setClickedSelectQuote] = useState(false)
    const [quotesByName, setQuotesByName] = useState(props.customizeState?.quotesByName || {})
    const [chosenQuoteObject, setChosenQuoteObject] = useState(initializeQuoteObject) as any
    const [companyLogo, setCompanyLogo] = useState('')
    const [photo, setPhoto] = useState('')
    const [photoSize, setPhotoSize] = useState('50')
    const [fontFamily, setFontFamily] = useState(props.customizeState?.fontFamily || 'Aftika')
    const quoteInputRef: any = useRef()
    const mainCustomizeArea: any = useRef()
    const [quote, setQuote] = useState(chosenQuoteObject?.quote)
    const [customAttribution, setCustomAttribution] = useState(utility.get(props.customizeState, 'customAttribution', ''))
    const [showCreateOwnQuote, setShowCreateOwnQuote] = useState(utility.get(props.customizeState, 'showCreateOwnQuote', false))
    const [clickedRemoveQuote, setClickedRemoveQuote] = useState(false)
    const [quoteSize, setQuoteSize] = useState(utility.get(props.customizeState, 'quoteSize', '75'))
    const [quoteHeightString, setQuoteHeightString] = useState(utility.get(props.customizeState, 'quoteHeightString', `${parseInt(((75 / 100) * defaultHeight) as any)}px`))
    const [attributionSize, setAttributionSize] = useState(utility.get(props.customizeState, 'attributionSize', '75'))
    const [recipientNameSize, setRecipientNameSize] = useState(utility.get(props.customizeState, 'recipientNameSize', '75'))
    const [donation, setDonation] = useState(utility.get(props.customizeState, 'donation'))
    const [chosenLogoImage, setChosenLogoImage] = useState(logo4)
    const [showAttribution, setShowAttribution] = useState(utility.get(props.customizeState, 'showAttribution', true))
    const [showTitle, setShowTitle] = useState(utility.get(props.customizeState, 'showTitle', false))
    const [likeCount, setLikeCount] = useState(getCount(chosenQuoteObject, 'likeCount'))
    const [currentLikeType, setCurrentLikeType] = useState(utility.getNested(props, ['likes', chosenQuoteObject?.id, 'userLikeType'], 0))
    const [loading, setLoading] = useState(false)
    const [addToCartLoading, setAddToCartLoading] = useState(false)
    const [addedToCart, setAddedToCart] = useState(false)
    const [message, setMessage] = useState('')
    const [addToCartMessage, setAddToCartMessage] = useState('')
    const [basicModal, setBasicModal] = useState(false)
    const [loginModal, setLoginModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [allReadyModal, setAllReadyModal] = useState(false)
    const [showedAllReadyModal, setShowedAllReadyModal] = useState(false)
    const [removedItem, setRemovedItem] = useState(false)
    const [shouldSaveState, setShouldSaveState] = useState(false)

    const elementToTakeImageOf = useRef<HTMLDivElement>(null)
    const elementsToTakeImagesOf = useRef([]) as any

    const logoUploader = useRef(null as any)
    const imageUploader = useRef(null as any)

    const categoriesChanged = previousCategories && previousCategories !== props.categories
    const genresChanged = previousGenres && previousGenres !== props.genres
    const tagsChanged = previousTags && previousTags !== props.tags

    if (categoriesChanged || genresChanged || tagsChanged) {
        utility.log('changed')

        setQuoteIndex(0)
        setRandomText(true)
        setPreviousCategories(props.categories)
        setPreviousGenres(props.genres)
        setPreviousTags(props.tags)
    }

    function initializeQuoteObject() {
        if (props.customizeState?.showCreateOwnQuote) {
            getRandomText()

            setClickedSelectQuote(true)

            return {
                quote: props.customizeState?.quote,
            }
        } else if (props.customizeState?.chosenQuoteObject) {
            setQuoteIndex(0)
            setMatchingLength(quotes.length)

            if (numberOfSelected() == props.namesList.length) {
                setClickedSelectQuote(true)
            }

            return props.customizeState?.chosenQuoteObject
        }

        return getRandomText()
    }

    function isAll(selectedItem: any) {
        return selectedItem?.value === '.all.'
    }

    function getRandomText(forceReset = false, increment = false, resetTags = false): any {
        utility.log('getRandomText')

        if (!shuffled) {
            utility.shuffle(quotes)
            setShuffled(true)
        }

        let genres = props.genres
        let tags = props.tags

        if (resetTags) {
            tags = defaultForSelect
            genres = defaultForSelect
        }

        const matching = quotes.filter((quote) => {
            //all filters are blank?

            //for multiple select
            if (Array.isArray(props.categories)) {
                if (!props.categories.length && !genres.length && !tags.length) {
                    return true
                }
            } else {
                if (isAll(props.categories) && isAll(genres) && isAll(tags)) {
                    return true
                }
            }

            const thingsToCheck = [
                {
                    thisQuoteValues: [quote.category],
                    mustMatch: props.categories,
                },
                {
                    thisQuoteValues: quote.genre?.split(', ') || [],
                    mustMatch: genres,
                },
                {
                    thisQuoteValues: Object.values(quote) as any[],
                    mustMatch: tags,
                },
            ]

            return projectUtility.quoteMatches(thingsToCheck)
        })

        setMatchingLength(matching.length)

        if (!matching || !matching.length) {
            return {}
        }

        let index = quoteIndex

        if (increment) {
            index++
        }

        if (index >= matching.length || forceReset) {
            //start over
            index = 0
        }

        const result = matching[index]
        setQuoteIndex(index)

        return result
    }

    function getCount(quoteObject: any, type: string) {
        return utility.getNested(props, ['likes', quoteObject?.id, type], 0)
    }

    const handleImageUpload = (e: any, setFunction: any = null) => {
        const [file] = e.target.files
        if (file) {
            const reader = new FileReader()
            reader.onload = (e: any) => {
                if (setFunction) {
                    setFunction(e?.target?.result)
                } else {
                    setPhoto(e?.target?.result)
                }
            }
            reader.readAsDataURL(file)
        }
    }

    const handleLogoUpload = (e: any) => {
        handleImageUpload(e, setCompanyLogo)
    }

    function changeQuoteHeight(newQuoteSize = quoteSize) {
        const newHeight = `${(parseInt(newQuoteSize) / 100) * defaultHeight}px`

        setQuoteHeightString(newHeight)
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault()

        let isAddToCart = false

        //pressed add to cart button
        if (event?.nativeEvent?.submitter?.name === 'addToCart') {
            isAddToCart = true
        }

        setChosenLogoImage(clearImage)

        const success = await addToCart(isAddToCart)

        saveCustomizeState()

        if (success) {
            if (isAddToCart) {
                setAddedToCart(true)
                props.handleAddedToCart()
            } else {
                props.handleSubmit(event)
            }
        }

        setTimeout(() => {
            setChosenLogoImage(logo4)
        }, 3000)
    }

    const storeLike = async (likeType: number, objectId: number) => {
        const response = await apiManager.like(likeType, objectId)

        if (likeType === 0) {
            if (response?.like !== null) {
                utility.log('like error')
            }
        } else {
            if (!response?.like?.id) {
                utility.log('like error')
            }
        }
    }

    const handleClickLike = (type: number, idToLike: number = 0) => {
        if (!props.user?.id) {
            setLoginModal(true)
            return
        }

        let id = chosenQuoteObject?.id

        if (idToLike) {
            id = idToLike
        }

        const currentType = utility.getNested(props, ['likes', id, 'userLikeType'], 0)
        const isUndo = type === currentType
        const destinationType = isUndo ? 0 : type

        const response = props.handleChangeLikes(destinationType, id, isUndo)

        if (id && id === chosenQuoteObject?.id) {
            if (response?.likeCount !== undefined) {
                setLikeCount(utility.get(response, 'likeCount', 0))
            }

            setCurrentLikeType(destinationType)
        }

        storeLike(destinationType, id)

        if (!idToLike && destinationType === -1) {
            setTimeout(() => {
                setRandomText(false, true)
            }, 250)
        }
    }

    const handleClickRemoveQuoteOrLike = (quoteObject: any, isCustom: boolean) => {
        const id = quoteObject?.id ? quoteObject?.id : quoteObject?.objectId

        if (!isCustom) {
            handleClickLike(1, id)
        }

        props.handleClickRemoveQuoteOrLike(id, isCustom)
    }

    const handleClickHistory = () => {
        if (!props.user?.id) {
            setLoginModal(true)
            return
        }

        setBasicModal(true)
    }

    function saveCustomizeStateLater() {
        setShouldSaveState(true)
    }

    const handleClickUseQuote = (quoteToUse: any, isCustom: boolean) => {
        if (isCustom) {
            setQuote(quoteToUse.text)
            setShowCreateOwnQuote(true)
            setShowTitle(false)
        } else {
            if (showCreateOwnQuote) {
                setShowTitle(true)
            }

            setShowCreateOwnQuote(false)

            const quoteObject = quotes.find((element) => element?.id === quoteToUse?.objectId)
            setQuoteObject(quoteObject)
        }

        setBasicModal(false)
        setClickedSelectQuote(true)
        mainCustomizeArea?.current?.scrollIntoView({ behavior: 'smooth' })

        saveCustomizeStateLater()
    }

    const storeQuote = async (text: string) => {
        //avoid duplicates
        for (let id in props.quotes) {
            if (props.quotes[id]?.text === text) {
                return
            }
        }

        props.addCustomQuote(text)

        const response = await apiManager.quote(text)

        if (utility.getNested(response, ['quote', 'id'])) {
            utility.log('quote error')
        }
    }

    function handleClickSelectQuote() {
        setQuoteObject(chosenQuoteObject)
        setClickedSelectQuote(true)
        setShowCreateOwnQuote(false)

        if (showCreateOwnQuote) {
            setShowTitle(true)
        }

        if (props.namesList?.length) {
            const newQuotesByName = { ...quotesByName }
            newQuotesByName[props.name] = chosenQuoteObject
            setQuotesByName(newQuotesByName)

            //no need to go to next one if they're all done
            if (!showedAllReadyModal) {
                const index = props.namesList.indexOf(props.name)

                const newName = utility.get(props.namesList, index + 1)

                if (newName) {
                    props.changeName(newName)
                }
            }

            if (numberOfSelected(newQuotesByName) === props.namesList?.length) {
                if (showedAllReadyModal) {
                    onAllApronsReady()
                } else {
                    setAllReadyModal(true)
                    setShowedAllReadyModal(true)
                }
            }
        } else {
            mainCustomizeArea?.current?.scrollIntoView({ behavior: 'smooth' })
        }

        saveCustomizeStateLater()
    }

    function handleClickSelectQuoteForEveryone() {
        const newQuotesByName = { ...quotesByName }

        for (const name of props.namesList) {
            newQuotesByName[name] = chosenQuoteObject
        }

        setQuotesByName(newQuotesByName)

        onAllApronsReady()

        saveCustomizeStateLater()
    }

    function onAllApronsReady() {
        setShowedAllReadyModal(true)
        setClickedSelectQuote(true)
        mainCustomizeArea?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const handleClickShowAnother = () => {
        let resetTags = false

        if (matchingLength <= 1) {
            //so the user can always press the show another button
            props.handleChangeGenres(defaultForSelect)
            props.handleChangeTags(defaultForSelect)
            resetTags = true
        }

        setCustomAttribution('')
        setRandomText(false, true, resetTags)
        setShowCreateOwnQuote(false)
    }

    const handleClickReset = () => {
        props.mainHeading?.current?.scrollIntoView({ behavior: 'smooth' })
        props.handleChangeTags(defaultForSelect)
        props.handleChangeCategories(defaultForSelect, true)
        props.handleChangeColor({ target: { name: 'black' } })

        setClickedSelectQuote(false)
        setShowCreateOwnQuote(false)
        setQuoteIndex(0)
        setCustomAttribution('')
        setShowAttribution(true)
        setShowTitle(false)
        setRandomText(true, false, false, true)
        setAddedToCart(false)
        setPhoto('')
        setFontFamily('Aftika')
        setDonation('')

        setPhotoSize('50')
        setQuoteSize('75')
        setAttributionSize('75')
        setRecipientNameSize('75')

        changeQuoteHeight()

        localStorage.setItem('customizeState', '{}')
    }

    function handleClickChooseText() {
        handleClickSelectQuote()
        setQuote('')

        setShowCreateOwnQuote(true)
        setShowTitle(false)
        quoteInputRef?.current?.focus()

        saveCustomizeStateLater()
    }

    const handleClickRemoveCustomQuote = () => {
        setShowCreateOwnQuote(false)
        setRandomText()
        setClickedRemoveQuote(!clickedRemoveQuote)
        setCustomAttribution('')
        props.mainHeading?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    function setRandomText(forceReset = false, increment = false, resetTags = false, isReset = false) {
        const randomText = getRandomText(forceReset, increment, resetTags)
        setQuoteObject(randomText)

        if (!isReset) {
            saveCustomizeStateLater()
        }
    }

    function setQuoteObject(quoteObject) {
        setChosenQuoteObject(quoteObject)
        setQuote(utility.get(quoteObject, 'quote'))
        setLikeCount(getCount(quoteObject, 'likeCount'))

        const type = utility.getNested(props, ['likes', quoteObject?.id, 'userLikeType'], 0)
        setCurrentLikeType(type)
    }

    const handleChangeQuote = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement
        const newQuote = target.value ? target.value : ''
        setQuote(newQuote)

        if (props.namesList?.length) {
            const newQuotesByName = { ...quotesByName }

            newQuotesByName[props.name] = {
                quote: newQuote,
            }

            setQuotesByName(newQuotesByName)
        }

        saveCustomizeStateLater()
    }

    const handleChangeCustomAttribution = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement
        const newValue = target.value ? target.value : ''
        setCustomAttribution(newValue)

        saveCustomizeStateLater()
    }

    const handleClickRemovePhoto = (isCompanyLogo = false) => {
        if (isCompanyLogo) {
            setCompanyLogo('')
        } else {
            setPhoto('')
        }

        changeQuoteHeight()
    }

    const handleSwitchChange = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement

        if (target.name === 'showAttribution') {
            setShowAttribution(target.checked)
        } else if (target.name === 'showTitle') {
            setShowTitle(target.checked)
        }

        saveCustomizeStateLater()
    }

    const onChangeSelect = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement

        if (target.name === 'photoSize') {
            setPhotoSize(target.value)
        } else if (target.name === 'fontFamily') {
            setFontFamily(target.value)
            setTimeout(() => {
                changeQuoteHeight()
            })
        } else if (target.name === 'quoteSize') {
            setQuoteSize(target.value)
            changeQuoteHeight(target.value)
        } else if (target.name === 'attributionSize') {
            setAttributionSize(target.value)
        } else if (target.name === 'recipientNameSize') {
            setRecipientNameSize(target.value)
        } else if (target.name === 'donation') {
            setDonation(target.value)
        }

        saveCustomizeStateLater()
    }

    const handleChange = (event: SyntheticEvent | null, name: String, newItems: any = null) => {
        if (name === 'name') {
            props.handleChangeName(event)
        } else if (name === 'color') {
            props.handleChangeColor(event)
        } else if (name === 'categories') {
            props.handleChangeCategories(newItems)
        } else if (name === 'genres') {
            props.handleChangeGenres(newItems)
        } else if (name === 'tags') {
            props.handleChangeTags(newItems)
        }

        saveCustomizeStateLater()
    }

    const getImage = async (element: HTMLDivElement) => {
        let blob: any = null

        if (!element) {
            return blob
        }

        try {
            //pixelRatio makes the output resolution and image quality x times hihger
            blob = await toBlob(element, { cacheBust: true, pixelRatio: 5 })
        } catch (error: any) {
            utility.log(error)
        }

        return blob
    }

    const addFile = (data: FormData, blob: Blob, index: number, recipientName: string) => {
        const date = utility.printableDate(true)
        const nameForFileName = utility.makeId(recipientName, 30)
        const quoteForFileName = utility.makeId(quote, 30)
        let shortUsername = utility.makeId(props.user?.fullName, 30)

        if (!shortUsername) {
            shortUsername = 'guest'
        }

        //the server will automatically change the filename if it already exists
        if (props.namesList?.length) {
            //php needs the [] in order to know it's an array of file uploads
            const paddedNumber = String(index + 1).padStart(4, '0')
            data.append('addon-12-images-3[]', blob, `${shortUsername}-${paddedNumber}-${quoteForFileName}-${nameForFileName}.png`)
        } else {
            data.append('addon-12-image-1', blob, `${date}-${shortUsername}-${quoteForFileName}-${nameForFileName}.png`)
        }
    }

    const downloadFile = (blob: any, fileName: string) => {
        var data = new Blob([blob], { type: 'image/png' })
        var csvURL = window.URL.createObjectURL(data)
        const tempLink = document.createElement('a')
        tempLink.href = csvURL
        tempLink.setAttribute('download', `${fileName}.png`)
        tempLink.click()
    }

    const saveCustomizeState = () => {
        const newCustomizeState = {
            chosenQuoteObject,
            color: props.color,
            showAttribution,
            showTitle,
            fontFamily,
            quoteSize,
            quoteHeightString,
            attributionSize,
            recipientNameSize,
            name: props.name,
            names: props.names,
            namesList: props.namesList,
            quotesByName,
            donation,
            showCreateOwnQuote,
            quote,
        }

        localStorage.setItem('customizeState', JSON.stringify(newCustomizeState))
    }

    React.useEffect(() => {
        console.log('shouldSaveState')
        if (shouldSaveState) {
            console.log('saving from later')
            saveCustomizeState()
            setShouldSaveState(false)
        }
    }, [shouldSaveState])

    React.useEffect(() => {
        setLikeCount(getCount(chosenQuoteObject, 'likeCount'))
        setCurrentLikeType(utility.getNested(props, ['likes', chosenQuoteObject?.id, 'userLikeType'], 0))
    }, [props.likes])

    const addToCart = async (isAddToCart: boolean) => {
        let loadingFunction = setLoading
        let messageFunction = setMessage
        let successMessage = 'Going to checkout...'

        if (isAddToCart) {
            loadingFunction = setAddToCartLoading
            messageFunction = setAddToCartMessage
            successMessage = 'Success'
        }

        loadingFunction(true)

        await removeFromCart()

        if (showCreateOwnQuote) {
            storeQuote(quote)
        }

        if (!elementToTakeImageOf.current) {
            await utility.sleep(0)
            loadingFunction(false)
            return
        }

        let data = new FormData()
        let blob = null
        let elements = [elementToTakeImageOf.current]

        if (elementsToTakeImagesOf?.current?.length) {
            elements = elementsToTakeImagesOf.current
        }

        for (let i = 0; i < elements.length; i++) {
            blob = await getImage(elements[i])

            if (!blob) {
                loadingFunction(false)
                messageFunction('Something went wrong')
                return
            }

            const recipientName = utility.get(props.namesList, i) ? utility.get(props.namesList, i) : props.name
            addFile(data, blob, i, recipientName)

            if (utility.debug) {
                downloadFile(blob, String(i))
            }
        }

        const variationIds = {
            black: '35',
            blue: '205',
        }

        let quantity = '1'

        if (props.namesList?.length) {
            quantity = String(props.namesList?.length)
        }

        data.append('attribute_pa_color', props.color)
        data.append('attribute_donation', donation || 'Kenneth Gordon Maplewood School')
        data.append('quantity', quantity)
        data.append('add-to-cart', '12')
        data.append('product_id', '12')
        data.append('variation_id', utility.get(variationIds, props.color))

        const response = await utility.requestPlainText('/product/apron/', data)

        const success = response.includes('added to your cart')
        let shouldStopLoadingNow = true

        if (!success) {
            messageFunction('Something went wrong')
        } else {
            messageFunction(successMessage)

            if (!isAddToCart) {
                shouldStopLoadingNow = false
            }
        }

        if (shouldStopLoadingNow) {
            loadingFunction(false)
        } else {
            setTimeout(() => loadingFunction(false), 5000)
        }

        setTimeout(() => messageFunction(''), 5000)

        return success
    }

    const removeFromCart = async () => {
        if (removedItem || !window?.location?.search) {
            await utility.sleep(0)
            return
        }

        const searchParams = new URLSearchParams(window?.location?.search)
        const cartItemId = searchParams.get('id')

        if (!cartItemId) {
            await utility.sleep(0)
            return
        }

        const postBody = {
            cartItemId: cartItemId,
        }

        //need to wait otherwise the item we remove might still show up in the checkout page
        await utility.request('/custom/api.php?mode=removeCartItem', postBody)

        setRemovedItem(true)
    }

    const handleChangeName = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement
        const name = target.value

        const quoteObject = quotesByName[name]

        if (quoteObject) {
            setQuoteObject(quoteObject)
        }

        props.handleChangeName(event)
    }

    const addRef = (index: number, element: any) => {
        if (index >= elementsToTakeImagesOf.current.length) {
            elementsToTakeImagesOf.current.push(element)
        } else {
            elementsToTakeImagesOf.current[index] = element
        }
    }

    function PreviewColumn(props: any) {
        return (
            <MDBCol
                size="md-6"
                className={`mb-3 text-center rounded apron-background d-flex justify-content-center flex-wrap ${props.columnClass || ''}`}
                style={{
                    backgroundImage: `url(${apronBackgroundImages[props.color]})`,
                }}
            >
                <ItemPreview
                    {...props}
                    name={props?.name}
                    index={props?.index}
                    elementToTakeImageOf={props?.elementToTakeImageOf}
                    addRef={addRef}
                    companyLogo={companyLogo}
                    chosenLogoImage={chosenLogoImage}
                    quoteHeightString={quoteHeightString}
                    quote={props?.quote || quote}
                    showCreateOwnQuote={showCreateOwnQuote}
                    customAttribution={customAttribution}
                    showAttribution={showAttribution}
                    attributionSize={attributionSize}
                    chosenQuoteObject={chosenQuoteObject}
                    showTitle={showTitle}
                    recipientNameSize={recipientNameSize}
                    photo={photo}
                    photoSize={photoSize}
                    fontFamily={fontFamily}
                />
            </MDBCol>
        )
    }

    function Test(props) {
        return <PreviewColumn {...props} />
    }

    return (
        <div className="order-step-2">
            <ConfirmModal
                title="Use this quote on all aprons?"
                message="You can still change each apron's quote individually after."
                message2="Next you can customize more options such as text size and adding your logo. Changes apply to all aprons. Only the quote and name can be different for each apron."
                okButtonText="Apply to all aprons"
                modal={confirmModal}
                setModal={setConfirmModal}
                onCancel={() => setConfirmModal(false)}
                onOk={handleClickSelectQuoteForEveryone}
            />

            <ConfirmModal
                title="Good job, all aprons have a quote!"
                message="You can change more options below such as text size and adding your logo. Changes apply to all aprons. Only the quote and name can be different for each apron."
                cancelText={null}
                modal={allReadyModal}
                setModal={setAllReadyModal}
                onCancel={() => setAllReadyModal(false)}
                onOk={onAllApronsReady}
            />

            <LoginModal loginModal={loginModal} setLoginModal={setLoginModal} onCloseLoginModal={() => setLoginModal(false)} onLogin={props.onLogin} />

            <QuotesModal
                basicModal={basicModal}
                setBasicModal={setBasicModal}
                onCloseBasicModal={() => setBasicModal(false)}
                customQuotes={props.customQuotes}
                likes={props.likes}
                quotes={quotes}
                user={props.user}
                handleClickUseQuote={handleClickUseQuote}
                handleClickRemoveQuoteOrLike={handleClickRemoveQuoteOrLike}
            />

            {props.namesList?.length > 0 && (
                <>
                    <MDBTooltip tag="span" title="Only this person's apron will use this quote." className="pb-5">
                        <MDBBtn className="mx-1 mt-2 quote-preview-button" color="primary" outline size="lg" onClick={handleClickSelectQuote}>
                            <Icon icon="arrow-right" />
                            Use for {utility.firstName(props.name)}
                        </MDBBtn>
                    </MDBTooltip>
                    <MDBTooltip tag="span" title="Apply this quote to each person's apron. You can still change each apron's quote individually after." className="pb-5">
                        <MDBBtn className="mx-1 mt-2 quote-preview-button" color="success" outline size="lg" onClick={() => setConfirmModal(true)}>
                            <Icon icon="arrow-right" />
                            Use for everyone
                        </MDBBtn>
                    </MDBTooltip>
                </>
            )}

            {props.namesList?.length === 0 && (
                <MDBBtn className="mx-1 mt-2 quote-preview-button" color="success" outline size="lg" onClick={handleClickSelectQuote}>
                    <Icon icon="arrow-right" />
                    That's the one
                </MDBBtn>
            )}
            <MDBBtn className="mx-1 mt-2 quote-preview-button" color="danger" outline size="lg" onClick={handleClickShowAnother}>
                <Icon icon="sync" />
                Show another
            </MDBBtn>
            <MDBBtn className="mx-1 mt-2 quote-preview-button" color="dark" outline size="lg" onClick={handleClickChooseText}>
                <Icon fas icon="pen" />
                Create my own
            </MDBBtn>

            {Object.keys(props.customizeState)?.length > 0 && (
                <MDBTooltip tag="span" title="Reset this page to the defaults" className="pb-4">
                    <MDBBtn className="mx-1 mt-2" color="warning" outline size="lg" onClick={handleClickReset}>
                        <Icon icon="undo" />
                        Reset page
                    </MDBBtn>
                </MDBTooltip>
            )}

            {props.namesList?.length > 0 && numberOfSelected() > 0 && (
                <MDBTooltip tag="span" title="How many people in the list you've selected a quote for">
                    <div className="d-inline-block mx-1 my-2 text-center quote-preview-button">
                        {numberOfSelected()} of {props.namesList.length} aprons ready
                    </div>
                </MDBTooltip>
            )}

            <Votes />

            <MDBRow className="ms-md-1">
                <PreviewColumn {...props} columnClass="mt-3" elementToTakeImageOf={elementToTakeImageOf} />
            </MDBRow>

            <MDBRow>
                <MDBCol className="px-0">
                    <p className="mt-3 mb-0 fs-5 fw-bold medium-color">Filters</p>
                </MDBCol>
            </MDBRow>

            <div className="search-container" style={{ zIndex: 1021, position: 'relative' }}>
                <Search
                    categories={props.categories}
                    genres={props.genres}
                    tags={props.tags}
                    handleChangeCategories={(newCategories: any) => handleChange(null, 'categories', newCategories)}
                    handleChangeGenres={(newGenres: any) => handleChange(null, 'genres', newGenres)}
                    handleChangeTags={(newTags: any) => handleChange(null, 'tags', newTags)}
                />
            </div>

            <MDBContainer ref={mainCustomizeArea} fluid className="px-0 mt-2 pt-2" style={{ opacity: `${clickedSelectQuote ? 100 : 0}` }}>
                <MDBRow>
                    <MDBCol className="px-0">
                        <p className="my-3 fs-5 fw-bold medium-color">Edit</p>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <Test {...props} />
                    <MDBCol size="md-6" className="mb-4">
                        <form className="text-start" onSubmit={handleSubmit}>
                            <NamesSelect name={props.name} namesList={props.namesList} quotesByName={quotesByName} showCount={false} handleChangeName={handleChangeName} />

                            {!props.namesList?.length && (
                                <div>
                                    <MDBTooltip tag="span" title="Mom? Dad? Grandpa? Add their name, or a nickname!" className="pb-3">
                                        <MDBInput
                                            name="name"
                                            label="Who is this apron for?"
                                            type="text"
                                            size="lg"
                                            wrapperClass="recipient-name-input"
                                            className="d-inline-block me-1"
                                            value={props.name}
                                            onChange={(e: SyntheticEvent) => handleChange(e, 'name')}
                                        />
                                    </MDBTooltip>
                                </div>
                            )}

                            <div className="mt-3">
                                <div className="ms-1">
                                    <ColorButtons color={props.color} handleChangeColor={(e: SyntheticEvent) => handleChange(e, 'color')} />
                                </div>
                            </div>

                            {showCreateOwnQuote && (
                                <>
                                    <div className="mt-3">
                                        <MDBInput
                                            ref={quoteInputRef}
                                            name="name"
                                            label="Your custom quote"
                                            type="text"
                                            size="lg"
                                            wrapperClass="custom-quote-input"
                                            className="d-inline-block me-1"
                                            value={quote}
                                            onChange={handleChangeQuote}
                                            autoFocus={quote === ''}
                                        />

                                        <MDBTooltip tag="span" title="Remove and go back to our default quotes">
                                            <MDBBtn tag="a" color="none" className="m-1 pb-1 medium-color" onClick={handleClickRemoveCustomQuote}>
                                                <Icon icon="times" />
                                            </MDBBtn>
                                        </MDBTooltip>
                                    </div>
                                    <div className="mt-3">
                                        <MDBInput
                                            name="customAttribution"
                                            label="Artist"
                                            type="text"
                                            size="lg"
                                            wrapperClass="custom-quote-input"
                                            className="d-inline-block me-1"
                                            value={customAttribution}
                                            onChange={handleChangeCustomAttribution}
                                        />
                                    </div>
                                </>
                            )}

                            {props.namesList?.length > 0 && (
                                <div className="mt-3">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onInput={handleLogoUpload}
                                        ref={logoUploader}
                                        style={{
                                            display: 'none',
                                        }}
                                    />
                                    <MDBBtn type="button" className="mt-1 me-1" color="dark" outline size="lg" onClick={() => logoUploader.current.click()}>
                                        <Icon icon="upload" className="me-2" />
                                        Upload Company Logo
                                    </MDBBtn>
                                    {companyLogo && (
                                        <MDBTooltip tag="span" title="Remove company logo">
                                            <MDBBtn tag="a" color="none" className="m-1 pb-1 medium-color" onClick={() => handleClickRemovePhoto(true)}>
                                                <Icon icon="times" />
                                            </MDBBtn>
                                        </MDBTooltip>
                                    )}
                                    <div className="small text-muted mt-1">Adds your logo to the top left corner of each apron</div>
                                </div>
                            )}

                            <div className="mt-3">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onInput={handleImageUpload}
                                    ref={imageUploader}
                                    style={{
                                        display: 'none',
                                    }}
                                />
                                <MDBBtn type="button" className="mt-1 me-1" color="dark" outline size="lg" onClick={() => imageUploader.current.click()}>
                                    <Icon icon="upload" className="me-2" />
                                    Upload Photo
                                </MDBBtn>
                                {photo && (
                                    <MDBTooltip tag="span" title="Remove photo">
                                        <MDBBtn tag="a" color="none" className="m-1 pb-1 medium-color" onClick={() => handleClickRemovePhoto(false)}>
                                            <Icon icon="times" />
                                        </MDBBtn>
                                    </MDBTooltip>
                                )}
                                <div className="small text-muted mt-1">Add a photo to personalize even more!</div>
                            </div>

                            {photo && (
                                <div className="mt-3">
                                    <label className="mb-1 me-2">Photo size</label>
                                    <select name="photoSize" className="form-select d-inline-block" value={photoSize} onChange={onChangeSelect} disabled={photo === ''}>
                                        <option value="100">large</option>
                                        <option value="75">medium</option>
                                        <option value="50">small</option>
                                        <option value="25">very small</option>
                                    </select>
                                </div>
                            )}

                            <div className="mt-2 me-4 d-inline-block">
                                <MDBTooltip tag="span" title="Show/hide original author's name">
                                    <MDBSwitch
                                        name="showAttribution"
                                        checked={showAttribution}
                                        onChange={handleSwitchChange}
                                        disabled={utility.get(chosenQuoteObject, 'attribution') === ''}
                                        label="Artist"
                                    />
                                </MDBTooltip>
                            </div>

                            <div className="mt-2 me-4 d-inline-block">
                                <MDBTooltip tag="span" title="Show/hide the movie or song title">
                                    <MDBSwitch
                                        name="showTitle"
                                        checked={showTitle}
                                        onChange={handleSwitchChange}
                                        disabled={utility.get(chosenQuoteObject, 'attribution') === ''}
                                        label="Title"
                                    />
                                </MDBTooltip>
                            </div>

                            <div className="mt-2 ms-1 d-inline-block">
                                <label className="mb-1">Font</label>
                                <select name="fontFamily" className="form-select ms-2 d-inline-block" style={{ width: 175 }} value={fontFamily} onChange={onChangeSelect}>
                                    <option value="Aftika">Aftika</option>
                                    {projectUtility.fonts.map((name: string) => (
                                        <option key={name} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <MDBRow className="mt-3 mx-0 mb-0 p-0" style={{ width: '470px' }}>
                                <MDBCol className="px-0 g-0">
                                    <label className="mb-1">Quote font size</label>
                                    <select name="quoteSize" className="form-select d-inline-block" style={{ width: 125 }} value={quoteSize} onChange={onChangeSelect}>
                                        <option value="100">large</option>
                                        <option value="75">medium</option>
                                        <option value="50">small</option>
                                        <option value="25">very small</option>
                                    </select>
                                </MDBCol>
                                <MDBCol className="px-0">
                                    <label className="mb-1">Artist font size</label>
                                    <select name="attributionSize" className="form-select d-inline-block" style={{ width: 125 }} value={attributionSize} onChange={onChangeSelect}>
                                        <option value="100">large</option>
                                        <option value="75">medium</option>
                                        <option value="50">small</option>
                                        <option value="25">very small</option>
                                    </select>
                                </MDBCol>
                                <MDBCol className="px-0">
                                    <label className="mb-1">Recipient font size</label>
                                    <select
                                        name="recipientNameSize"
                                        className="form-select d-inline-block"
                                        style={{ width: 125 }}
                                        value={recipientNameSize}
                                        onChange={onChangeSelect}
                                    >
                                        <option value="100">large</option>
                                        <option value="75">medium</option>
                                        <option value="50">small</option>
                                        <option value="25">very small</option>
                                    </select>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="mt-3 mx-0 mb-0 p-0" style={{ width: '470px' }}>
                                <MDBCol className="px-0 g-0">
                                    <MDBTooltip tag="span" title="We donate $3 from every apron order! You pick the charity:">
                                        <label className="mb-1">
                                            Donation
                                            <Icon icon="infoCircle" className="ms-1 text-muted" />
                                        </label>
                                    </MDBTooltip>

                                    <select name="donation" className="form-select" onChange={onChangeSelect}>
                                        <option value="Kenneth Gordon Maplewood School">Kenneth Gordon Maplewood School</option>
                                        <option value="Red Cross">Red Cross</option>
                                    </select>
                                </MDBCol>
                            </MDBRow>

                            <SubmitButtons />
                        </form>
                    </MDBCol>
                </MDBRow>
                {props.namesList.length > 0 && (
                    <MDBRow>
                        <hr className="mt-3 mx-4" />
                        <h3 className="text-center fw-bold">Apron Previews</h3>
                        <div className="text-center mb-3 text-muted">All the aprons in your group order</div>
                    </MDBRow>
                )}
                <MDBRow>
                    {props.namesList.map((name: string, index: number) => (
                        <PreviewColumn {...props} key={index} name={name} index={index} quote={utility.getNested(quotesByName, [name, 'quote'])} columnClass="group-preview" />
                    ))}
                </MDBRow>
            </MDBContainer>
        </div>
    )

    function Votes() {
        return (
            <div className="votes mt-2 ms-2 d-flex align-items-center">
                <MDBTooltip tag="span" title={`People who like this quote: ${likeCount}`}>
                    <img src={like} className="emoji" alt="logo" />
                    <span className="vote-count fs-5 align-middle ms-1">{likeCount}</span>
                </MDBTooltip>
                <MDBTooltip tag="span" title={currentLikeType >= 1 ? 'Remove like' : 'Like this quote. View your liked quotes by clicking the button to the right.'}>
                    <MDBBtn className={`action-button ms-4 ${currentLikeType >= 1 && 'liked-quote'}`} floating onClick={() => handleClickLike(1)}>
                        <Icon icon="heart" className="fa-lg" />
                    </MDBBtn>
                </MDBTooltip>
                <MDBTooltip tag="span" title="See list of quotes you liked or created">
                    <MDBBtn className="action-button ms-3" floating color="dark" onClick={handleClickHistory}>
                        <Icon icon="history" className="fa-lg" />
                    </MDBBtn>
                </MDBTooltip>

                <span className="ms-3">
                    {matchingLength ? quoteIndex + 1 : 0} of {matchingLength} result{matchingLength !== 1 && 's'}
                </span>
            </div>
        )
    }

    function SubmitButtons() {
        return (
            <div className="form-group mt-4">
                <MDBTooltip tag="span" title="Proceed to payment page. Make sure you're happy with the design above first." className="pb-4">
                    <MDBBtn className="continue-button" color="danger" size="lg" type="submit" disabled={loading || addToCartLoading}>
                        {loading ? <MDBSpinner size="sm" role="status" tag="span" className="me-2" /> : <Icon icon="arrow-right" />}
                        {!loading && !message && 'Checkout'}
                        {message}
                        {loading && !message && 'Loading...'}
                    </MDBBtn>
                </MDBTooltip>
                {props.namesList?.length === 0 && (
                    <MDBTooltip tag="span" title="Add this apron to your cart and start designing another one." className="pb-4">
                        <MDBBtn
                            className="continue-button ms-3"
                            size="lg"
                            type="submit"
                            /* so we can tell which button was pressed */
                            name="addToCart"
                            disabled={loading || addToCartLoading}
                        >
                            {addToCartLoading ? <MDBSpinner size="sm" role="status" tag="span" className="me-2" /> : <Icon fas icon="shopping-cart" />}
                            {!addToCartLoading && !addToCartMessage && 'Add to Cart'}
                            {addToCartMessage}
                            {addToCartLoading && 'Loading...'}
                        </MDBBtn>
                    </MDBTooltip>
                )}

                {addedToCart && (
                    <>
                        <div className="alert alert-success mt-3 mb-0" role="alert">
                            Added to cart.{' '}
                            <a href="/cart/" target="_blank">
                                View cart
                                <Icon fas icon="external-link-alt" className="fa-sm ms-2" />
                            </a>
                        </div>

                        <MDBTooltip tag="span" title="Reset this page to the defaults" className="pb-4">
                            <MDBBtn className="mt-3" color="success" size="lg" onClick={handleClickReset}>
                                <Icon icon="plus" />
                                Start New Apron
                            </MDBBtn>
                        </MDBTooltip>
                    </>
                )}
            </div>
        )
    }

    function numberOfSelected(dictionary = quotesByName) {
        let count = 0

        for (const value of Object.values(dictionary)) {
            if (value) {
                count++
            }
        }

        if (count > props.namesList.length) {
            count = props.namesList.length
        }

        return count
    }
}

export default OrderStep2
