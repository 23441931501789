export const quotes: Array<any> = [
    {
        "id": 1,
        "quote": "There’s a steak in my boot.",
        "attribution": "Woody",
        "category": "Movies",
        "title": "Toy Story",
        "genre": "Comedy",
        "tag1": "Pixar",
        "tag2": "Animated",
        "tag3": "1990s",
        "tag4": "Rated G",
        "tag5": "Classic"
    },
    {
        "id": 2,
        "quote": "Guns for show, grills for a pro.",
        "attribution": "Soap",
        "category": "Movies",
        "title": "Lock, Stock and Two Smoking Barrels",
        "genre": "Crime",
        "tag1": "Guy Ritchie",
        "tag2": "Dexter Fletcher",
        "tag3": "1990s"
    },
    {
        "id": 3,
        "quote": "I'm gonna make him a burger he can't refuse.",
        "attribution": "Don Vito Corleone",
        "category": "Movies",
        "title": "The Godfather",
        "genre": "Crime",
        "tag1": "Marlon Brando",
        "tag2": "Mob",
        "tag3": "1970s",
        "tag4": "Mafia",
        "tag5": "Classic"
    },
    {
        "id": 4,
        "quote": "Here’s grillin for you, kid.",
        "attribution": "Rick Blaine",
        "category": "Movies",
        "title": "Casablanca",
        "genre": "Drama",
        "tag1": "Humphrey Bogart",
        "tag3": "1940s",
        "tag4": "Peter Lorre",
        "tag5": "Ingrid Bergman"
    },
    {
        "id": 5,
        "quote": "Hey - I'm grilling here! I'm grilling here!",
        "attribution": "Ratso Rizzo",
        "category": "Movies",
        "title": "Midnight Cowboy",
        "genre": "Classic",
        "tag2": "Dustin Hoffman",
        "tag3": "1960s",
        "tag4": "Drama"
    },
    {
        "id": 6,
        "quote": "You can't handle the sauce!",
        "attribution": "Col. Nathan Jessup",
        "category": "Movies",
        "title": "A Few Good Men",
        "genre": "Classic",
        "tag2": "Jack Nicholson",
        "tag3": "1990s",
        "tag4": "Drama",
        "tag5": "Tom Cruise",
        "tag6": "Demi Moore"
    },
    {
        "id": 7,
        "quote": "You're gonna need a bigger plate.",
        "attribution": "Martin Brody",
        "category": "Movies",
        "title": "JAWS",
        "genre": "Classic",
        "tag2": "Roy Scheider",
        "tag3": "1970s",
        "tag4": "Steven Spielberg",
        "tag5": "Richard Dreyfuss",
        "tag6": "Peter Benchley"
    },
    {
        "id": 8,
        "quote": "You've got to ask yourself one question. Do I feel hungry? Well, do ya, punk?",
        "attribution": "Harry Callahan",
        "category": "Movies",
        "title": "Dirty Harry",
        "genre": "Classic",
        "tag2": "Clint Eastwood",
        "tag3": "1970s",
        "tag4": "Drama"
    },
    {
        "id": 9,
        "quote": "Nobody puts Baby in a food coma.",
        "attribution": "Johnny",
        "category": "Movies",
        "title": "Dirty Dancing",
        "genre": "Classic",
        "tag1": "Romance",
        "tag2": "Patrick Swayze",
        "tag3": "1980s",
        "tag4": "Jennifer Grey",
        "tag5": "Jerry Orbach"
    },
    {
        "id": 10,
        "quote": "Pay some attention to that man behind the apron.",
        "attribution": "The Wizard of Oz",
        "category": "Movies",
        "title": "The Wizard of Oz",
        "genre": "Classic",
        "tag1": "Fantasy",
        "tag2": "Judy Garland",
        "tag3": "1930s",
        "tag4": "Ray Bolger",
        "tag5": "Bert Lahr",
        "tag6": "Toto"
    },
    {
        "id": 11,
        "quote": "Just keep grilling. Just keep grilling.",
        "attribution": "Dory",
        "category": "Movies",
        "title": "Finding Dory",
        "genre": "Comedy",
        "tag1": "Animated",
        "tag2": "Ellen DeGeneres",
        "tag3": "2000s",
        "tag4": "Pixar",
        "tag5": "Rated G",
        "tag6": "Finding Nemo",
        "tag7": "Animated"
    },
    {
        "id": 12,
        "quote": "Mama says, “Grilling is as grilling does”.",
        "attribution": "Forrest Gump",
        "category": "Movies",
        "title": "Forrest Gump",
        "genre": "Classic",
        "tag1": "Tom Hanks",
        "tag2": "Robert Zemeckis",
        "tag3": "1990s",
        "tag4": "Sally Field",
        "tag5": "Gary Sinise",
        "tag6": "Robin Wright"
    },
    {
        "id": 13,
        "quote": "I love the smell of bbq sauce in the morning.",
        "attribution": "Lt. Col. Bill Kilgore",
        "category": "Movies",
        "title": "Apocalypse Now",
        "genre": "Drama",
        "tag1": "Robert Duvall",
        "tag2": "Francis Ford Coppola",
        "tag3": "1970s",
        "tag5": "Marlon Brando",
        "tag6": "Martin Sheen",
        "tag7": "Dennis Hopper",
        "tag8": "Laurence Fishburne"
    },
    {
        "id": 14,
        "quote": "You’ll have what she’s having.",
        "attribution": "Female Customer",
        "category": "Movies",
        "title": "When Harry Met Sally...",
        "genre": "Comedy",
        "tag2": "Billy Crystal",
        "tag3": "1980s",
        "tag4": "Meg Ryan",
        "tag5": "Rob Reiner",
        "tag6": "Estelle Reiner",
        "tag7": "Carrie Fisher",
        "tag8": "Bruno Kirby"
    },
    {
        "id": 15,
        "quote": "Yeah, but I flip with this hand.",
        "attribution": "Jim",
        "category": "Movies",
        "title": "Blazing Saddles",
        "genre": "Comedy",
        "tag2": "Mel Brooks",
        "tag3": "1970s",
        "tag4": "Gene Wilder",
        "tag5": "Richard Pryor",
        "tag6": "Slim Pickens",
        "tag7": "Madeline Kahn",
        "tag8": "Dom DeLuise"
    },
    {
        "id": 16,
        "quote": "We get the tenderloins and we hold the world ransom... For ONE MILLION DOLLARS!",
        "attribution": "Dr. Evil",
        "category": "Movies",
        "title": "Austin Powers: The Spy Who Shagged Me",
        "genre": "Comedy",
        "tag1": "Austin Powers",
        "tag2": "Mike Myers",
        "tag3": "1990s",
        "tag5": "Seth Meyers",
        "tag6": "Heather Graham",
        "tag7": "Michael Caine",
        "tag8": "Beyonce",
        "tag9": "Elizabeth Hurley"
    },
    {
        "id": 17,
        "quote": "Four ounce steak inside of a four ounce steak? That’s like, 16 ounces!",
        "attribution": "Deadpool",
        "category": "Movies",
        "title": "Deadpool",
        "genre": "Comedy",
        "tag1": "Ryan Reynolds",
        "tag2": "MCU",
        "tag3": "2010s",
        "tag4": "Marvel",
        "tag5": "Ed Skrein",
        "tag6": "Morena Baccarin",
        "tag7": "TJ Miller",
        "tag8": "Gina Carano"
    },
    {
        "id": 18,
        "quote": "I have no idea what I'm doing with my life. But I have a grill, so I got that going for me, which is nice.",
        "attribution": "Carl Spackler",
        "category": "Movies",
        "title": "Caddyshack",
        "genre": "Comedy",
        "tag1": "Bill Murray",
        "tag3": "1980s",
        "tag4": "Ted Knight",
        "tag5": "Rodney Dangerfield",
        "tag6": "Danny Noonan",
        "tag7": "Chevy Chase"
    },
    {
        "id": 19,
        "quote": "Symmetrical burger stacking. Just like the Philadelphia mass turbulence of 1947.",
        "attribution": "Ray Stantz",
        "category": "Movies",
        "title": "Ghostbusters",
        "genre": "Comedy",
        "tag1": "Bill Murray",
        "tag3": "1980s",
        "tag4": "Dan Aykroyd",
        "tag5": "Sigourney Weaver",
        "tag6": "Harold Ramis",
        "tag7": "Rick Moranis"
    },
    {
        "id": 20,
        "quote": "May the forks be with you.",
        "attribution": "Obi-Wan Kenobi",
        "category": "Movies",
        "title": "Star Wars",
        "genre": "Sci-fi",
        "tag2": "Sir Alec Guinness",
        "tag3": "1970s",
        "tag4": "Harrison Ford",
        "tag5": "Mark Hamill",
        "tag6": "Carrie Fisher",
        "tag7": "James Earl Jones"
    },
    {
        "id": 21,
        "quote": "I'm the king of the grill!",
        "attribution": "Jack Dawson",
        "category": "Movies",
        "title": "Titanic",
        "genre": "Drama",
        "tag2": "Leonardo DiCaprio",
        "tag3": "1990s",
        "tag4": "Kate Winslet"
    },
    {
        "id": 22,
        "quote": "I've got two steaks. One for the each of ya.",
        "attribution": "Doc Holliday",
        "category": "Movies",
        "title": "Tombstone",
        "genre": "Western",
        "tag2": "Val Kilmer",
        "tag3": "1990s",
        "tag4": "Sam Elliott",
        "tag5": "Charleton Heston",
        "tag6": "Jason Priestley",
        "tag7": "Dana Delany",
        "tag8": "Kurt Russell"
    },
    {
        "id": 23,
        "quote": "You come into my house, on the day my ribeye is to be marinated, and you ask me to grill meat for money.",
        "attribution": "Don Vito Corleone",
        "category": "Movies",
        "title": "The Godfather",
        "genre": "Crime",
        "tag2": "Marlon Brando",
        "tag3": "1970s",
        "tag4": "Francis Ford Coppola",
        "tag5": "Mafia",
        "tag6": "Mob"
    },
    {
        "id": 24,
        "quote": "Leave the gun, bring the barbeque.",
        "attribution": "Peter Clemenza",
        "category": "Movies",
        "title": "The Godfather",
        "genre": "Crime",
        "tag2": "Richard Castellano",
        "tag3": "1970s",
        "tag4": "Marlon Brando",
        "tag5": "Mafia",
        "tag6": "Francis Ford Coppola",
        "tag7": "Mob"
    },
    {
        "id": 25,
        "quote": "Grill this!",
        "attribution": "Trinity",
        "category": "Movies",
        "title": "The Matrix",
        "genre": "Sci-fi",
        "tag1": "The Matrix",
        "tag2": "The Wachowskis",
        "tag3": "1990s",
        "tag4": "Joel Silver",
        "tag5": "Keanu Reeves",
        "tag6": "Warner Bros Records",
        "tag7": "Laurence Fishburne",
        "tag8": "Carrie-Anne Moss",
        "tag9": "Hugo Weaving",
        "field16": "Joe Pantoliano",
        "field17": "Neo",
        "field18": "Karate"
    },
    {
        "id": 26,
        "quote": "Now on the day I was born, the chefs all gathered round.",
        "attribution": "George Thorogood",
        "category": "Music",
        "title": "Bad to the Bone",
        "genre": "Rock",
        "tag1": "George Thorogood and the destroyers",
        "tag2": "Muddy Waters",
        "tag3": "1980s",
        "tag4": "Mannish Boy",
        "tag5": "Billy Blough",
        "tag6": "Jeff Simon",
        "tag7": "Hank Carter",
        "tag8": "Ian Stewart",
        "tag9": "EMI America Records"
    },
    {
        "id": 27,
        "quote": "Welcome to my Weber. We've got steak and prawns.",
        "attribution": "Guns N' Roses",
        "category": "Music",
        "title": "Welcome to the Jungle",
        "genre": "Rock",
        "tag1": "Axl Rose",
        "tag2": "Geffen Records",
        "tag3": "1980s",
        "tag4": "Izzy Stradlin",
        "tag5": "Mike Clink",
        "tag6": "Slash"
    },
    {
        "id": 28,
        "quote": "Psycho Griller, qu'est que steak?",
        "attribution": "Talking Heads",
        "category": "Music",
        "title": "Psycho Killer",
        "genre": "Rock, Alternative",
        "tag1": "David Byrne",
        "tag2": "Chris Frantz",
        "tag3": "1970s",
        "tag4": "Tina Weymouth",
        "tag5": "Cage the Elephant",
        "tag6": "Sire Records"
    },
    {
        "id": 29,
        "quote": "Grill it for me, Baby.",
        "attribution": "Rick James",
        "category": "Music",
        "title": "Give it to Me, Baby",
        "genre": "Disco",
        "tag3": "1980s",
        "tag4": "Gordy Records",
        "tag5": "Motown Records"
    },
    {
        "id": 30,
        "quote": "Our grill is a very very very fine grill.",
        "attribution": "Crosby, Stills, & Nash",
        "category": "Music",
        "title": "Our House",
        "genre": "Rock",
        "tag1": "Graham Nash",
        "tag2": "David Crosby",
        "tag3": "1970s",
        "tag4": "Stephen Stills",
        "tag5": "CSNY",
        "tag6": "CSN",
        "tag7": "Helen Reddy",
        "tag8": "Atlantic Records"
    },
    {
        "id": 31,
        "quote": "It's a steak world after all.",
        "attribution": "Richard & Robert Sherman",
        "category": "Music",
        "title": "It's a Small World",
        "genre": "Pop",
        "tag1": "Disney",
        "tag2": "Rhyme",
        "tag3": "1960s",
        "tag4": "World fair",
        "tag5": "Disneyland records"
    },
    {
        "id": 32,
        "quote": "Tonight is a wonderful night, to grill a steak, oh yeah.",
        "attribution": "April Wine",
        "category": "Music",
        "title": "Tonight Is A Wonderful Time To Fall In Love",
        "genre": "Rock",
        "tag1": "Brian Greenway",
        "tag2": "Myles Goodwyn",
        "tag3": "1970s",
        "tag4": "Richard Lanthier",
        "tag5": "Roy Nichol",
        "tag6": "Aquarius Records",
        "tag7": "Capitol Records"
    },
    {
        "id": 33,
        "quote": "You can always get what you want.",
        "attribution": "The Rolling Stones",
        "category": "Music",
        "title": "You Can't Always Get What You Want",
        "genre": "Rock",
        "tag1": "Mick Jagger",
        "tag2": "Keith Richards",
        "tag3": "1960s",
        "tag4": "Bill Wyman",
        "tag5": "Jimmy Miller",
        "tag6": "Decca Records",
        "tag7": "London Recordings"
    },
    {
        "id": 34,
        "quote": "Let’s talk about steaks, Baby.",
        "attribution": "Salt-N-Pepa",
        "category": "Music",
        "title": "Let's Talk About Sex",
        "genre": "Hip-Hop",
        "tag1": "Cheryl James",
        "tag2": "Sandra Denton",
        "tag3": "1990s",
        "tag4": "Deidra Roper",
        "tag5": "Hurby Azor",
        "tag6": "Al Bell",
        "tag7": "Next Plateau Entertainment"
    },
    {
        "id": 35,
        "quote": "50 ways to sauce your burger.",
        "attribution": "Paul Simon",
        "category": "Music",
        "title": "50 Ways to Leave Your Lover",
        "genre": "Rock",
        "tag1": "John Tropea",
        "tag2": "Columbia Records",
        "tag3": "1970s",
        "tag4": "Hugh McCracken",
        "tag5": "Tony Levin",
        "tag6": "Pop",
        "tag7": "Steve Gadd"
    },
    {
        "id": 36,
        "quote": "Grilling days are here again, as the summer evenings grow.",
        "attribution": "Led Zeppelin",
        "category": "Music",
        "title": "Dancing Days",
        "genre": "Rock",
        "tag1": "Jimmy Page",
        "tag2": "Robert Plant",
        "tag3": "1970s",
        "tag4": "Atlantic Records",
        "tag5": "Stone Temple Pilots",
        "tag6": "UK"
    },
    {
        "id": 37,
        "quote": "Over the grills and char or flay.",
        "attribution": "Led Zeppelin",
        "category": "Music",
        "title": "Over the Hills and Far Away",
        "genre": "Rock",
        "tag1": "Jimmy Page",
        "tag2": "Robert Plant",
        "tag3": "1970s",
        "tag4": "Atlantic Records",
        "tag5": "UK"
    },
    {
        "id": 38,
        "quote": "I'm making dinner, my fans they can't wait. They eat my burgers, tell me I'm great.",
        "attribution": "Joe Walsh",
        "category": "Music",
        "title": "Life's Been Good",
        "genre": "Rock",
        "tag1": "Bill Szymczyk",
        "tag2": "Reggae",
        "tag3": "1970s",
        "tag4": "Asylum Records"
    },
    {
        "id": 39,
        "quote": "Cause Gramps like us, baby we were born to grill.",
        "attribution": "Bruce Springsteen",
        "category": "Music",
        "title": "Born to Run",
        "genre": "Rock",
        "tag1": "Mike Appel",
        "tag2": "Frankie Goes to Hollywood",
        "tag3": "1970s",
        "tag4": "Garry Tallent",
        "tag5": "Ernest Carter",
        "tag6": "David Sancious",
        "tag7": "Danny Federici",
        "tag8": "Clarence Clemons",
        "tag9": "Columbia Records"
    },
    {
        "id": 40,
        "quote": "Very super-delicious, grill marks on the steak.",
        "attribution": "Stevie Wonder",
        "category": "Music",
        "title": "Superstition",
        "genre": "Soul, R&B",
        "tag1": "Malcolm Cecil",
        "tag2": "Blues",
        "tag3": "1970s",
        "tag4": "Robert Margouleff",
        "tag5": "Trevor Lawrence",
        "tag6": "Steve Madaio",
        "tag7": "Motown Records"
    },
    {
        "id": 41,
        "quote": "There’s gonna be a clambake tonight.",
        "attribution": "The Eagles",
        "category": "Music",
        "title": "Heartache Tonight",
        "genre": "Rock",
        "tag1": "Don Henley",
        "tag2": "Glenn Frey",
        "tag3": "1970s",
        "tag4": "Bill Szymczyk",
        "tag5": "Bob Seger",
        "tag6": "Asylum Records",
        "tag7": "J.D. Souther",
        "tag8": "Joe Walsh",
        "tag9": "Warner Bros.",
        "field16": "Michael Bublé"
    },
    {
        "id": 42,
        "quote": "Gimme, gimme, gimme mignon after midnight.",
        "attribution": "ABBA",
        "category": "Music",
        "title": "Gimme, Gimme, Gimme (A Man After Midnight)",
        "genre": "Pop, Disco",
        "tag1": "Benny Andersson",
        "tag2": "Björn Ulvaeus",
        "tag3": "1970s",
        "tag4": "Agnetha Fältskog",
        "tag5": "Mamma Mia",
        "tag6": "Polar Music"
    },
    {
        "id": 43,
        "quote": "Takin’ care of grilling, everyday!",
        "attribution": "Bachman-Turner Overdrive",
        "category": "Music",
        "title": "Taking Care of Business",
        "genre": "Rock",
        "tag1": "Mercury Records",
        "tag2": "Randy Bachman",
        "tag3": "1970s",
        "tag4": "Fred Turner",
        "tag5": "Tim Bachman",
        "tag6": "Robbie Bachman"
    },
    {
        "id": 44,
        "quote": "Gina smokes the dinner all day. Grilling for her man, she turns up the heat for love, oooh, for love.",
        "attribution": "Bon Jovi",
        "category": "Music",
        "title": "Livin' On a Prayer",
        "genre": "Rock",
        "tag1": "Mercury Records",
        "tag2": "Desmond Child",
        "tag3": "1980s",
        "tag4": "Richie Sambora",
        "tag5": "Bruce Fairbairn",
        "tag6": "Slippery When Wet"
    },
    {
        "id": 45,
        "quote": "Whoa, grillin’ with some flair.",
        "attribution": "Bon Jovi",
        "category": "Music",
        "title": "Livin' On a Prayer",
        "genre": "Rock",
        "tag1": "Mercury Records",
        "tag2": "Desmond Child",
        "tag3": "1980s",
        "tag4": "Richie Sambora",
        "tag5": "Bruce Fairbairn",
        "tag6": "Slippery When Wet"
    },
    {
        "id": 46,
        "quote": "Whoa, grillin’ with some flair. Flay my prawns, we'll eat 'em, I swear.",
        "attribution": "Bon Jovi",
        "category": "Music",
        "title": "Livin' On a Prayer",
        "genre": "Rock",
        "tag1": "Mercury Records",
        "tag2": "Desmond Child",
        "tag3": "1980s",
        "tag4": "Richie Sambora",
        "tag5": "Bruce Fairbairn",
        "tag6": "Slippery When Wet"
    },
    {
        "id": 47,
        "quote": "I will survive, oh, as long as I know how to grill, I know I'll stay alive.",
        "attribution": "Gloria Gaynor",
        "category": "Music",
        "title": "I Will Survive",
        "genre": "Pop, Disco",
        "tag1": "Disco",
        "tag2": "Freddie Perren",
        "tag3": "1970s",
        "tag4": "Dino Fekaris",
        "tag5": "Cake",
        "tag6": "Diana Ross",
        "tag7": "Shirley Bassey",
        "tag8": "Polydor Records"
    },
    {
        "id": 48,
        "quote": "I know everybody says money can't buy happiness... but it could buy me a grill, it could buy me propane to fuel it, it could buy me a bone-in ribeye soaked in some fancy marinade.",
        "attribution": "Chris Janson",
        "category": "Music",
        "title": "Buy Me a Boat",
        "genre": "Country",
        "tag1": "Chris DuBois",
        "tag2": "Warner Bros Nashville",
        "tag3": "2010s",
        "tag4": "Brent Anderson"
    },
    {
        "id": 49,
        "quote": "Ba-dee-ya, say, do you remember? Ba-dee-ya, grilling in September, Ba-dee-ya, never was a meatless day.",
        "attribution": "Earth, Wind & Fire",
        "category": "Music",
        "title": "September",
        "genre": "Pop, R&B",
        "tag1": "ARC Records",
        "tag2": "Columbia Records",
        "tag3": "1970s",
        "tag4": "Maurice White",
        "tag5": "Allee Willis",
        "tag6": "Al McKay",
        "tag7": "Philip Bailey",
        "tag8": "Night at the Museum",
        "tag9": "Trolls",
        "field16": "Stax Records"
    },
    {
        "id": 50,
        "quote": "And we were grilling different things, we were smoking chicken wings.",
        "attribution": "Kid Rock",
        "category": "Music",
        "title": "All Summer Long",
        "genre": "Country",
        "tag1": "Atlantic Records",
        "tag2": "Sweet Home Alabama",
        "tag3": "2000s",
        "tag4": "Warren Zevon"
    },
    {
        "id": 51,
        "quote": "Oh, there ain't no rest for the grillers, burgers don't grow on trees.",
        "attribution": "Cage the Elephant",
        "category": "Music",
        "title": "There Ain't No Rest for the Wicked",
        "genre": "Rock, Alternative, Alt-Rock",
        "tag1": "Matt Shultz",
        "tag2": "Jay Joyce",
        "tag3": "2000s",
        "tag4": "Relentless Records"
    },
    {
        "id": 52,
        "quote": "Oh, there ain't no rest for the grillers, burgers don't grow on trees. Got grocery bills to pay, I got mouths to feed, there ain't nothing in this store for free.",
        "attribution": "Cage the Elephant",
        "category": "Music",
        "title": "There Ain't No Rest for the Wicked",
        "genre": "Rock, Alternative, Alt-Rock",
        "tag1": "Matt Shultz",
        "tag2": "Jay Joyce",
        "tag3": "2000s",
        "tag4": "Relentless Records"
    },
    {
        "id": 53,
        "quote": "Little old lady got mutton chops late last night, werewolves of London again.",
        "attribution": "Warren Zevon",
        "category": "Music",
        "title": "Werewolves of London",
        "genre": "Rock, Pop",
        "tag1": "Asylum Records",
        "tag2": "Mick Fleetwood",
        "tag3": "1970s",
        "tag4": "LeRoy Marinell",
        "tag5": "Waddy Wachtel",
        "tag6": "Jackson Browne",
        "tag7": "London"
    },
    {
        "id": 54,
        "quote": "Ooh baby grill me one more steak.",
        "attribution": "The Jackson 5",
        "category": "Music",
        "title": "I Want You Back",
        "genre": "Pop, R&B",
        "tag1": "Motown Records",
        "tag2": "The Corporation",
        "tag3": "1960s",
        "tag4": "Michael Jackson"
    },
    {
        "id": 55,
        "quote": "Is this a real steak, is this just fantasy?",
        "attribution": "Queen",
        "category": "Music",
        "title": "Bohemian Rhapsody",
        "genre": "Pop, Rock",
        "tag1": "Rock",
        "tag2": "EMI Records",
        "tag3": "1970s",
        "tag4": "Freddie Mercury",
        "tag5": "Roy Thomas Baker",
        "tag6": "Wayne's World",
        "tag7": "Brian May",
        "tag8": "John Beacon",
        "tag9": "Elektra Records",
        "field16": "Roger Taylor"
    },
    {
        "id": 56,
        "quote": "You can go for that (I can do).",
        "attribution": "Hall & Oates",
        "category": "Music",
        "title": "I Can't Go For That (No Can Do)",
        "genre": "Rock",
        "tag1": "Pop",
        "tag2": "RCA Records",
        "tag3": "1980s",
        "tag4": "Sara Allen",
        "tag5": "Daryl Hall",
        "tag6": "John Oates"
    },
    {
        "id": 57,
        "quote": "Fat-bottomed pork you make the grilling world go round.",
        "attribution": "Queen",
        "category": "Music",
        "title": "Fat Bottomed Girls",
        "genre": "Rock, Pop",
        "tag1": "Pop",
        "tag2": "Freddie Mercury",
        "tag3": "1970s",
        "tag4": "Brian May",
        "tag5": "Roy Thomas Baker",
        "tag6": "EMI Records",
        "tag7": "Elektra Records",
        "tag8": "Roger Taylor",
        "tag9": "John Beacon"
    },
    {
        "id": 58,
        "quote": "We like the champignons, my friends. And we'll keep on grilling to the end.",
        "attribution": "Queen",
        "category": "Music",
        "title": "We Are The Champions",
        "genre": "Rock, Pop",
        "tag1": "Freddie Mercury",
        "tag2": "Brian May",
        "tag3": "1970s",
        "tag4": "EMI Records",
        "tag5": "Roger Taylor",
        "tag6": "John Beacon",
        "tag7": "Elektra Records"
    },
    {
        "id": 59,
        "quote": "Another one bites the crust.",
        "attribution": "Queen",
        "category": "Music",
        "title": "Another One Bites the Dust",
        "genre": "Rock, Pop",
        "tag1": "Pop",
        "tag2": "Freddie Mercury",
        "tag3": "1980s",
        "tag4": "Brian May",
        "tag5": "EMI Records",
        "tag6": "Elektra Records",
        "tag7": "Roger Taylor",
        "tag8": "John Beacon"
    },
    {
        "id": 60,
        "quote": "Cause I sauce to thrill and I'm ready to grill.",
        "attribution": "AC/DC",
        "category": "Music",
        "title": "Shoot to Thrill",
        "genre": "Rock",
        "tag1": "Atlantic Records",
        "tag2": "Angus Young",
        "tag3": "1980s",
        "tag4": "Malcolm Young",
        "tag5": "Brian Johnson",
        "tag6": "Robert \"Mutt\" Lange",
        "tag7": "Phil Rudd",
        "tag8": "Iron man",
        "tag9": "Columbia Records",
        "field16": "Cliff Williams"
    },
    {
        "id": 61,
        "quote": "For those about to grill. We sous-chef you.",
        "attribution": "AC/DC",
        "category": "Music",
        "title": "For Those About to Rock",
        "genre": "Rock",
        "tag1": "Atlantic Records",
        "tag2": "Angus Young",
        "tag3": "1980s",
        "tag4": "Malcolm Young",
        "tag5": "Brian Johnson",
        "tag6": "Robert \"Mutt\" Lange",
        "tag7": "Phil Rudd",
        "tag8": "Cliff Williams"
    },
    {
        "id": 62,
        "quote": "Don't need season. Don't need thyme. Ain't nothin' that I'd rather do. Grillin' town. Party time. My friends are gonna be there, too.",
        "attribution": "AC/DC",
        "category": "Music",
        "title": "Highway to Hell",
        "genre": "Rock",
        "tag1": "Bon Scott",
        "tag2": "Angus Young",
        "tag3": "1970s",
        "tag4": "Malcolm Young",
        "tag5": "Robert \"Mutt\" Lange",
        "tag6": "Phil Rudd",
        "tag7": "Marilyn Manson",
        "tag8": "Atco Records",
        "tag9": "Atlantic Records",
        "field16": "Epic Records",
        "field17": "Cliff Williams"
    },
    {
        "id": 63,
        "quote": "I smell steaks and brandy, yeah.",
        "attribution": "Marcy Playground",
        "category": "Music",
        "title": "Sex and Candy",
        "genre": "Rock, Alternative",
        "tag1": "John Wozniak",
        "tag2": "Capitol Records",
        "tag3": "1990s",
        "tag4": "Maroon 5",
        "tag5": "Jim Sabella",
        "tag6": "Jared Kotler",
        "tag7": "Kenny Gioia"
    },
    {
        "id": 64,
        "quote": "You can grill your own way, grill your own way.",
        "attribution": "Fleetwood Mac",
        "category": "Music",
        "title": "Go Your Own Way",
        "genre": "Rock",
        "tag1": "Warner Bros Records",
        "tag2": "Lindsey Buckingham",
        "tag3": "1970s",
        "tag4": "Richard Dashut",
        "tag5": "Ken Caillat",
        "tag6": "Stevie Nicks",
        "tag7": "Mick Fleetwood",
        "tag8": "Carrie Underwood",
        "tag9": "The Lumineers",
        "field16": "The Cranberries"
    },
    {
        "id": 65,
        "quote": "Guess who just got backribs today.",
        "attribution": "Thin Lizzie",
        "category": "Music",
        "title": "The Boys Are Back In Town",
        "genre": "Rock",
        "tag1": "Vertigo Records",
        "tag2": "Mercury Records",
        "tag3": "1970s",
        "tag4": "Phil Lynott",
        "tag5": "John Alcock"
    },
    {
        "id": 66,
        "quote": "We want the meat, grill up the meat.",
        "attribution": "Parliament",
        "category": "Music",
        "title": "Give Up The Funk",
        "genre": "Funk, R&B",
        "tag1": "George Clinton",
        "tag2": "Bootsy Collins",
        "tag3": "1970s",
        "tag4": "Jerome Brailey",
        "tag5": "Casablanca Records"
    },
    {
        "id": 67,
        "quote": "You make me feel like grilling. I wanna grill the night away.",
        "attribution": "Leo Sayer",
        "category": "Music",
        "title": "You Make Me Feel Like Dancing",
        "genre": "Disco, Pop",
        "tag1": "Richard Perry",
        "tag2": "Warner Bros Nashville",
        "tag3": "1970s",
        "tag4": "Chrysalis",
        "tag5": "Vini Poncia",
        "tag6": "The Wiggles"
    },
    {
        "id": 68,
        "quote": "That’s the way (uh huh, uh huh, you like it).",
        "attribution": "KC and the Sunshine Band",
        "category": "Music",
        "title": "That's The Way (I Like It)",
        "genre": "Pop, Disco",
        "tag1": "Rock",
        "tag2": "Harry Wayne Casey",
        "tag3": "1970s",
        "tag4": "Richard Finch",
        "tag5": "TK Records",
        "tag6": "Metronome Records"
    },
    {
        "id": 69,
        "quote": "She’s got tongs, she knows how to use them.",
        "attribution": "ZZ Top",
        "category": "Music",
        "title": "Legs",
        "genre": "Rock",
        "tag1": "Warner Bros Records",
        "tag2": "Billy Gibbons",
        "tag3": "1980s",
        "tag4": "Dusty Hill",
        "tag5": "Frank Beard",
        "tag6": "Bill Ham",
        "tag7": "Nickelback",
        "tag8": "Kid Rock"
    },
    {
        "id": 70,
        "quote": "So you think you can stop me and eat my ribeye?",
        "attribution": "Queen",
        "category": "Music",
        "title": "Bohemian Rhapsody",
        "genre": "Pop, Rock",
        "tag1": "Rock",
        "tag2": "EMI Records",
        "tag3": "1970s",
        "tag4": "Freddie Mercury",
        "tag5": "Roy Thomas Baker",
        "tag6": "Wayne's World",
        "tag7": "Brian May",
        "tag8": "John Beacon",
        "tag9": "Elektra Records"
    },
    {
        "id": 71,
        "quote": "The butcher shop has a prime rib set aside for me, for mee, for meeeee!!",
        "attribution": "Queen",
        "category": "Music",
        "title": "Bohemian Rhapsody",
        "genre": "Pop, Rock",
        "tag1": "Rock",
        "tag2": "EMI Records",
        "tag3": "1970s",
        "tag4": "Freddie Mercury",
        "tag5": "Roy Thomas Baker",
        "tag6": "Wayne's World",
        "tag7": "Brian May",
        "tag8": "John Beacon",
        "tag9": "Elektra Records"
    },
    {
        "id": 72,
        "quote": "Don’t stop eating, hold on to that fork right there.",
        "attribution": "Journey",
        "category": "Music",
        "title": "Don't Stop Believin'",
        "genre": "Pop",
        "tag1": "Rock",
        "tag2": "Columbia Records",
        "tag3": "1980s",
        "tag4": "Steve Perry"
    },
    {
        "id": 73,
        "quote": "I like pork butts and I cannot lie.",
        "attribution": "Sir Mixalot",
        "category": "Music",
        "title": "Baby Got Back",
        "genre": "Hip-Hop",
        "tag1": "Rick Rubin",
        "tag2": "Reprise Records",
        "tag3": "1990s",
        "tag4": "American Recordings"
    },
    {
        "id": 74,
        "quote": "I like big steaks and I cannot lie.",
        "attribution": "Sir Mixalot",
        "category": "Music",
        "title": "Baby Got Back",
        "genre": "Hip-Hop",
        "tag1": "Rick Rubin",
        "tag2": "Reprise Records",
        "tag3": "1990s",
        "tag4": "American Recordings"
    },
    {
        "id": 75,
        "quote": "Baby got back ribs.",
        "attribution": "Sir Mixalot",
        "category": "Music",
        "title": "Baby Got Back",
        "genre": "Hip-Hop",
        "tag1": "Rick Rubin",
        "tag2": "Reprise Records",
        "tag3": "1990s",
        "tag4": "American Recordings"
    },
    {
        "id": 76,
        "quote": "She's a smokehouse, the meat's stacked and that's a fact.",
        "attribution": "The Commodores",
        "category": "Music",
        "title": "Brick House",
        "genre": "R&B",
        "tag1": "Motown Records",
        "tag2": "Lionel Richie",
        "tag3": "1970s",
        "tag4": "James Carmichael"
    },
    {
        "id": 77,
        "quote": "Mamas, please let your babies grow up to be grillers.",
        "attribution": "Waylon Jennings & Willie Nelson",
        "category": "Music",
        "title": "Mammas Don't Let Your Babies Grow Up To Be Cowboys",
        "genre": "Country",
        "tag1": "Ed Bruce",
        "tag2": "RCA Records",
        "tag3": "1970s",
        "tag4": "United Artists Records",
        "tag5": "Epic Records",
        "tag6": "Patsy Bruce",
        "tag7": "Gibson/Miller Band"
    },
    {
        "id": 78,
        "quote": "I got steak rub full of spices.",
        "attribution": "Garth Brooks",
        "category": "Music",
        "title": "Friends in Low Places",
        "genre": "Country",
        "tag1": "Capitol Records Nashville",
        "tag2": "Dewayne Blackwell",
        "tag3": "1990s",
        "tag4": "Allen Reynolds",
        "tag5": "MCA Records"
    },
    {
        "id": 79,
        "quote": "Any steak of mine will taste just fine.",
        "attribution": "Shania Twain",
        "category": "Music",
        "title": "Any Man of Mine",
        "genre": "Country",
        "tag1": "Robert \"Mutt\" Lange",
        "tag2": "PolyGram N.V.",
        "tag3": "1990s",
        "tag4": "Universal Music Group Nashville"
    },
    {
        "id": 80,
        "quote": "There's a grill at home who loves me.",
        "attribution": "Brooks Jefferson",
        "category": "Music",
        "title": "Longneck Bottle",
        "genre": "Country",
        "tag1": "Garth Brooks",
        "tag2": "Capitol Records Nashville",
        "tag3": "1990s",
        "tag4": "Rick Carnes",
        "tag5": "Steve Wariner",
        "tag6": "Allen Reynolds"
    },
    {
        "id": 81,
        "quote": "Don't you forget to thank me.",
        "attribution": "Simple Minds",
        "category": "Music",
        "title": "Don't You Forget About Me",
        "genre": "Pop, Alternative",
        "tag1": "Rock",
        "tag2": "Virgin Records",
        "tag3": "1980s",
        "tag4": "A&M Records",
        "tag5": "Keith Forsey",
        "tag6": "Steve Schiff",
        "tag7": "Billy Idol"
    },
    {
        "id": 82,
        "quote": "She’s got a smile that seems to me, reminds me of grilling memories.",
        "attribution": "Guns N' Roses",
        "category": "Music",
        "title": "Sweet Child O' Mine",
        "genre": "Rock",
        "tag1": "Mike Clink",
        "tag2": "Axl Rose",
        "tag3": "1980s",
        "tag4": "Slash",
        "tag5": "Sheryl Crow",
        "tag6": "Geffen Records",
        "tag7": "Izzy Stradlin"
    },
    {
        "id": 83,
        "quote": "Yo, VIP, let’s grill it!",
        "attribution": "Vanilla Ice",
        "category": "Music",
        "title": "Ice Ice Baby",
        "genre": "Hip-Hop",
        "tag1": "Floyd Brown",
        "tag3": "1990s",
        "tag4": "David Bowie",
        "tag5": "Freddie Mercury",
        "tag6": "SBK Records"
    },
    {
        "id": 84,
        "quote": "Steak, steak baby.",
        "attribution": "Vanilla Ice",
        "category": "Music",
        "title": "Ice Ice Baby",
        "genre": "Hip-Hop",
        "tag1": "Floyd Brown",
        "tag3": "1990s",
        "tag4": "David Bowie",
        "tag5": "Freddie Mercury",
        "tag6": "SBK Records"
    },
    {
        "id": 85,
        "quote": "I sauced some ribs, and I liked it!",
        "attribution": "Katy Perry",
        "category": "Music",
        "title": "I Kissed A Girl",
        "genre": "Pop",
        "tag1": "Capitol Records",
        "tag3": "2000s"
    },
    {
        "id": 86,
        "quote": "Grill with one eye open, gripping your fork so tight!",
        "attribution": "Metallica",
        "category": "Music",
        "title": "Enter Sandman",
        "genre": "Rock",
        "tag1": "Elektra Records",
        "tag2": "James Hetfield",
        "tag3": "1990s",
        "tag4": "Bob Rock",
        "tag5": "Kirk Hammett",
        "tag6": "Lars Ulrich"
    },
    {
        "id": 87,
        "quote": "Grilling me softly with his tongs.",
        "attribution": "Fugees",
        "category": "Music",
        "title": "Killing Me Softly With His Song",
        "genre": "Reggae, Pop",
        "tag1": "Pop",
        "tag2": "Roberta Flack",
        "tag3": "1970s",
        "tag4": "Lori Lieberman",
        "tag5": "Charles Fox",
        "tag6": "Norman Gimbel",
        "tag7": "Columbia Records",
        "tag8": "Ruffhouse Records",
        "tag9": "Atlantic Records"
    },
    {
        "id": 88,
        "quote": "Baby, you're amazed at the way I grill, every time.",
        "attribution": "Paul McCartney and Wings",
        "category": "Music",
        "title": "Maybe I'm Amazed",
        "genre": "Rock",
        "tag1": "Apple Records",
        "tag2": "Wings",
        "tag3": "1970s",
        "tag4": "Capitol Records",
        "tag5": "Paul McCartney"
    },
    {
        "id": 89,
        "quote": "No (no). Sleep (sleep). Til brisket!",
        "attribution": "Beastie Boys",
        "category": "Music",
        "title": "No Sleep Till Brooklyn",
        "genre": "Rock, Hip-Hop, Alt-Rock",
        "tag1": "Rick Rubin",
        "tag2": "Columbia Records",
        "tag3": "1980s",
        "tag4": "Def Jam Records",
        "tag5": "Ad-Rock",
        "tag6": "Mike D"
    },
    {
        "id": 90,
        "quote": "It’s more than a feeding.",
        "attribution": "Boston",
        "category": "Music",
        "title": "More Than A Feeling",
        "genre": "Rock",
        "tag1": "Epic records",
        "tag2": "Tom Scholz",
        "tag3": "1970s"
    },
    {
        "id": 91,
        "quote": "Grills just want to have fun.",
        "attribution": "Cyndi Lauper",
        "category": "Music",
        "title": "Girls Just Want to Have Fun",
        "genre": "Pop",
        "tag1": "Robert Hazard",
        "tag2": "Portrait Records",
        "tag3": "1980s",
        "tag4": "Miley Cyrus"
    },
    {
        "id": 92,
        "quote": "Steak on me (steak on me).",
        "attribution": "a-ha",
        "category": "Music",
        "title": "Take On Me",
        "genre": "Pop, Alternative",
        "tag1": "Warner Bros Records",
        "tag2": "John Ratcliff",
        "tag3": "1980s",
        "tag4": "Columbia Records",
        "tag5": "Weezer"
    },
    {
        "id": 93,
        "quote": "I said you wanna be grillin' somethin', you got to be grillin' somethin'.",
        "attribution": "Michael Jackson",
        "category": "Music",
        "title": "Wanna Be Startin' Somethin'",
        "genre": "Pop, R&B",
        "tag1": "Epic Records",
        "tag2": "Quincy Jones",
        "tag3": "1970s",
        "tag4": "1980s"
    },
    {
        "id": 94,
        "quote": "Cause this is Griller, Griller night.",
        "attribution": "Michael Jackson",
        "category": "Music",
        "title": "Thriller",
        "genre": "Pop",
        "tag1": "Epic Records",
        "tag2": "Quincy Jones",
        "tag3": "1980s",
        "tag4": "Rod Temperton",
        "tag5": "CBS Records",
        "tag6": "Columbia Records"
    },
    {
        "id": 95,
        "quote": "I wanna grill with somebody.",
        "attribution": "Whitney Houston",
        "category": "Music",
        "title": "I Wanna Dance With Somebody",
        "genre": "Pop",
        "tag1": "R&B",
        "tag2": "Arista Records",
        "tag3": "1980s",
        "tag4": "George Merrill",
        "tag5": "Shannon Rubicam",
        "tag6": "David Byrne"
    },
    {
        "id": 96,
        "quote": "Alligators grillin' in the air, in the air!",
        "attribution": "America",
        "category": "Music",
        "title": "Ventura Highway",
        "genre": "Rock",
        "tag1": "Dewey Bunnell",
        "tag2": "Warner Bros Records",
        "tag3": "1970s"
    },
    {
        "id": 97,
        "quote": "Set adrift on steak sauce bliss.",
        "attribution": "PM Dawn",
        "category": "Music",
        "title": "Set Adrift On Memory Bliss",
        "genre": "R&B, Hip-Hop",
        "tag1": "Gary Kemp",
        "tag2": "Attrell Cordes",
        "tag3": "1990s",
        "tag4": "Island Records"
    },
    {
        "id": 98,
        "quote": "Sweet dreams (are sauced with this).",
        "attribution": "Eurythmics",
        "category": "Music",
        "title": "Sweet Dreams",
        "genre": "Pop, Alternative",
        "tag1": "Annie Lennox",
        "tag2": "David A. Stuart",
        "tag3": "1980s",
        "tag4": "Marilyn Manson",
        "tag5": "1990s",
        "tag6": "RCA Records",
        "tag7": "Interscope Records",
        "tag8": "Nothing Records"
    },
    {
        "id": 99,
        "quote": "Never gonna run around and dessert you!",
        "attribution": "Rick Astley",
        "category": "Music",
        "title": "Never Gonna Give You Up",
        "genre": "Pop",
        "tag1": "RCA Records",
        "tag2": "Stock Aitken Waterman",
        "tag3": "1980s"
    },
    {
        "id": 100,
        "quote": "Flay that chunky brisket, white boy!",
        "attribution": "Wild Cherry",
        "category": "Music",
        "title": "Play That Funky Music",
        "genre": "Funk, R&B, Pop",
        "tag1": "Rob Parissi",
        "tag2": "Epic Records",
        "tag3": "1970s",
        "tag4": "Roxanne",
        "tag5": "Vanilla Ice",
        "tag6": "SBK Records",
        "tag7": "1980s",
        "tag8": "1990s"
    },
    {
        "id": 101,
        "quote": "Seared, grilled, delivered, it’s yours!",
        "attribution": "Stevie Wonder",
        "category": "Music",
        "title": "Signed, Sealed, Delivered I'm Yours",
        "genre": "R&B, Pop",
        "tag1": "R&B",
        "tag2": "A&M Records",
        "tag3": "1970s",
        "tag4": "Polydor Records",
        "tag5": "Innocent Records",
        "tag6": "Virgin Records",
        "tag7": "Tamla Records"
    },
    {
        "id": 102,
        "quote": "God only knows what I'd be without food.",
        "attribution": "Beach Boys",
        "category": "Music",
        "title": "God Only Knows",
        "genre": "Pop, Rock",
        "tag1": "Capitol Records",
        "tag2": "Brian Wilson",
        "tag3": "1960s",
        "tag4": "Tony Asher"
    },
    {
        "id": 103,
        "quote": "It's nine o'clock on a Saturday. Medium rare orders are in.",
        "attribution": "Billy Joel",
        "category": "Music",
        "title": "Piano Man",
        "genre": "Pop, Rock",
        "tag1": "Michael Stewart",
        "tag2": "Columbia Records",
        "tag3": "1970s"
    },
    {
        "id": 104,
        "quote": "She told me to grill this way.",
        "attribution": "Aerosmith",
        "category": "Music",
        "title": "Walk This Way",
        "genre": "Rock",
        "tag1": "Steven Tyler",
        "tag2": "Joe Perry",
        "tag3": "1970s",
        "tag4": "Jack Douglas",
        "tag5": "Columbia Records",
        "tag6": "Run DMC",
        "tag7": "Island Records"
    },
    {
        "id": 105,
        "quote": "So live and let fry.",
        "attribution": "Wings",
        "category": "Music",
        "title": "Live and Let Die",
        "genre": "Rock",
        "tag1": "Paul McCartney",
        "tag2": "Linda McCartney",
        "tag3": "1970s",
        "tag4": "George Martin",
        "tag5": "James Bond",
        "tag7": "Apple Records",
        "tag8": "Guns N' Roses"
    },
    {
        "id": 106,
        "quote": "Meeting you, with a view, to a grill.",
        "attribution": "Duran Duran",
        "category": "Music",
        "title": "A View to a Kill",
        "genre": "Rock, Pop",
        "tag1": "Pop",
        "tag2": "EMI Records",
        "tag3": "1980s",
        "tag4": "Capitol Records",
        "tag5": "John Barry",
        "tag6": "James Bond"
    },
    {
        "id": 107,
        "quote": "Your own, personal, sous chef.",
        "attribution": "Depeche Mode",
        "category": "Music",
        "title": "Personal Jesus",
        "genre": "Rock, Alternative",
        "tag1": "Martin Gore",
        "tag2": "Mute Records",
        "tag3": "1980s",
        "tag4": "Johnny Cash",
        "tag5": "Marilyn Manson",
        "tag6": "Interscope Records"
    },
    {
        "id": 108,
        "quote": "Gonna grill what you told me!",
        "attribution": "Rage Against the Machine",
        "category": "Music",
        "title": "Killing in the Name",
        "genre": "Rock",
        "tag1": "Epic Records",
        "tag3": "1990s"
    },
    {
        "id": 109,
        "quote": "Flip me baby one more time.",
        "attribution": "Britney Spears",
        "category": "Music",
        "title": "Baby One More Time",
        "genre": "Pop",
        "tag1": "Jive Records",
        "tag2": "Max Martin",
        "tag3": "1990s"
    },
    {
        "id": 110,
        "quote": "Sunday, blue rare Sunday.",
        "attribution": "U2",
        "category": "Music",
        "title": "Sunday Bloody Sunday",
        "genre": "Rock",
        "tag1": "Polystar Records",
        "tag2": "Island Records",
        "tag3": "1980s",
        "tag4": "Steve Lillywhite",
        "tag5": "Bono",
        "tag6": "The Edge",
        "tag7": "Adam Clayton"
    },
    {
        "id": 111,
        "quote": "I can't live with or without bleu.",
        "attribution": "U2",
        "category": "Music",
        "title": "With or Without You",
        "genre": "Rock",
        "tag1": "Island Records",
        "tag2": "Bono",
        "tag3": "1980s",
        "tag4": "The Edge",
        "tag5": "Adam Clayton",
        "tag6": "Brian Eno",
        "tag7": "Steve Lillywhite"
    },
    {
        "id": 112,
        "quote": "There goes my hero. Watch him as he grills.",
        "attribution": "Foo Fighters",
        "category": "Music",
        "title": "My Hero",
        "genre": "Rock",
        "tag1": "Roswell Records",
        "tag2": "Capitol Records",
        "tag3": "1990s",
        "tag4": "Dave Grohl",
        "tag5": "Gil Norton"
    },
    {
        "id": 113,
        "quote": "This meat goes on.",
        "attribution": "The Kings",
        "category": "Music",
        "title": "This Beat Goes On/Switchin' to Glide",
        "genre": "Rock",
        "tag1": "David Diamond",
        "tag2": "Sonny Keyes",
        "tag3": "1980s",
        "tag4": "Extreme Records"
    },
    {
        "id": 114,
        "quote": "There is, a grill, in New Orleans.",
        "attribution": "The Animals",
        "category": "Music",
        "title": "House of the Rising Sun",
        "genre": "Rock",
        "tag1": "Bob Dylan",
        "tag2": "Folk",
        "tag3": "1960s",
        "tag4": "1930s",
        "tag5": "Columbia Records",
        "tag6": "MGM Records",
        "tag7": "Mickie Most",
        "tag8": "Dolly Parton"
    },
    {
        "id": 115,
        "quote": "I don't believe that anybody, grills the way I do.",
        "attribution": "Oasis",
        "category": "Music",
        "title": "Wonderwall",
        "genre": "Rock, Pop",
        "tag1": "Creation Records",
        "tag2": "Owen Morris",
        "tag3": "1990s",
        "tag4": "Noel Gallagher",
        "tag5": "Epic Records"
    },
    {
        "id": 116,
        "quote": "On the edge of 1700 degrees.",
        "attribution": "Stevie Nicks",
        "category": "Music",
        "title": "On the Edge of Seventeen",
        "genre": "Rock",
        "tag1": "Jimmy Iovine",
        "tag2": "Modern Records",
        "tag3": "1980s"
    },
    {
        "id": 117,
        "quote": "Hello? Is it you I'm grilling for?",
        "attribution": "Lionel Richie",
        "category": "Music",
        "title": "Hello",
        "genre": "Pop",
        "tag1": "R&B",
        "tag2": "Motown Records",
        "tag3": "1990s",
        "tag4": "James Anthony Carmichael"
    },
    {
        "id": 118,
        "quote": "I love brisket so, put another log in the hotbox, baby.",
        "attribution": "Joan Jett and the Blackhearts",
        "category": "Music",
        "title": "I Love Rock And Roll",
        "genre": "Rock",
        "tag1": "Arrows",
        "tag2": "Mickie Most",
        "tag3": "1980s",
        "tag4": "1970s",
        "tag5": "Boardwalk Records",
        "tag6": "Alan Merrill",
        "tag7": "Jake Hooker",
        "tag8": "Britney Spears"
    },
    {
        "id": 119,
        "quote": "Tongs, grilling prawns. Reaching out. Flipping two, maybe three... Sweet Marinade (Bah Bah Bah).",
        "attribution": "Neil Diamond",
        "category": "Music",
        "title": "Sweet Caroline",
        "genre": "Pop",
        "tag1": "Uni Records",
        "tag2": "MCA Records",
        "tag3": "1960s",
        "tag4": "Tommy Cogbill",
        "tag5": "Tom Catalano"
    },
    {
        "id": 120,
        "quote": "Tongs, grilling tongs. Reaching out. Flipping two, maybe three... Sweet Baby Ray's (Bah Bah Bah). Good times never seared so good...",
        "attribution": "Neil Diamond",
        "category": "Music",
        "title": "Sweet Caroline",
        "genre": "Pop",
        "tag1": "Uni Records",
        "tag2": "MCA Records",
        "tag3": "1960s",
        "tag4": "Tommy Cogbill",
        "tag5": "Tom Catalano"
    },
    {
        "id": 121,
        "quote": "Red, red, meat goes on my grill. Makes me forget that I still need a salad.",
        "attribution": "Neil Diamond",
        "category": "Music",
        "title": "Red Red Wine",
        "genre": "Pop",
        "tag1": "Bang Records",
        "tag2": "UB40",
        "tag3": "1960s"
    },
    {
        "id": 122,
        "quote": "Upside down, I'm grilling steak instinctively.",
        "attribution": "Diana Ross",
        "category": "Music",
        "title": "Upside Down",
        "genre": "Disco, Pop, R&B",
        "tag1": "Motown Records",
        "tag2": "Nile Rodgers",
        "tag3": "1980s",
        "tag4": "Bernard Edwards"
    },
    {
        "id": 123,
        "quote": "Grill’s out for summer.",
        "attribution": "Alice Cooper",
        "category": "Music",
        "title": "School's Out",
        "genre": "Rock",
        "tag1": "Warner Bros Records",
        "tag2": "Glen Buxton",
        "tag3": "1970s",
        "tag4": "Michael Bruce",
        "tag5": "Dennis Dunaway",
        "tag6": "Neal Smith"
    },
    {
        "id": 124,
        "quote": "Here I grill again, on my own.",
        "attribution": "Whitesnake",
        "category": "Music",
        "title": "Here I Go Again",
        "genre": "Rock",
        "tag1": "Geffen Records",
        "tag2": "David Coverdale",
        "tag3": "1980s",
        "tag4": "Bernie Marsden",
        "tag5": "Martin Birch"
    },
    {
        "id": 125,
        "quote": "What’s your name, who’s your daddy... does he grill like me?",
        "attribution": "The Zombies",
        "category": "Music",
        "title": "Time of the Season",
        "genre": "Rock",
        "tag1": "Rod Argent",
        "tag2": "CBS Records",
        "tag3": "1960s",
        "tag4": "The Guess Who",
        "tag5": "Dave Matthews Band"
    },
    {
        "id": 126,
        "quote": "Little pink centers for you and me.",
        "attribution": "John Mellencamp",
        "category": "Music",
        "title": "Pink Houses",
        "genre": "Rock",
        "tag1": "Riva Records",
        "tag2": "Don Gehman",
        "tag3": "1980s"
    },
    {
        "id": 127,
        "quote": "Cause chefs like us, baby we were born to grill.",
        "attribution": "Bruce Springsteen",
        "category": "Music",
        "title": "Born to Run",
        "genre": "Rock",
        "tag1": "Mike Appel",
        "tag2": "Columbia Records",
        "tag3": "1970s",
        "tag4": "Frankie Goes To Hollywood"
    },
    {
        "id": 128,
        "quote": "Grill's been good to me so far.",
        "attribution": "Joe Walsh",
        "category": "Music",
        "title": "Life's Been Good",
        "genre": "Rock",
        "tag1": "Bill Szymczyk",
        "tag2": "Reggae",
        "tag3": "1970s",
        "tag4": "Asylum Records"
    },
    {
        "id": 129,
        "quote": "Any steak is good steak. So I took what I could get. Yeah, I took what I could get. She looked at me with big brown eyes, and said, “This steak is your best yet”.",
        "attribution": "Bachman-Turner Overdrive",
        "category": "Music",
        "title": "You Ain't Seen Nothing Yet",
        "genre": "Rock",
        "tag1": "Mercury Records",
        "tag2": "Randy Bachman",
        "tag3": "1970s",
        "tag4": "Fred Turner",
        "tag5": "Tim Bachman",
        "tag6": "Robbie Bachman"
    },
    {
        "id": 130,
        "quote": "You get your mutton for nothin and your chips for free.",
        "attribution": "Dire Straits",
        "category": "Music",
        "title": "Money for Nothing",
        "genre": "Rock",
        "tag1": "Vertigo Records",
        "tag2": "Sting",
        "tag3": "1980s",
        "tag4": "Mark Knopfler",
        "tag5": "Neil Dorfsman"
    },
    {
        "id": 131,
        "quote": "Twistin' 'round the fire, havin' fun, grilling tenderloins, grilling in the sun",
        "attribution": "The B-52's",
        "category": "Music",
        "title": "Rock Lobster",
        "genre": "Alternative, Pop, Rock",
        "tag1": "DB Records",
        "tag2": "Warner Bros Records",
        "tag3": "1970s"
    },
    {
        "id": 132,
        "quote": "You've got to know when to roast 'em. Know when to grill 'em. Know when to barbeque. And know when to eat.",
        "attribution": "Kenny Rogers",
        "category": "Music",
        "title": "The Gambler",
        "genre": "Country",
        "tag1": "Don Schlitz",
        "tag2": "Larry Butler",
        "tag3": "1970s",
        "tag4": "United Artists Records",
        "tag5": "Bobby Bare",
        "tag6": "Johnny Cash"
    },
    {
        "id": 133,
        "quote": "Yeah, get down, turn around, go to town, grill scrapin’ boogie.",
        "attribution": "Brooks and Dunn",
        "category": "Music",
        "title": "Boot Scootin' Boogie",
        "genre": "Country",
        "tag1": "Arista Nashville Records",
        "tag2": "Ronnie Dunn",
        "tag3": "1990s",
        "tag4": "Don Cook",
        "tag5": "Scott Hendricks"
    },
    {
        "id": 134,
        "quote": "What kind of lunch is this?",
        "attribution": "Streetheart",
        "category": "Music",
        "title": "What Kind of Love Is This",
        "genre": "Rock",
        "tag1": "Capitol Records",
        "tag3": "1980s"
    },
    {
        "id": 135,
        "quote": "Let's grill tonight, share the spice of life, baby slice it right, we're gonna grill tonight.",
        "attribution": "Earth Wind & Fire",
        "category": "Music",
        "title": "Let's Groove",
        "genre": "R&B, Pop",
        "tag1": "Columbia Records",
        "tag2": "Maurice White",
        "tag3": "1980s",
        "tag4": "Wayne Vaughn"
    },
    {
        "id": 136,
        "quote": "Just de-lovely and delicious.",
        "attribution": "Deee-Lite",
        "category": "Music",
        "title": "Groove Is In the Heart",
        "genre": "Alternative, Pop",
        "tag1": "Elektra Records",
        "tag2": "Dmitry Brill",
        "tag3": "1990s",
        "tag4": "Chung Dong-Hwa",
        "tag5": "Jonathan Davis",
        "tag6": "Kierin Kirby",
        "tag7": "Herbie Hancock"
    },
    {
        "id": 137,
        "quote": "I've got my steak, I've got my Orange Crush.",
        "attribution": "REM",
        "category": "Music",
        "title": "Orange Crush",
        "genre": "Rock, Alternative, Alt-Rock",
        "tag1": "Warner Bros Records",
        "tag2": "Bill Berry",
        "tag3": "1980s",
        "tag4": "Peter Buck",
        "tag5": "Mike Mills",
        "tag6": "Michael Stipe",
        "tag7": "Scott Litt"
    },
    {
        "id": 138,
        "quote": "This one goes out to the one I feed.",
        "attribution": "REM",
        "category": "Music",
        "title": "The One I Love",
        "genre": "Rock, Alternative, Alt-Rock",
        "tag1": "I.R.S. Records",
        "tag2": "Bill Berry",
        "tag3": "1980s",
        "tag4": "Peter Buck",
        "tag5": "Mike Mills",
        "tag6": "Michael Stipe",
        "tag7": "Scott Litt"
    },
    {
        "id": 139,
        "quote": "And they asked me if I would grill a little dinner, and I grilled with all my heart.",
        "attribution": "Marc Cohn",
        "category": "Music",
        "title": "Walking in Memphis",
        "genre": "Country, Pop",
        "tag1": "Rock",
        "tag2": "Ben Wisch",
        "tag3": "1990s",
        "tag4": "Atlantic Records"
    },
    {
        "id": 140,
        "quote": "He grills the steaks that remind him of the good times, he grills the steaks that remind him of the better times (Don't cry for me, next-door neighbor).",
        "attribution": "Chumbawamba",
        "category": "Music",
        "title": "Tubthumping",
        "genre": "Pop",
        "tag1": "Universal Records",
        "tag2": "Republic Records",
        "tag3": "1990s",
        "tag4": "EMI Records"
    },
    {
        "id": 141,
        "quote": "Every time I think of you, I know we have to eat, and I just can't grill enough, and I just can't grill enough.",
        "attribution": "Depeche Mode",
        "category": "Music",
        "title": "Just Can't Get Enough",
        "genre": "Alternative, Pop",
        "tag1": "Daniel Miller",
        "tag2": "Vince Clark",
        "tag3": "1980s",
        "tag4": "Mute Records",
        "tag5": "Polydor Records"
    },
    {
        "id": 142,
        "quote": "Watch out. I might grill what you're after.",
        "attribution": "Talking Heads",
        "category": "Music",
        "title": "Burning Down the House",
        "genre": "Alternative, Pop",
        "tag1": "Pop",
        "tag2": "Sire Records",
        "tag3": "1980s",
        "tag4": "David Byrne",
        "tag5": "Tina Weymouth",
        "tag6": "Chris Frantz",
        "tag7": "Jerry Harrison",
        "tag8": "Tom Jones and the Cardigans",
        "tag9": "V2 Records"
    },
    {
        "id": 143,
        "quote": "You are the steak sauce of my life.",
        "attribution": "Stevie Wonder",
        "category": "Music",
        "title": "You Are the Sunshine of My Life",
        "genre": "R&B, Pop",
        "tag1": "Rock",
        "tag2": "Motown Records",
        "tag3": "1970s",
        "tag4": "Jim Gilstrap",
        "tag5": "Lani Groves",
        "tag6": "Gloria Barley",
        "tag7": "Tamla Records"
    },
    {
        "id": 144,
        "quote": "The best thing about bein’ a griller, is the prerogative to char a little bun.",
        "attribution": "Shania Twain",
        "category": "Music",
        "title": "Man! I Feel Like A Woman",
        "genre": "Pop, Country",
        "tag1": "Robert \"Mutt\" Lange",
        "tag2": "Mercury Nashville Records",
        "tag3": "1990s"
    },
    {
        "id": 145,
        "quote": "Here’s the one that you want, ooh ooh ooh.",
        "attribution": "Grease",
        "category": "Music",
        "title": "You're the One That I Want",
        "genre": "Pop",
        "tag1": "RSO Records",
        "tag2": "John Farrar",
        "tag3": "1970s",
        "tag4": "Olivia Newton-John",
        "tag5": "John Travolta",
        "tag6": "Epic Records"
    },
    {
        "id": 146,
        "quote": "This dinner ain't for everybody, only the hungry people.",
        "attribution": "Salt 'N' Pepa",
        "category": "Music",
        "title": "Push It",
        "genre": "Hip-Hop",
        "tag1": "Ray Davies",
        "tag2": "Hurby Azor",
        "tag3": "1980s",
        "tag4": "Next Plateau Entertainment",
        "tag5": "London Recordings"
    },
    {
        "id": 147,
        "quote": "I think, therefore I grill.",
        "attribution": "Rene Descartes",
        "category": "Philosophy",
        "title": "Discourse on the Method",
        "genre": "French",
        "tag1": "Literature"
    },
    {
        "id": 148,
        "quote": "That’s one small steak for man, one giant T-bone for mankind.",
        "attribution": "Neil Armstrong",
        "category": "Famous",
        "title": "American",
        "genre": "Space",
        "tag1": "Astronaut",
        "tag3": "1960s"
    },
    {
        "id": 149,
        "quote": "Grill it for me baby (Uh huh, uh huh).",
        "attribution": "The Offspring",
        "category": "Music",
        "title": "Pretty Fly (for a White Guy)",
        "genre": "Rock",
        "tag1": "Punk",
        "tag2": "Columbia Records",
        "tag3": "1980s",
        "tag4": "Dave Jerden",
        "tag5": "Robert \"Mutt\" Lange",
        "tag6": "Steve Clark",
        "tag7": "Joe Elliott",
        "tag8": "Dexter Holland"
    },
    {
        "id": 150,
        "quote": "Hey, come out and grill.",
        "attribution": "The Offspring",
        "category": "Music",
        "title": "Come Out and Play",
        "genre": "Rock",
        "tag1": "Thom Wilson",
        "tag2": "Punk",
        "tag3": "1990s",
        "tag4": "Epitaph Records",
        "tag5": "Dexter Holland"
    },
    {
        "id": 151,
        "quote": "I can char, baste, sizzle and flip, do things with my grill that most men can't.",
        "attribution": "Brooks and Dunn",
        "category": "Music",
        "title": "Hard Workin' Man",
        "genre": "Country",
        "tag1": "Ronnie Dunn",
        "tag2": "Don Cook",
        "tag3": "1990s",
        "tag4": "Scott Hendricks",
        "tag5": "Brothers Osborne",
        "tag6": "Arista Nashville"
    },
    {
        "id": 152,
        "quote": "Give me liberty or give me steak!",
        "attribution": "Patrick Henry",
        "category": "Historical",
        "title": "Give me liberty or give me death",
        "genre": "American",
        "tag1": "American revolution"
    },
    {
        "id": 153,
        "quote": "We shall grill on the beaches, we shall grill on the landing grounds, we shall grill in the fields and in the streets, we shall grill in the hills; we shall never order delivery.",
        "attribution": "Winston Churchill",
        "category": "Historical",
        "title": "We shall fight on the beaches",
        "genre": "English",
        "tag1": "WW2",
        "tag3": "1940s"
    },
    {
        "id": 154,
        "quote": "Let them eat steak.",
        "attribution": "Marie Antoinette",
        "category": "Famous",
        "title": "French revolution",
        "genre": "French",
        "tag1": "Queens",
        "tag2": "Monarchs"
    },
    {
        "id": 155,
        "quote": "A journey of a thousand miles begins with a single steak.",
        "attribution": "Lao Tzu",
        "category": "Philosophy",
        "genre": "Ancient",
        "tag1": "Wisdom",
        "tag2": "Chinese"
    },
    {
        "id": 156,
        "quote": "Where there’s a grill, there’s a steak.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "genre": "English"
    },
    {
        "id": 157,
        "quote": "Four score and seven years ago, our fathers brought forth, upon this barbeque, a steak, conceived in marinade, and dedicated to the proposition that all steaks are not created equal.",
        "attribution": "Abraham Lincoln",
        "category": "Famous",
        "title": "Gettysburg Address",
        "genre": "Civil war",
        "tag2": "American civil war",
        "tag4": "American",
        "tag5": "President"
    },
    {
        "id": 158,
        "quote": "Grill one for the gipper.",
        "attribution": "Knute Rockne",
        "category": "Movies",
        "title": "Knute Rockne, All American",
        "genre": "Drama",
        "tag1": "Sports",
        "tag2": "Ronald Reagan",
        "tag3": "1940s",
        "tag4": "American",
        "tag5": "President",
        "tag6": "George Gipp",
        "tag7": "Lloyd Bacon",
        "tag8": "Warner Bros. Pictures",
        "tag9": "Pat O'Brien",
        "field16": "1980s"
    },
    {
        "id": 159,
        "quote": "He will win who knows when to baste and when not to baste.",
        "attribution": "Sun Tzu",
        "category": "Philosophy",
        "title": "Ancient",
        "genre": "Wisdom",
        "tag1": "Chinese",
        "tag2": "Military",
        "tag4": "General"
    },
    {
        "id": 160,
        "quote": "Pound and marinate before you make a move.",
        "attribution": "Sun Tzu",
        "category": "Philosophy",
        "title": "Ancient",
        "genre": "Wisdom",
        "tag1": "Chinese",
        "tag2": "Military",
        "tag4": "General"
    },
    {
        "id": 161,
        "quote": "The unattended grill is not worth having.",
        "attribution": "Socrates",
        "category": "Philosophy",
        "title": "Ancient",
        "genre": "Greek"
    },
    {
        "id": 162,
        "quote": "Liberty exists in grilling what one desires.",
        "attribution": "John Stuart Mill",
        "category": "Historical",
        "title": "English"
    },
    {
        "id": 163,
        "quote": "This is patently absurd; but whoever wishes to become a griller must learn not be frightened by high heat.",
        "attribution": "Bertrand Russell",
        "category": "Famous",
        "title": "English"
    },
    {
        "id": 164,
        "quote": "We are what we repeatedly do. Grilling then, is not an act, but a habit.",
        "attribution": "Socrates",
        "category": "Philosophy",
        "title": "Ancient",
        "genre": "Greek"
    },
    {
        "id": 165,
        "quote": "The mouth is furnished with tastes, by grilling, alone.",
        "attribution": "John Locke",
        "category": "Historical",
        "title": "English"
    },
    {
        "id": 166,
        "quote": "Grilling is at once sublime and the most trivial of human pursuits.",
        "attribution": "William James",
        "category": "Historical",
        "title": "American"
    },
    {
        "id": 167,
        "quote": "All that is necessary for the triumph of evil is that good men do no grilling.",
        "attribution": "Edmund Burke",
        "category": "Famous",
        "title": "English",
        "genre": "Irish"
    },
    {
        "id": 168,
        "quote": "Happiness is the hottest grill.",
        "attribution": "Aristotle",
        "category": "Philosophy",
        "title": "Ancient",
        "genre": "Greek"
    },
    {
        "id": 169,
        "quote": "Two steaks are better than one.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 170,
        "quote": "The grill is mightier than the microwave.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 171,
        "quote": "Never put off until tomorrow what you can grill today.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 172,
        "quote": "Grill steak while the sun shines.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 173,
        "quote": "Learn to grill before you run.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 174,
        "quote": "Grilling is the best medicine.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 175,
        "quote": "Half a steak is better than none.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 176,
        "quote": "Good things come to those who grill.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 177,
        "quote": "Give them a taste and they'll take a chuck.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 178,
        "quote": "The best things in life are grilled.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 179,
        "quote": "A steak a day keeps the vegan away.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 180,
        "quote": "All’s well that grills well.",
        "attribution": "English Proverb",
        "category": "Philosophy",
        "title": "English"
    },
    {
        "id": 181,
        "quote": "I came, I saw, I grilled.",
        "attribution": "Julius Caesar",
        "category": "Historical",
        "title": "Roman",
        "genre": "Generals",
        "tag1": "Ancient"
    },
    {
        "id": 182,
        "quote": "Morality is not the doctrine of how we make ourselves happy, but of how we may make ourselves worthy of grilling.",
        "attribution": "Immanuel Kant",
        "category": "Philosophy",
        "title": "German"
    },
    {
        "id": 183,
        "quote": "Man is condemned to be grilling.",
        "attribution": "Jean-Paul Sartre",
        "category": "Famous",
        "title": "French"
    },
    {
        "id": 184,
        "quote": "That man is wisest who, like Socrates, realizes his grilling is priceless.",
        "attribution": "Plato",
        "category": "Philosophy",
        "title": "Ancient",
        "genre": "Greek"
    },
    {
        "id": 185,
        "quote": "The function of grilling is not to influence God, but rather to change the nature of the griller.",
        "attribution": "Soren Kierkegaard",
        "category": "Philosophy",
        "title": "Danish"
    },
    {
        "id": 186,
        "quote": "If you would be a real seeker after the truth, it is necessary that at least once in your life you grill, as much as possible, all things.",
        "attribution": "Rene Descartes",
        "category": "Philosophy",
        "title": "French"
    },
    {
        "id": 187,
        "quote": "Happiness lies in virtuous grilling, and perfect happiness lies in the best activity, which is tasting.",
        "attribution": "Aristotle",
        "category": "Philosophy",
        "title": "Greek",
        "genre": "Ancient"
    },
    {
        "id": 188,
        "quote": "I can control my passions and emotions if I can understand my grill.",
        "attribution": "Spinoza",
        "category": "Philosophy",
        "title": "Dutch"
    },
    {
        "id": 189,
        "quote": "The proper function of man is not to exist, but to grill.",
        "attribution": "Jack London",
        "category": "Philosophy",
        "title": "American"
    },
    {
        "id": 190,
        "quote": "Steak without marinade is the slowest route to satisfaction. Marinade without steak is the noise before disgust.",
        "attribution": "Sun Tzu",
        "category": "Philosophy",
        "title": "Ancient",
        "genre": "Wisdom",
        "tag1": "Chinese",
        "tag2": "Military",
        "tag4": "Generals"
    },
    {
        "id": 191,
        "quote": "Ask not what your grill can do for you, ask what you can do for your grill.",
        "attribution": "John F Kennedy",
        "category": "Famous",
        "tag1": "President",
        "tag2": "American",
        "tag3": "1960s"
    },
    {
        "id": 192,
        "quote": "Open the grill's lid now, HAL.",
        "attribution": "Dave Bowman",
        "category": "Movies",
        "title": "2001: A Space Odyssey",
        "genre": "Sci-fi",
        "tag1": "Keir Dullea",
        "tag2": "Gary Lockwood",
        "tag3": "1960s",
        "tag4": "Metro-Goldwyn-Mayer",
        "tag5": "Arthur C. Clarke",
        "tag6": "Stanley Kubrick"
    },
    {
        "id": 193,
        "quote": "Wait a minute, wait a minute. We ain't grilled nothin' yet.",
        "attribution": "Al Jolson",
        "category": "Movies",
        "title": "The Jazz Singer",
        "genre": "Drama",
        "tag1": "May McAvoy",
        "tag2": "Warner Oland",
        "tag3": "1920s",
        "tag4": "Yossele Rosenblatt",
        "tag5": "Alan Crosland",
        "tag6": "Warner Bros. Pictures"
    },
    {
        "id": 194,
        "quote": "Food is served ‘cause I'm stone-hot grillin.",
        "attribution": "Young MC",
        "category": "Music",
        "title": "Bust a Move",
        "genre": "Hip-Hop",
        "tag1": "Marvin Young",
        "tag2": "Matt Dike",
        "tag3": "1980s",
        "tag4": "Michael Ross",
        "tag5": "Delicious Vinyl",
        "tag6": "Island Records",
        "tag7": "4th & B'way Records",
        "tag8": "Ariola Records"
    },
    {
        "id": 195,
        "quote": "A world without burgers is like a world without sun, you can't grill meats with anyone.",
        "attribution": "Kiss",
        "category": "Music",
        "title": "A World Without Heroes",
        "genre": "Rock",
        "tag1": "Gene Simmons",
        "tag2": "Casablanca Records",
        "tag3": "1980s",
        "tag4": "Bob Ezrin",
        "tag5": "Lou Reed",
        "tag6": "Paul Stanley",
        "tag7": "Cher"
    },
    {
        "id": 196,
        "quote": "It’s tricky to grill a steak, to grill a steak just right on time it’s tricky. It’s tricky (tricky) tricky (tricky).",
        "attribution": "Run-DMC",
        "category": "Music",
        "title": "It's Tricky",
        "genre": "Hip-Hop",
        "tag1": "Rick Rubin",
        "tag2": "Profile Records",
        "tag3": "1980s",
        "tag4": "Joseph Simmons",
        "tag5": "Darryl McDaniels",
        "tag6": "Doug Fieger",
        "tag7": "Berton Averre"
    },
    {
        "id": 197,
        "quote": "It’s tricky to grill a steak, to grill a steak just right on time it’s tricky.",
        "attribution": "Run-DMC",
        "category": "Music",
        "title": "It's Tricky",
        "genre": "Hip-Hop",
        "tag1": "Rick Rubin",
        "tag2": "Profile Records",
        "tag3": "1980s",
        "tag4": "Joseph Simmons",
        "tag5": "Darryl McDaniels",
        "tag6": "Doug Fieger",
        "tag7": "Berton Averre"
    },
    {
        "id": 198,
        "quote": "I love barbeque, so put another loin on the grill there baby.",
        "attribution": "Joan Jett and the Blackhearts",
        "category": "Music",
        "title": "I Love Rock And Roll",
        "genre": "Rock",
        "tag1": "Arrows",
        "tag2": "Mickie Most",
        "tag3": "1980s",
        "tag4": "1970s",
        "tag5": "Boardwalk Records",
        "tag6": "Alan Merrill",
        "tag7": "Jake Hooker",
        "tag8": "Britney Spears"
    },
    {
        "id": 199,
        "quote": "We can grill if we want to, we can leave your friends behind. 'Cause your friends don't grill and if they don't grill, well, they're no friends of mine.",
        "attribution": "Men Without Hats",
        "category": "Music",
        "title": "The Safety Dance",
        "genre": "Pop, Alternative",
        "tag1": "Marc Durand",
        "tag2": "Virgin Records",
        "tag3": "1980s",
        "tag4": "Ivan Doroschuk"
    },
    {
        "id": 200,
        "quote": "I'm gonna braise you like I should.",
        "attribution": "Fatboy Slim",
        "category": "Music",
        "title": "Praise You",
        "genre": "Alternative, Pop",
        "tag1": "Norman Cook",
        "tag2": "Astralwerks Records",
        "tag3": "1990s",
        "tag4": "Skint Records",
        "tag5": "Camille Yarbrough"
    },
    {
        "id": 201,
        "quote": "Once more, you open the door, and you're here in my home and my steak will go on and sear.",
        "attribution": "Celine Dion",
        "category": "Music",
        "title": "My Heart Will Go On",
        "genre": "Pop",
        "tag1": "Epic Records",
        "tag2": "James Horner",
        "tag3": "1990s",
        "tag4": "Will Jennings",
        "tag5": "Simon Franglen",
        "tag6": "Walter Afanasieff",
        "tag7": "Titanic",
        "tag8": "Sony Music",
        "tag9": "Columbia Records"
    },
    {
        "id": 202,
        "quote": "On a dark desert cookout, cool wind in my hair, warm smell of barbeque, rising up through the air.",
        "attribution": "The Eagles",
        "category": "Music",
        "title": "Hotel California",
        "genre": "Rock, Pop",
        "tag1": "Bill Szymczyk",
        "tag2": "Don Felder",
        "tag3": "1970s",
        "tag4": "Don Henley",
        "tag5": "Glenn Frey",
        "tag6": "The Killers",
        "tag7": "Nancy Sinatra",
        "tag8": "Asylum Records"
    },
    {
        "id": 203,
        "quote": "Hard to say too spicy.",
        "attribution": "Chicago",
        "category": "Music",
        "title": "Hard To Say I'm Sorry",
        "genre": "Pop",
        "tag1": "Peter Cetera",
        "tag2": "Full Moon Records",
        "tag3": "1980s",
        "tag4": "Warner Bros Records",
        "tag5": "David Foster"
    },
    {
        "id": 204,
        "quote": "Yes it’s true - I am happy to be sauced with you.",
        "attribution": "Huey Lewis and the News",
        "category": "Music",
        "title": "Stuck with You",
        "genre": "Rock, Pop",
        "tag1": "Chrysalis Records",
        "tag2": "Chris Hayes",
        "tag3": "1980s"
    },
    {
        "id": 205,
        "quote": "(Everything I grill) I grill it for you.",
        "attribution": "Bryan Adams",
        "category": "Music",
        "title": "Everything I Do, I Do It For You",
        "genre": "Rock, Pop",
        "tag1": "Robert \"Mutt\" Lange",
        "tag2": "Michael Kamen",
        "tag3": "1990s",
        "tag4": "A&M Records",
        "tag5": "Brandy",
        "tag6": "Atlantic Records"
    },
    {
        "id": 206,
        "quote": "Grill, you know it's true.",
        "attribution": "Milli Vanilli",
        "category": "Music",
        "title": "Girl You Know It's True",
        "genre": "Pop, R&B",
        "tag1": "Arista Records",
        "tag2": "Frank Farian",
        "tag3": "1980s"
    },
    {
        "id": 207,
        "quote": "A little bit of tenderloin in my life, a little bit of beef wellington by my side, a little bit of bacon is all I need, a little bit of brisket is what I see, a little bit of rare steak in the sun, a little bit of burger all night long, a little bit of pork roast here I am, a little bit of you makes me your chef.",
        "attribution": "Lou Bega",
        "category": "Music",
        "title": "Mambo No. 5",
        "genre": "R&B, Pop",
        "tag1": "Soul",
        "tag2": "1950s",
        "tag3": "1990s",
        "tag4": "Zippy Davids",
        "tag5": "Goar B",
        "tag6": "Frank Lio",
        "tag7": "Donald Fact",
        "tag8": "Peter Meisel",
        "tag9": "Ariola",
        "field16": "RCA Records",
        "field17": "Pérez Prado",
        "field18": "Cuba"
    },
    {
        "id": 208,
        "quote": "I do my steak sauce, check my nails, baby how you grillin' (grilling good as hell).",
        "attribution": "Lizzo",
        "category": "Music",
        "title": "Good as Hell",
        "genre": "Pop",
        "tag1": "Nice Life Recording Company",
        "tag2": "Atlantic Records",
        "tag3": "2010s",
        "tag4": "Ricky Reed",
        "tag5": "Eric Frederic",
        "tag6": "Melissa Jefferson",
        "tag7": "Ariana Grande"
    },
    {
        "id": 209,
        "quote": "It's tricky to bake a pie, to bake a pie that's right on time, it's tricky.",
        "attribution": "Run DMC",
        "category": "Music",
        "title": "It's Tricky",
        "genre": "Hip-Hop",
        "tag1": "Rick Rubin",
        "tag2": "Profile Records",
        "tag3": "1980s",
        "tag4": "Joseph Simmons",
        "tag5": "Darryl McDaniels",
        "tag6": "Doug Fieger",
        "tag7": "Berton Averre"
    },
    {
        "id": 210,
        "quote": "I've been having dreams, grilling on a trampoline.",
        "attribution": "SHAED",
        "category": "Music",
        "title": "Trampoline",
        "genre": "Pop, Alternative",
        "tag1": "Photo Finish Records",
        "tag2": "Chelsea Lee",
        "tag3": "2010s",
        "tag4": "Max Ernst",
        "tag5": "Spencer Ernst",
        "tag6": "Alex Mendoza",
        "tag7": "Grant Eadie",
        "tag8": "Zayn Malik"
    },
    {
        "id": 211,
        "quote": "Doo, doo, doo grillin out my back door.",
        "attribution": "Creedence Clearwater Revival",
        "category": "Music",
        "title": "Lookin' Out My Back Door",
        "genre": "Rock",
        "tag1": "CCR",
        "tag2": "John Fogerty",
        "tag3": "1970s",
        "tag4": "Fantasy Records"
    },
    {
        "id": 212,
        "quote": "Grill me, grill me steaks now, or I'm gonna waste away.",
        "attribution": "The Rolling Stones",
        "category": "Music",
        "title": "Gimme Shelter",
        "genre": "Rock",
        "tag1": "ABKCO Music & Recordings",
        "tag2": "Decca Records",
        "tag3": "1960s",
        "tag4": "Mike Jagger",
        "tag5": "Keith Richards",
        "tag6": "Jimmy Miller",
        "tag7": "Merry Clayton",
        "tag8": "Bill Wyman",
        "tag9": "Charlie Watts",
        "field16": "U2"
    },
    {
        "id": 213,
        "quote": "If I look hard enough into the setting sun, my love will grill with me before the morning comes.",
        "attribution": "The Rolling Stones",
        "category": "Music",
        "title": "Paint It Black",
        "genre": "Rock",
        "tag1": "London Recordings",
        "tag2": "Decca Records",
        "tag3": "1960s",
        "tag4": "Mike Jagger",
        "tag5": "Keith Richards",
        "tag6": "Andrew Oldham",
        "tag7": "Brian Jones",
        "tag8": "Bill Wyman",
        "tag9": "Charlie Watts",
        "field16": "War",
        "field17": "U2"
    },
    {
        "id": 214,
        "quote": "You shouldn't let other people grill your ribs for you.",
        "attribution": "Bob Dylan",
        "category": "Music",
        "title": "Like A Rolling Stone",
        "genre": "Rock",
        "tag1": "Columbia Records",
        "tag2": "Tom Wilson",
        "tag3": "1960s",
        "tag4": "The Jimi Hendrix Experience"
    },
    {
        "id": 215,
        "quote": "A good grill is a continuous Christmas.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 216,
        "quote": "The eye of the master will do more work than both his tongs.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 217,
        "quote": "Grilling is constant proof that God loves us and loves to see us happy.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 218,
        "quote": "Tricks and microwaving are the practice of fools, that don't have brains enough to grill.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 219,
        "quote": "Do not fear mistakes. You will know failure. Continue to grill.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 220,
        "quote": "Where liberty is, there is my grill.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 221,
        "quote": "Hide not your tongs. They for use were made.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 222,
        "quote": "Being ignorant is not so much a shame, as being unwilling to grill.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 223,
        "quote": "It is the grilling man who is the happy man.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 224,
        "quote": "Without freedom of thought there can be no wisdom - and no such thing as perfect steak without freedom of saucing.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 225,
        "quote": "He that lives upon tofu will die fasting.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 226,
        "quote": "When in doubt, grill.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 227,
        "quote": "Diligence is the mother of good grilling.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 228,
        "quote": "In this world nothing can be said to be certain, except grilling and sauces.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 229,
        "quote": "The Constitution only gives people the right to pursue happiness. You have to grill it yourself.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 230,
        "quote": "Dost thou love life? Then do not squander grilling time, for that is the stuff life is made of.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 231,
        "quote": "Never leave that till tomorrow which you can grill today.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 232,
        "quote": "The doorstep to the temple of grilling is knowledge of our recipes.",
        "attribution": "Ben Franklin",
        "category": "Philosophy",
        "genre": "American",
        "tag1": "President",
        "tag2": "Founding Fathers"
    },
    {
        "id": 233,
        "quote": "Virtue is nothing else than the right grill.",
        "attribution": "Seneca the Younger",
        "category": "Philosophy",
        "genre": "Roman",
        "tag1": "Ancient"
    },
    {
        "id": 234,
        "quote": "Freedom is not secured by the fulfilling of one's desires, but by the removal of char.",
        "attribution": "Epictetus",
        "category": "Philosophy",
        "genre": "Roman",
        "tag1": "Greek",
        "tag2": "Ancient"
    },
    {
        "id": 235,
        "quote": "A little philosophy inclineth man's mind to frying; but depth in philosophy bringeth men's minds about to grilling.",
        "attribution": "Sir Francis Bacon",
        "category": "Philosophy",
        "genre": "English"
    },
    {
        "id": 236,
        "quote": "I have nothing to offer but beef, t-bones, tacos and sausage.",
        "attribution": "Winston Churchill",
        "category": "Historical",
        "genre": "WW2",
        "tag1": "English",
        "tag3": "1940s"
    },
    {
        "id": 237,
        "quote": "The darkest places in hell are reserved for those who maintain their neutrality in times of ordering steak.",
        "attribution": "Dante Alighieri",
        "category": "Historical",
        "title": "Italian"
    },
    {
        "id": 238,
        "quote": "Life without liberty is like a body without steak.",
        "attribution": "Khalil Gibran",
        "category": "Historical",
        "title": "Lebanese",
        "genre": "American"
    },
    {
        "id": 239,
        "quote": "The only thing we have to fear is burn itself.",
        "attribution": "Franklin Delano Roosevelt",
        "category": "Historical",
        "genre": "President",
        "tag1": "American",
        "tag3": "1990s"
    },
    {
        "id": 240,
        "quote": "Mr. Gorbachev, fire up this grill.",
        "attribution": "Ronald Reagan",
        "category": "Historical",
        "genre": "President",
        "tag1": "American",
        "tag3": "1990s"
    },
    {
        "id": 241,
        "quote": "Grilling by the people, for the people.",
        "attribution": "Abraham Lincoln",
        "category": "Historical",
        "genre": "President",
        "tag1": "American"
    },
    {
        "id": 242,
        "quote": "Speak softly and carry a big spatula.",
        "attribution": "Theodore Roosevelt",
        "category": "Historical",
        "genre": "President",
        "tag1": "American"
    },
    {
        "id": 243,
        "quote": "Liberty and grilling, now and for ever, one and inseparable.",
        "attribution": "Daniel Webster",
        "category": "Historical",
        "title": "American"
    },
    {
        "id": 244,
        "quote": "The measure of a man is what he does with a grill.",
        "attribution": "Greek Proverb",
        "category": "Philosophy",
        "genre": "Greek"
    },
    {
        "id": 245,
        "quote": "A nation's treasure are its grillers.",
        "attribution": "Yiddish Proverb",
        "category": "Philosophy"
    },
    {
        "id": 246,
        "quote": "We were at a party, his ribeye fell in the deep. Someone reached in and ate it. It was a rock lobster.",
        "attribution": "The B-52's",
        "category": "Music",
        "title": "Rock Lobster",
        "genre": "Alternative, Pop",
        "tag1": "DB Records",
        "tag2": "Warner Bros Records",
        "tag3": "1970s"
    },
    {
        "id": 247,
        "quote": "Barbeque ain't air pollution, barbeque ain't gonna die.",
        "attribution": "AC/DC",
        "category": "Music",
        "title": "Rock and Ain't Noise Pollution",
        "genre": "Rock",
        "tag1": "Atlantic Records",
        "tag2": "Brian Johnson",
        "tag3": "1980s",
        "tag4": "Angus Young",
        "tag5": "Malcolm Young",
        "tag6": "Robert \"Mutt\" Lange",
        "tag7": "Cliff Williams",
        "tag8": "Phil Rudd"
    },
    {
        "id": 248,
        "quote": "Because you know I'm all about that steak, 'bout that steak, no tofu.",
        "attribution": "Meghan Trainor",
        "category": "Music",
        "title": "All About That Bass",
        "genre": "Pop",
        "tag1": "Kevin Kadish",
        "tag2": "Epic Records",
        "tag3": "2010s"
    },
    {
        "id": 249,
        "quote": "Grill or cook not, there is no fry.",
        "attribution": "Yoda",
        "category": "Movies",
        "title": "Star Wars",
        "genre": "Sci-fi",
        "tag1": "Irvin Kershner",
        "tag2": "Harrison Ford",
        "tag3": "1980s",
        "tag4": "Mark Hamill",
        "tag5": "Carrie Fisher",
        "tag6": "John Williams",
        "tag7": "20th Century Fox",
        "tag8": "George Lucas"
    },
    {
        "id": 250,
        "quote": "Cause I sauce to thrill and I'm ready to grill, and I can't get enough and I can't get my fill.",
        "attribution": "AC/DC",
        "category": "Music",
        "title": "Shoot to Thrill",
        "genre": "Rock",
        "tag1": "Atlantic Records",
        "tag2": "Angus Young",
        "tag3": "1980s",
        "tag4": "Malcolm Young",
        "tag5": "Brian Johnson",
        "tag6": "Robert \"Mutt\" Lange",
        "tag7": "Phil Rudd",
        "tag8": "Iron man",
        "tag9": "Columbia Records",
        "field16": "Cliff Williams"
    },
    {
        "id": 251,
        "quote": "Go ahead. Make my steak.",
        "attribution": "Harry Callahan",
        "category": "Movies",
        "title": "Sudden Impact",
        "genre": "Crime",
        "tag1": "Clint Eastwood",
        "tag2": "Sondra Locke",
        "tag3": "1980s",
        "tag4": "Warner Bros. Pictures"
    },
    {
        "id": 252,
        "quote": "Happiness can be found in even the darkest of times, if only one remembers to turn on the grill.",
        "attribution": "Albus Dumbledore",
        "category": "Movies",
        "title": "Harry Potter and the Prisoner of Azkaban",
        "genre": "Fantasy",
        "tag1": "Harry Potter",
        "tag2": "Wizardry",
        "tag3": "2000s",
        "tag4": "Warner Bros. Pictures",
        "tag5": "Michael Gambon",
        "tag6": "Magic"
    },
    {
        "id": 253,
        "quote": "I'm back in the game, grilling everything, no stopping me now, it's like I never been away.",
        "attribution": "Airbourne",
        "category": "Music",
        "title": "Back in the Game",
        "genre": "Rock",
        "tag1": "Roadrunner Records",
        "tag2": "David Roads",
        "tag3": "2010s",
        "tag4": "Joel O'Keeffe",
        "tag5": "Justin Street",
        "tag6": "Matt \"Harri\" Harrison",
        "tag7": "Ryan O'Keeffe"
    },
    {
        "id": 254,
        "quote": "Till I collapse I'm grilling these ribs long as you eat 'em 'til the day that I drop you'll never say that I'm not grilling 'em.",
        "attribution": "Eminem",
        "category": "Music",
        "title": "Till I Collapse",
        "genre": "Rap",
        "tag1": "Hip-Hop",
        "tag2": "Nate Dogg",
        "tag3": "2000s",
        "tag4": "Nathaniel Hale",
        "tag5": "Luis Resto",
        "tag6": "Aftermath Entertainment",
        "tag7": "Interscope Records"
    },
    {
        "id": 255,
        "quote": "Take me down to the paradise city, where the steak is lean and the grills are pretty, oh won't you please take me home.",
        "attribution": "Guns N' Roses",
        "category": "Music",
        "title": "Paradise City",
        "genre": "Rock",
        "tag1": "Geffen Records",
        "tag2": "Axl Rose",
        "tag3": "1980s",
        "tag4": "Slash",
        "tag5": "Mike Clink",
        "tag6": "Izzy Stradlin"
    },
    {
        "id": 256,
        "quote": "You burn 100% of the burgers you don't flip.",
        "attribution": "Wayne Gretzky",
        "category": "Sports",
        "genre": "Hockey",
        "tag1": "Canadian"
    },
    {
        "id": 257,
        "quote": "You grill your best when you have the fewest things to think about.",
        "attribution": "Bobby Jones",
        "category": "Sports",
        "genre": "Golf",
        "tag1": "American"
    },
    {
        "id": 258,
        "quote": "A good griller has the determination to eat and the patience to wait for the marinade.",
        "attribution": "Gary Player",
        "category": "Sports",
        "genre": "Golf",
        "tag1": "South African"
    },
    {
        "id": 259,
        "quote": "Your next steak is a new experience, it may be the best steak you ever grill.",
        "attribution": "Harvey Penick",
        "category": "Sports",
        "genre": "Golf",
        "tag1": "American"
    },
    {
        "id": 260,
        "quote": "Life is better when you're grilling.",
        "attribution": "Arnold Palmer",
        "category": "Sports",
        "genre": "Golf",
        "tag1": "American"
    },
    {
        "id": 261,
        "quote": "The most important meal in life is the next one.",
        "attribution": "Ben Hogan",
        "category": "Sports",
        "genre": "Golf",
        "tag1": "American"
    },
    {
        "id": 262,
        "quote": "Cookouts are like church, many attend, few understand.",
        "attribution": "Leo Durocher",
        "category": "Sports",
        "genre": "Baseball",
        "tag1": "American"
    },
    {
        "id": 263,
        "quote": "That's not a steak, THIS is a steak!",
        "attribution": "Crocodile Dundee",
        "category": "Movies",
        "title": "Crocodile Dundee",
        "genre": "Comedy",
        "tag1": "Adventure",
        "tag2": "Paramount Pictures",
        "tag3": "1980s",
        "tag4": "20th Century Fox",
        "tag5": "Paul Hogan",
        "tag6": "Peter Faiman"
    },
    {
        "id": 264,
        "quote": "I want a grill with a skirt steak and a Looong Island.",
        "attribution": "Cake",
        "category": "Music",
        "title": "Short Skirt/Long Jacket",
        "genre": "Rock, Alt-Rock",
        "tag1": "Columbia Records",
        "tag2": "John McCrea",
        "tag3": "2000s",
        "tag4": "Chuck"
    },
    {
        "id": 265,
        "quote": "You're face, to face, with the man who grilled the steak.",
        "attribution": "David Bowie",
        "category": "Music",
        "title": "The Man Who Sold The World",
        "genre": "Rock",
        "tag1": "Mercury Records",
        "tag2": "Tony Visconti",
        "tag3": "1970s",
        "tag5": "DGC Records",
        "tag6": "1990s",
        "tag7": "Alex Coletti",
        "tag8": "Scott Litt",
        "tag9": "Dave Grohl",
        "field16": "Kurt Cobain"
    },
    {
        "id": 266,
        "quote": "Girl look at that burger (ah) girl look at that burger (ah) girl look at that burger, I-I-I cookout.",
        "attribution": "LMFAO",
        "category": "Music",
        "title": "Sexy and I Know It",
        "genre": "Pop",
        "tag1": "Audiobot",
        "tag2": "Redfoo",
        "tag3": "2010s",
        "tag4": "GoonRock",
        "tag5": "Erin Beck",
        "tag6": "Interscope Records"
    },
    {
        "id": 267,
        "quote": "I'm saucy and I know it.",
        "attribution": "LMFAO",
        "category": "Music",
        "title": "Sexy and I Know It",
        "genre": "Pop",
        "tag1": "Audiobot",
        "tag2": "Redfoo",
        "tag3": "2010s",
        "tag4": "GoonRock",
        "tag5": "Erin Beck",
        "tag6": "Interscope Records"
    },
    {
        "id": 268,
        "quote": "I got burgers on the grill and I ain't afraid to show it, I'm saucy and I know it.",
        "attribution": "LMFAO",
        "category": "Music",
        "title": "Sexy and I know It",
        "genre": "Pop",
        "tag1": "Audiobot",
        "tag2": "Redfoo",
        "tag3": "2010s",
        "tag4": "GoonRock",
        "tag5": "Erin Beck",
        "tag6": "Interscope Records"
    },
    {
        "id": 269,
        "quote": "Master of all meats, I'm grilling your steaks. Twisting your mind and cooking your dreams.",
        "attribution": "Metallica",
        "category": "Music",
        "title": "Master of Puppets",
        "genre": "Rock",
        "tag1": "Cliff Burton",
        "tag2": "Elektra Records",
        "tag3": "1980s",
        "tag4": "Lars Ulrich",
        "tag5": "Kirk Hammett",
        "tag6": "James Hetfield",
        "tag7": "Music for Nations",
        "tag8": "New Electric Way",
        "tag9": "Flemming Rasmussen",
        "field16": "Metal"
    },
    {
        "id": 270,
        "quote": "You wouldn't like me when I'm hungry.",
        "attribution": "The Hulk",
        "category": "TV",
        "title": "The Incredible Hulk",
        "genre": "Action",
        "tag1": "Jack Kirby",
        "tag2": "Bill Bixby",
        "tag3": "1970s",
        "tag4": "Jack Colvin",
        "tag5": "Lou Ferrigno",
        "tag6": "Superhero",
        "tag7": "Universal Television",
        "tag8": "CBS",
        "tag9": "Stan Lee"
    },
    {
        "id": 271,
        "quote": "I can believe, today was a good steak.",
        "attribution": "Ice Cube",
        "category": "Music",
        "title": "It Was A Good Day",
        "genre": "Hip-Hop",
        "tag1": "Priority Records",
        "tag2": "O'Shea Jackson",
        "tag3": "1990s",
        "tag4": "Lench Mob Records",
        "tag5": "DJ Pooh"
    },
    {
        "id": 272,
        "quote": "Like a Mister I was born to grill T-bones.",
        "attribution": "Whitesnake",
        "category": "Music",
        "title": "Here I Go Again",
        "genre": "Rock",
        "tag1": "Geffen Records",
        "tag2": "David Coverdale",
        "tag3": "1980s",
        "tag4": "Bernie Marsden",
        "tag5": "Martin Birch"
    },
    {
        "id": 273,
        "quote": "I wanna grill! (Grill!)",
        "attribution": "Twisted Sister",
        "category": "Music",
        "title": "I Wanna Rock",
        "genre": "Rock",
        "tag1": "Dee Snider",
        "tag2": "Tom Werman",
        "tag3": "1980s",
        "tag4": "Atlantic Records"
    },
    {
        "id": 274,
        "quote": "Hello charcoal my old friend, I've come to grill with you again.",
        "attribution": "Simon & Garfunkel",
        "category": "Music",
        "title": "The Sound of Silence",
        "genre": "Pop",
        "tag1": "Columbia Records",
        "tag2": "Paul Simon",
        "tag3": "1960s",
        "tag4": "Tom Wilson",
        "tag5": "Art Garfunkel",
        "tag6": "Reprise Records",
        "tag7": "Disturbed"
    },
    {
        "id": 275,
        "quote": "Yeah, we're running a little bit hot tonight. I can barely see the grill from the heat coming off.",
        "attribution": "Van Halen",
        "category": "Music",
        "title": "Panama",
        "genre": "Rock",
        "tag1": "Warner Bros Records",
        "tag2": "Eddie Van Halen",
        "tag3": "1980s",
        "tag4": "Alex Van Halen",
        "tag5": "Michael Anthony",
        "tag6": "David Lee Roth",
        "tag7": "Ted Templeton"
    },
    {
        "id": 276,
        "quote": "I wield the tongs that make the whole grill sing.",
        "attribution": "Barry Manilow",
        "category": "Music",
        "title": "I Write the Songs",
        "genre": "Pop",
        "tag1": "Bruce Johnston",
        "tag2": "Arista Records",
        "tag3": "1970s",
        "tag4": "Ron Dante",
        "tag5": "RCA Records"
    },
    {
        "id": 277,
        "quote": "I grill the steaks that make the young girls sigh, I grill the steaks, I grill the steaks.",
        "attribution": "Barry Manilow",
        "category": "Music",
        "title": "I Write the Songs",
        "genre": "Pop",
        "tag1": "Bruce Johnston",
        "tag2": "Arista Records",
        "tag3": "1970s",
        "tag4": "Ron Dante",
        "tag5": "RCA Records"
    },
    {
        "id": 278,
        "quote": "Reach down, between the grills, and light the coals up.",
        "attribution": "Van Halen",
        "category": "Music",
        "title": "Panama",
        "genre": "Rock",
        "tag1": "Warner Bros Records",
        "tag2": "Eddie Van Halen",
        "tag3": "1980s",
        "tag4": "Alex Van Halen",
        "tag5": "Michael Anthony",
        "tag6": "David Lee Roth",
        "tag7": "Ted Templeton"
    },
    {
        "id": 279,
        "quote": "Who let the chef out? (Who, who, who who who?)",
        "attribution": "Baha Men",
        "category": "Music",
        "title": "Who Let the Dogs Out",
        "genre": "Pop",
        "tag1": "S-Curve Records",
        "tag2": "Anslem Douglas",
        "tag3": "1990s",
        "tag4": "Steve Greenberg",
        "tag5": "Matthew Traynor"
    },
    {
        "id": 280,
        "quote": "All my friends know the slow smoker.",
        "attribution": "War",
        "category": "Music",
        "title": "Low Rider",
        "genre": "Soul, R&B",
        "tag1": "R&B",
        "tag2": "United Artists Records",
        "tag3": "1970s",
        "tag4": "Jerry Goldstein",
        "tag5": "Howard E. Scott",
        "tag6": "Lonnie Jordan",
        "tag7": "Funk"
    },
    {
        "id": 281,
        "quote": "Life it seems, will grill away.",
        "attribution": "Metallica",
        "category": "Music",
        "title": "Fade to Black",
        "genre": "Rock",
        "tag1": "Metal",
        "tag2": "Lars Ulrich",
        "tag3": "1980s",
        "tag4": "Elektra Records",
        "tag5": "Cliff Burton",
        "tag6": "James Hetfield",
        "tag7": "Flemming Rasmussen"
    },
    {
        "id": 282,
        "quote": "How you grillin?",
        "attribution": "Joey Tribbiani",
        "category": "TV",
        "title": "Friends",
        "genre": "Comedy",
        "tag1": "Sitcom",
        "tag2": "Jennifer Aniston",
        "tag3": "1990s",
        "tag4": "Courteney Cox",
        "tag5": "Lisa Kudrow",
        "tag6": "Matt LeBlanc",
        "tag7": "Matthew Perry",
        "tag8": "David Schwimmer",
        "tag9": "Warner Bros.",
        "field16": "David Crane",
        "field17": "Marta Kauffman",
        "field18": "NBC"
    },
    {
        "id": 283,
        "quote": "It ain't me, it ain't me. I ain't no vegetarian's son.",
        "attribution": "Creedence Clearwater Revival",
        "category": "Music",
        "title": "Fortunate Son",
        "genre": "Rock",
        "tag1": "John Fogerty",
        "tag2": "Fantasy Records",
        "tag3": "1960s"
    },
    {
        "id": 284,
        "quote": "You're face, to face, with the man who grilled the steak.",
        "attribution": "Nirvana",
        "category": "Music",
        "title": "The Man Who Sold The World",
        "genre": "Alternative, Rock, Alt-Rock",
        "tag1": "Grunge",
        "tag2": "Kurt Cobain",
        "tag3": "1990s",
        "tag4": "Dave Grohl",
        "tag5": "Krist Novoselic",
        "tag6": "Pat Smear",
        "tag7": "Alex Coletti"
    },
    {
        "id": 285,
        "quote": "The moon comes up and the sun goes down, we find a little spot on the patio, grab some, eat a little, pass it around, dance in the dust, turn the barbeque on.",
        "attribution": "Florida Georgia Line",
        "category": "Music",
        "title": "Round Here",
        "genre": "Country",
        "tag1": "Joey Moi",
        "tag2": "BMLG Records",
        "tag3": "2010s",
        "tag4": "Rodney Clawson",
        "tag5": "Chris Tompkins",
        "tag6": "Thomas Rhett"
    },
    {
        "id": 286,
        "quote": "And that seasoned burger whispers temptation in my ear, it's a feedin' alright Saturday night, and that's how we grill it 'round here.",
        "attribution": "Florida Georgia Line",
        "category": "Music",
        "title": "Round Here",
        "genre": "Country",
        "tag1": "Joey Moi",
        "tag2": "BMLG Records",
        "tag3": "2010s",
        "tag4": "Rodney Clawson",
        "tag5": "Chris Tompkins",
        "tag6": "Thomas Rhett"
    },
    {
        "id": 287,
        "quote": "I'm about to lose my mind, you've been gone for so long, I'm runnin' out of time, I need a burger, grill me a burger, I need a burger, burger, to bring me back to life.",
        "attribution": "Skylar Grey",
        "category": "Music",
        "title": "I Need a Doctor",
        "genre": "Rap",
        "tag1": "Hip-Hop",
        "tag2": "Eminem",
        "tag3": "2010s",
        "tag4": "Dr. Dre",
        "tag5": "Aftermath Entertainment",
        "tag6": "Interscope Records",
        "tag7": "Alex da Kid"
    },
    {
        "id": 288,
        "quote": "For whom the chef grills. Thyme marches on. For whom the chef grills.",
        "attribution": "Metallica",
        "category": "Music",
        "title": "For Whom the Bell Tolls",
        "genre": "Rock",
        "tag1": "Metal",
        "tag2": "Lars Ulrich",
        "tag3": "1980s",
        "tag4": "Elektra Records",
        "tag5": "Cliff Burton",
        "tag6": "James Hetfield",
        "tag7": "Flemming Rasmussen"
    },
    {
        "id": 289,
        "quote": "And it's hard to host a cookout in the cold November rain.",
        "attribution": "Guns N' Roses",
        "category": "Music",
        "title": "November Rain",
        "genre": "Rock",
        "tag1": "Axl Rose",
        "tag2": "Geffen Records",
        "tag3": "1990s",
        "tag4": "Mike Clink",
        "tag5": "Steven Wilson",
        "tag6": "Duff McKagan",
        "tag7": "Slash",
        "tag8": "Izzy Stradlin",
        "tag9": "Gilby Clarke",
        "field16": "Matt Sorum",
        "field17": "Dizzy Reed"
    },
    {
        "id": 290,
        "quote": "Been spending most our lives, livin' in the grilla's paradise.",
        "attribution": "Coolio",
        "category": "Music",
        "title": "Gangsta's Paradise",
        "genre": "Hip-Hop",
        "tag1": "Rap",
        "tag2": "MCA Records",
        "tag3": "1990s",
        "tag4": "Doug Rasheed",
        "tag5": "Warner Bros Records",
        "tag6": "Tommy Boy Entertainment",
        "tag7": "Larry Sanders"
    },
    {
        "id": 291,
        "quote": "Biggie, Biggie, Biggie, can't you see? Sometimes your steaks just hypnotize me.",
        "attribution": "The Notorious B.I.G.",
        "category": "Music",
        "title": "Hypnotize",
        "genre": "Hip-Hop",
        "tag1": "Rap",
        "tag2": "Junior M.A.F.I.A.",
        "tag3": "1990s",
        "tag4": "Arista Records",
        "tag5": "Bad Boy Entertainment",
        "tag6": "Puff Daddy",
        "tag7": "P. Diddy",
        "tag8": "Papa Dot",
        "tag9": "Amen-Ra"
    },
    {
        "id": 292,
        "quote": "Caesar salad's alright, somebody check my steak.",
        "attribution": "Alice in Chains",
        "category": "Music",
        "title": "Check My Brain",
        "genre": "Rock",
        "tag1": "Metal",
        "tag2": "Virgin Records",
        "tag3": "2000s",
        "tag4": "EMI",
        "tag5": "Jerry Cantrell",
        "tag6": "Nick Raskulinecz"
    },
    {
        "id": 293,
        "quote": "Yeah you - smoked meat all night long",
        "attribution": "AC/DC",
        "category": "Music",
        "title": "You Shook Me All Night Long",
        "genre": "Rock",
        "tag1": "Atlantic Records",
        "tag2": "Angus Young",
        "tag3": "1980s",
        "tag4": "Malcolm Young",
        "tag5": "Brian Johnson",
        "tag6": "Robert \"Mutt\" Lange",
        "tag7": "Phil Rudd",
        "tag8": "Iron man",
        "tag9": "Columbia Records",
        "field16": "Cliff Williams"
    },
    {
        "id": 294,
        "quote": "This is how we grill it.",
        "attribution": "Montell Jordan",
        "category": "Music",
        "title": "This is How We Do It",
        "genre": "Hip-Hop",
        "tag1": "Def Jam Recordings",
        "tag2": "Ricky Walters",
        "tag3": "1990s",
        "tag4": "Slick Rick",
        "tag5": "Oji Pierce"
    },
    {
        "id": 295,
        "quote": "Ooh, yeah. Grillin' with the devil.",
        "attribution": "Van Halen",
        "category": "Music",
        "title": "Runnin with the Devil",
        "genre": "Rock",
        "tag1": "Eddie van Halen",
        "tag2": "Alex Van Halen",
        "tag3": "1970s",
        "tag4": "Warner Bros.",
        "tag5": "Michael Anthony",
        "tag6": "David Lee Roth",
        "tag7": "Ted Templeman"
    },
    {
        "id": 296,
        "quote": "I have become, comfortably fed.",
        "attribution": "Pink Floyd",
        "category": "Music",
        "title": "Comfortably Numb",
        "genre": "Rock",
        "tag1": "Pop",
        "tag2": "Harvest Records",
        "tag3": "1970s",
        "tag4": "Columbia Records",
        "tag5": "Roger Waters",
        "tag6": "David Gilmour",
        "tag7": "Bob Ezrin",
        "tag8": "James Guthrie",
        "tag9": "Richard Wright",
        "field16": "Nick Mason"
    },
    {
        "id": 297,
        "quote": "Run to the grills. Run for your chives.",
        "attribution": "Iron Maiden",
        "category": "Music",
        "title": "Run to the Hills",
        "genre": "Rock",
        "tag1": "Metal",
        "tag2": "EMI",
        "tag3": "1980s",
        "tag4": "Steve Harris",
        "tag5": "Martin Birch",
        "tag6": "Bruce Dickinson",
        "tag7": "Dave Murray",
        "tag8": "Clive Burr",
        "tag9": "Adrian Smith"
    },
    {
        "id": 298,
        "quote": "Come get hungry with me, if you curious to see, how it feels to be with a C.H.E.F.",
        "attribution": "50 Cent",
        "category": "Music",
        "title": "P.I.M.P.",
        "genre": "Rap, Hip-Hop",
        "tag1": "Hip-Hop",
        "tag2": "Shady Records",
        "tag3": "2000s",
        "tag4": "Aftermath Entertainment",
        "tag5": "Interscope Records",
        "tag6": "Universal Music Group",
        "tag7": "Denaun Porter"
    },
    {
        "id": 299,
        "quote": "So live and let fry.",
        "attribution": "Guns N' Roses",
        "category": "Music",
        "title": "Live and Let Die",
        "genre": "Rock",
        "tag2": "Axl Rose",
        "tag3": "1990s",
        "tag4": "Slash",
        "tag5": "Izzy Stradlin"
    },
    {
        "id": 300,
        "quote": "Have a steak on me.",
        "attribution": "AC/DC",
        "category": "Music",
        "title": "Have a Drink On Me",
        "genre": "Rock",
        "tag1": "Atlantic Records",
        "tag2": "Angus Young",
        "tag3": "1980s",
        "tag4": "Malcolm Young",
        "tag5": "Brian Johnson",
        "tag6": "Robert \"Mutt\" Lange",
        "tag7": "Phil Rudd",
        "tag8": "Iron man",
        "tag9": "Columbia Records",
        "field16": "Cliff Williams"
    },
    {
        "id": 301,
        "quote": "If you grill it, they will come.",
        "attribution": "Mysterious Voice In Cornfield",
        "category": "Movies",
        "title": "Field of Dreams",
        "genre": "Sports",
        "tag1": "Fantasy",
        "tag2": "Drama",
        "tag3": "1980s",
        "tag4": "Ray Liotta",
        "tag5": "Kevin Costner"
    },
    {
        "id": 302,
        "quote": "Might as well go for a smoker, no bloody meat, nobody cries.",
        "attribution": "Kim Mitchell",
        "category": "Music",
        "title": "Might As Well Go For a Soda",
        "genre": "Rock",
        "tag1": "Alert Records",
        "tag2": "Nick Blagona",
        "tag3": "1980s",
        "tag4": "Pye Dubois",
        "tag5": "Bronze Records"
    },
    {
        "id": 303,
        "quote": "You ain't nothin but a hot dog.",
        "attribution": "Elvis Presley",
        "category": "Music",
        "title": "Hound Dog",
        "genre": "Rock",
        "tag1": "Stephen Sholes",
        "tag2": "RCA Records",
        "tag3": "1950s",
        "tag4": "Jerry Leiber",
        "tag5": "Mike Stoller",
        "tag6": "Big Mama Thornton",
        "tag7": "Little Richard",
        "tag8": "Jerry Lee Lewis",
        "tag9": "Etta James",
        "field16": "Billy Craddock"
    },
    {
        "id": 304,
        "quote": "Give a little bit, give a bit of my steak to you.",
        "attribution": "Supertramp",
        "category": "Music",
        "title": "Give a Little Bit",
        "genre": "Pop",
        "tag1": "A&M Records",
        "tag2": "Roger Hodgson",
        "tag3": "1970s",
        "tag4": "Rick Davies",
        "tag5": "Rob Cavallo",
        "tag6": "The Goo Goo Dolls"
    },
    {
        "id": 305,
        "quote": "Grillman, come together with your tongs, feed me. I'm together with your plan, feed me.",
        "attribution": "Soundgarden",
        "category": "Music",
        "title": "Spoonman",
        "genre": "Rock",
        "tag1": "A&M Records",
        "tag2": "Chris Cornell",
        "tag3": "1990s",
        "tag4": "Grunge",
        "tag5": "Michael Beinhorn"
    },
    {
        "id": 306,
        "quote": "This one's grilled up for the one I love.",
        "attribution": "REM",
        "category": "Music",
        "title": "The One I Love",
        "genre": "Alt Rock, Alternative",
        "tag1": "I.R.S. Records",
        "tag2": "Bill Berry",
        "tag3": "1980s",
        "tag4": "Scott Litt",
        "tag5": "Michael Stipe",
        "tag6": "Peter Buck",
        "tag7": "Mike Mills"
    },
    {
        "id": 307,
        "quote": "I grill, therefore I am.",
        "attribution": "Rene Descartes",
        "category": "Philosophy",
        "title": "Discourse on the Method",
        "genre": "French",
        "tag1": "Literature"
    },
    {
        "id": 308,
        "quote": "I eat, therefore I am.",
        "attribution": "Rene Descartes",
        "category": "Philosophy",
        "title": "Discourse on the Method",
        "genre": "French",
        "tag1": "Literature"
    },
    {
        "id": 309,
        "quote": "I cook, therefore I am.",
        "attribution": "Rene Descartes",
        "category": "Philosophy",
        "title": "Discourse on the Method",
        "genre": "French",
        "tag1": "Literature"
    },
    {
        "id": 310,
        "quote": "I'm cookin' out so you better get this patty started.",
        "attribution": "P!nk",
        "category": "Music",
        "title": "Get the Party Started",
        "genre": "Pop",
        "tag1": "LaFace Records",
        "tag2": "Arista Records",
        "tag3": "2000s",
        "tag4": "Electro",
        "tag5": "Disco"
    },
    {
        "id": 311,
        "quote": "I can smell clearly now the sear is on.",
        "attribution": "Johnny Nash",
        "category": "Music",
        "title": "I Can See Clearly Now",
        "genre": "Pop",
        "tag1": "Soul",
        "tag2": "Epic Records",
        "tag3": "1970s",
        "tag4": "Jimmy Cliff",
        "tag5": "Columbia Records",
        "tag6": "Paul Henton"
    },
    {
        "id": 312,
        "quote": "People think I'm insane because I am grilling all the time.",
        "attribution": "Black Sabbath",
        "category": "Music",
        "title": "Paranoid",
        "genre": "Rock",
        "tag1": "Metal",
        "tag2": "Ozzy Osbourne",
        "tag3": "1970s",
        "tag4": "Vertigo Records",
        "tag5": "Tony Iommi",
        "tag6": "Geezer Butler",
        "tag7": "Bill Ward",
        "tag8": "Rodger Bain"
    },
    {
        "id": 313,
        "quote": "All day long I grill up things and everything does satisfy.",
        "attribution": "Black Sabbath",
        "category": "Music",
        "title": "Paranoid",
        "genre": "Rock",
        "tag1": "Metal",
        "tag2": "Ozzy Osbourne",
        "tag3": "1970s",
        "tag4": "Vertigo Records",
        "tag5": "Tony Iommi",
        "tag6": "Geezer Butler",
        "tag7": "Bill Ward",
        "tag8": "Rodger Bain"
    },
    {
        "id": 314,
        "quote": "I grill a boneless steak, the only food that I have ever known",
        "attribution": "Green Day",
        "category": "Music",
        "title": "Boulevard of Broken Dreams",
        "genre": "Rock, Alt-Rock, Alternative",
        "tag1": "Reprise Records",
        "tag2": "Warner Bros Records",
        "tag3": "2000s",
        "tag4": "Billie Joe Armstrong",
        "tag5": "Mike Dirnt",
        "tag6": "Tré Cool",
        "tag7": "Rob Cavallo"
    },
    {
        "id": 315,
        "quote": "I grill a boneless steak, and I sauce alone.",
        "attribution": "Green Day",
        "category": "Music",
        "title": "Boulevard of Broken Dreams",
        "genre": "Rock, Alt-Rock, Alternative",
        "tag1": "Reprise Records",
        "tag2": "Warner Bros Records",
        "tag3": "2000s",
        "tag4": "Billie Joe Armstrong",
        "tag5": "Mike Dirnt",
        "tag6": "Tré Cool",
        "tag7": "Rob Cavallo"
    },
    {
        "id": 316,
        "quote": "I gotta know if the steak rub is gonna be savoury.",
        "attribution": "The Eagles",
        "category": "Music",
        "title": "Take It Easy",
        "genre": "Pop, Rock",
        "tag1": "Country Rock",
        "tag2": "Asylum Records",
        "tag3": "1970s",
        "tag4": "Jackson Browne",
        "tag5": "Glenn Frey",
        "tag6": "Glyn Johns",
        "tag7": "Bernie Leadon",
        "tag8": "Don Henley",
        "tag9": "Randy Meisner"
    },
    {
        "id": 317,
        "quote": "The steak must go on.",
        "attribution": "Queen",
        "category": "Music",
        "title": "The Show Must Go On",
        "genre": "Rock, Pop",
        "tag1": "Parlophone Records",
        "tag2": "Hollywood Records",
        "tag3": "1990s",
        "tag4": "Brian May",
        "tag5": "David Richards",
        "tag6": "Freddie Mercury",
        "tag7": "Roger Taylor",
        "tag8": "John Deacon"
    },
    {
        "id": 318,
        "quote": "The steak must go on. I'll grill it with a grin.",
        "attribution": "Queen",
        "category": "Music",
        "title": "The Show Must Go On",
        "genre": "Rock, Pop",
        "tag1": "Parlophone Records",
        "tag2": "Hollywood Records",
        "tag3": "1990s",
        "tag4": "Brian May",
        "tag5": "David Richards",
        "tag6": "Freddie Mercury",
        "tag7": "Roger Taylor",
        "tag8": "John Deacon"
    },
    {
        "id": 319,
        "quote": "Every time I look in the smoker, all these grill lines on my steak getting darker.",
        "attribution": "Aerosmith",
        "category": "Music",
        "title": "Dream On",
        "genre": "Rock",
        "tag1": "Columbia Records",
        "tag2": "Adrian Barber",
        "tag3": "1970s",
        "tag4": "Steven Tyler",
        "tag5": "Brad Whitford",
        "tag6": "Tom Hamilton",
        "tag7": "Joe Perry",
        "tag8": "Joey Kramer"
    },
    {
        "id": 320,
        "quote": "Tweeter and the Monkey Man were hard up for cash, they stayed up all night grilling burgers and bass.",
        "attribution": "Headstones",
        "category": "Music",
        "title": "Tweeter and the Monkeyman",
        "genre": "Rock",
        "tag1": "MCA Records",
        "tag3": "1990s"
    },
    {
        "id": 321,
        "quote": "Tweeter and the Monkey Man were hard up for cash, they stayed up all night grilling burgers and bass.",
        "attribution": "Travelling Wilburys",
        "category": "Music",
        "title": "Tweeter and the Monkeyman",
        "genre": "Pop, Rock",
        "tag1": "Nelson Wilbury",
        "tag2": "George Harrison",
        "tag3": "1980s",
        "tag4": "Bob Dylan",
        "tag5": "Tom Petty",
        "tag6": "Jeff Lynne",
        "tag7": "Otis Wilbury"
    },
    {
        "id": 322,
        "quote": "You can baste, you can slice, having the time of your life, ooo, see that girl, watch that meat, she is the Grilling Queen",
        "attribution": "ABBA",
        "category": "Music",
        "title": "Dancing Queen",
        "genre": "Disco, Pop",
        "tag3": "1970s"
    },
    {
        "id": 323,
        "quote": "Baby, I'm just gonna plate, plate, plate, plate, plate, I plate it up, plate it up",
        "attribution": "Taylor Swift",
        "category": "Music",
        "title": "Shake It Off",
        "genre": "Pop",
        "tag3": "2010s"
    },
    {
        "id": 324,
        "quote": "In your coals, the light, the heat, in your coals, I grill to eat",
        "attribution": "Peter Gabriel",
        "category": "Music",
        "title": "In Your Eyes",
        "genre": "Rock, Alt-Rock, Alternative",
        "tag3": "1990s"
    }
]