import * as React from 'react'
import { MDBTooltip, MDBBtn } from 'mdb-react-ui-kit'
import './ColorButtons.scss'
import Icon from '../components/Icon'

const ColorButtons = (props: any) => {
    const colors = ['black', 'blue']
    const colorNameMap: any = {
        black: 'Raven Black',
        blue: 'Royal Blue',
    }

    const colorButtons = colors.map((colorFromList: string) => (
        <MDBTooltip key={colorFromList} tag="span" title={colorNameMap[colorFromList]} className="pb-2">
            <MDBBtn
                type="button"
                className={`mx-2 color-button ${colorFromList} ${props.color === colorFromList && 'heavy-shadow'}`}
                name={colorFromList}
                size="sm"
                floating
                onClick={props.handleChangeColor}
            >
                {props.color === colorFromList && <Icon icon="check" className="check-icon" />}
            </MDBBtn>
        </MDBTooltip>
    ))

    return (
        <div className="color-buttons">
            <label>Apron color</label> {colorButtons}
        </div>
    )
}

export default ColorButtons
