import * as React from 'react'
import { Textfit } from 'react-textfit'
import './ItemPreview.scss'

const CustomPhoto = (props: any) => {
    if (!props.photo) {
        return null
    }

    return (
        <div
            className="chosen-image mx-auto mt-4 "
            style={{
                backgroundImage: `url(${props.photo})`,
                width: `${(parseInt(props.photoSize) / 100) * 300}px`,
                height: `${(parseInt(props.photoSize) / 100) * 200}px`,
            }}
        ></div>
    )
}

const ItemPreview = (props: any) => {
    const [forceHeight, setForceHeight] = React.useState('')

    function shouldShowAttributionOrTitle() {
        let result = (props.showAttribution && props.chosenQuoteObject.attribution) || (props.showTitle && props.chosenQuoteObject.title)

        if (props.showCreateOwnQuote) {
            result = props.showAttribution && props.customAttribution?.length > 0
        }

        return result
    }

    return (
        <div
            className={`item-preview pt-4 ${props.index ? 'multiple' : ''}`}
            ref={props.elementToTakeImageOf ? props.elementToTakeImageOf : (element) => props.addRef?.(props.index, element)}
        >
            <div className="d-flex justify-content-around">
                {props.companyLogo && <img src={props.companyLogo} className="site-logo me-auto" alt="logo" />}
                <img src={props.chosenLogoImage} className="site-logo ms-auto" alt="logo" />
            </div>

            <CustomPhoto {...props} above={true} />

            <div
                className={`${props.photo ? 'mt-2' : ''} mb-2 px-2 chosen-text-preview apron-${props.color}`}
                style={{
                    fontFamily: props.fontFamily,
                }}
            >
                <Textfit
                    mode="multi"
                    className={`${props.fontFamily === 'Aftika' ? 'fw-bold' : ''} quote d-flex justify-content-center mx-4`}
                    style={{
                        height: `${forceHeight || props.quoteHeightString}`,
                    }}
                    onReady={() => setForceHeight('auto')}
                >
                    {props.quote ? props.quote : 'Your quote will appear here'}
                </Textfit>

                <div className={`fw-normal text-size-${props.attributionSize} authors pe-1`}>
                    {shouldShowAttributionOrTitle() && (
                        <div className="text-end fst-italic">
                            &ndash;{' '}
                            {props.showAttribution && (props.customAttribution || props.chosenQuoteObject.attribution) && (
                                <span className={`${!props.customAttribution && 'strikethrough'}`}>
                                    {props.showCreateOwnQuote ? props.customAttribution : props.chosenQuoteObject.attribution}
                                </span>
                            )}
                            {props.showTitle && props.chosenQuoteObject.title && (
                                <span className={`${!props.customAttribution && 'strikethrough'}`}>
                                    {props.showAttribution && props.chosenQuoteObject.attribution && <span>,</span>} {props.chosenQuoteObject.title}
                                </span>
                            )}
                        </div>
                    )}
                    {props.name && <div className={`text-end fst-italic text-size-${props.recipientNameSize}`}>&ndash; {props.name}</div>}
                </div>
            </div>
        </div>
    )
}

export default ItemPreview
