import utility from './utility'

const apiManager = {
    async fetchData(): Promise<any> {
        const searchParams = new URLSearchParams(window.location.search)
        const allUsers = searchParams.get('allUsers') === '1'

        const postBody = {
            query: `
                    query($allUsers: Boolean!) {
                        user {
                            id
                            name
                            cartItemCount
                        }
                        likes {
                            objectId
                            likeCount
                            userLikeType
                        }
                        quotes(allUsers: $allUsers) {
                            id
                            userId
                            text
                        }
                    }
                `,
            variables: {
                allUsers: allUsers,
            },
        }

        let response = await apiManager.send(postBody)

        return {
            user: response?.user,
            likes: utility.arrayToDictionary(response?.likes, 'objectId'),
            customQuotes: utility.arrayToDictionary(response?.quotes, 'id'),
        }
    },

    async like(type: number, objectId: number): Promise<any> {
        const postBody = {
            query: `
                    mutation($type: Int!, $objectId: Int!) {
                        like(type: $type, objectId: $objectId) {
                            id
                            userId
                            type
                        }
                    }
                `,
            variables: {
                type: type,
                objectId: objectId,
            },
        }

        return await apiManager.send(postBody)
    },

    async quote(text: string): Promise<any> {
        const postBody = {
            query: `
                    mutation($text: String!) {
                        quote(text: $text) {
                            id
                            userId
                        }
                    }
                `,
            variables: {
                text: text,
            },
        }

        return await apiManager.send(postBody)
    },

    async deleteQuote(quoteObject: any): Promise<any> {
        //no need to send text if we have the id
        const postBody = {
            query: `
                    mutation($id: Int!, $text: String!) {
                       deleteQuote(id: $id, text: $text)
                    }
                `,
            variables: {
                id: quoteObject?.id > 0 ? quoteObject?.id : 0,
                text: quoteObject?.id > 0 ? '' : quoteObject?.text,
            },
        }

        return await apiManager.send(postBody)
    },

    async signUp(email: string, name: string): Promise<any> {
        const postBody = {
            query: `
                    mutation($email: String!, $name: String!) {
                       signUp(email: $email, name: $name) {
                           cookie
                            user {
                                id
                                name
                                cartItemCount
                            }
                       }
                    }
                `,
            variables: {
                email,
                name,
            },
        }

        return await apiManager.send(postBody)
    },

    async send(postBody: any) {
        const response = await utility.request(process.env.GATSBY_GRAPHQL_URL, postBody)
        return utility.get(response, 'data')
    },
}

export default apiManager
