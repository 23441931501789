export const categoryList = [
    { value: '.all.', label: 'All' },
    { value: 'Movies', label: 'Movies' },
    { value: 'Music', label: 'Music' },
    { value: 'Famous', label: 'Famous' },
    { value: 'Historical', label: 'History' },
    { value: 'Philosophy', label: 'Philosophy' },
    { value: 'Sports', label: 'Sports' },
    { value: 'TV', label: 'TV' },
]
