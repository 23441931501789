import * as React from 'react'
import { MDBTooltip, MDBBtn } from 'mdb-react-ui-kit'
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit'
import apiManager from '../utility/apiManager'
import Icon from '../components/Icon'
import utility from '../utility/utility'
import './QuotesModal.scss'

const QuotesModal = (props: any) => {
    const deleteQuote = async (quoteObject: any) => {
        const response = await apiManager.deleteQuote(quoteObject)

        if (!response?.quote?.id) {
            utility.log('quote error')
        }
    }

    const handleClickRemoveQuoteOrLike = (quoteObject: any, isCustom: boolean) => {
        if (isCustom) {
            deleteQuote(quoteObject)
        }

        props.handleClickRemoveQuoteOrLike(quoteObject, isCustom)
    }

    const lineInModal = (quote: any, index, array) => {
        let objectId = parseInt(quote[0])
        quote = quote[1]

        if (!quote) {
            return
        }

        let isCustom = quote?.likeCount === undefined
        let text = quote.text

        if (!isCustom) {
            const matchingQuote = props.quotes.find((element) => element?.id === objectId)
            text = matchingQuote?.quote
        }

        //maybe a quote that no longer exists
        if (!text) {
            return
        }

        return (
            <div className="quote ms-3" key={isCustom ? index : objectId}>
                {isCustom && quote.userId !== props.user?.id && <span>(other user) </span>}
                <span onClick={() => props.handleClickUseQuote(quote, isCustom)}>{text}</span>
                <MDBTooltip tag="span" title="Use this quote">
                    <MDBBtn
                        tag="a"
                        color="none"
                        className="action-button ms-3 medium-color"
                        onClick={() => props.handleClickUseQuote(quote, isCustom)}
                    >
                        <Icon icon="arrow-right" />
                    </MDBBtn>
                </MDBTooltip>
                <MDBTooltip tag="span" title="Remove from this list">
                    <MDBBtn
                        tag="a"
                        color="none"
                        className="action-button ms-3 medium-color"
                        onClick={() => handleClickRemoveQuoteOrLike(quote, isCustom)}
                    >
                        <Icon icon="trash" />
                    </MDBBtn>
                </MDBTooltip>
                {index < array.length - 1 && <hr />}
            </div>
        )
    }
    return (
        <MDBModal show={props.basicModal} getOpenState={(e: any) => props.setBasicModal(e)} tabIndex="-1">
            <MDBModalDialog scrollable centered size="lg">
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Quotes</MDBModalTitle>
                        <MDBBtn className="btn-close" color="none" onClick={props.onCloseBasicModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody className="quotes-modal">
                        <div className="fs-6 mb-3 fw-bold">Created</div>
                        {props.customQuotes && Object.entries(props.customQuotes).map(lineInModal)}
                        {utility.isEmpty(props.customQuotes) && (
                            <div className="no-items ms-3 text-muted">No custom quotes yet</div>
                        )}

                        <div className="fs-6 mt-4 mb-3 fw-bold">Liked</div>
                        {props.likes && likesArray().map(lineInModal)}
                        {utility.isEmpty(likesArray()) && (
                            <div className="no-items ms-3 text-muted">No liked quotes yet</div>
                        )}
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={props.onCloseBasicModal}>
                            Close
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )

    function likesArray() {
        const array = Object.entries(props.likes)
        return array.filter((like: any) => like[1]?.userLikeType === 1)
    }
}

export default QuotesModal
