import fetch from 'cross-fetch'

const utility = {
    debug: process.env.GATSBY_ENV === 'development',

    capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    },

    async request(url: any, postBody: any = null, headers: any = null): Promise<any> {
        let result = {}

        try {
            const response = await utility.requestInternal(
                url,
                postBody ? JSON.stringify(postBody) : null,
                true,
                headers
            )
            result = await response?.json()
        } catch (error: any) {
            utility.log(error)
        }

        return result
    },

    async requestPlainText(url: string, postBody: any = null): Promise<string> {
        let result = ''

        try {
            const response = await utility.requestInternal(url, postBody)
            result = await response?.text()
        } catch (error: any) {
            utility.log(error)
        }

        return result
    },

    async requestInternal(
        url: string,
        postBody: any = null,
        postBodyIsJson = false,
        headers: any = null
    ): Promise<Response> {
        if (!headers && postBodyIsJson) {
            headers = {
                'Content-Type': 'application/json',
            }
        }

        return await fetch(url, {
            headers,
            method: postBody ? 'POST' : 'GET',
            body: postBody ? postBody : null,
        })
    },

    log(string: string) {
        if (!utility.debug) {
            return
        }

        console.log(string)
    },

    get(object: any, key: any, defaultValue: any = '') {
        let result = defaultValue

        if (Array.isArray(object) && Number.isInteger(key)) {
            if (key >= object.length) {
                return result
            }
        } else {
            if (!object || !key) {
                return result
            }

            if (!(key in object)) {
                return result
            }
        }

        return object[key]
    },

    getNested(object, keys, defaultValue = '' as any) {
        let result = defaultValue

        if (!object || !keys) {
            return result
        }

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]

            if (Array.isArray(object) && Number.isInteger(key)) {
                if (key >= object.length) {
                    break
                }
            } else if (typeof object === 'string' || typeof object === 'number') {
                break
            } else {
                if (!(key in object)) {
                    break
                }
            }

            if (i === keys.length - 1) {
                result = object[key]
                break
            }

            object = object[key]

            if (!object) {
                break
            }
        }

        return result
    },

    setNested(object, path, value) {
        let lastKeyIndex = path.length - 1

        for (let i = 0; i < lastKeyIndex; ++i) {
            let key = path[i]

            if (!(key in object)) {
                object[key] = {}
            }

            object = object[key]
        }

        object[path[lastKeyIndex]] = value
    },

    isEmpty(object: any) {
        if (!object) {
            return true
        }

        if (Array.isArray(object) && !object.length) {
            return true
        }

        return Object.keys(object).length === 0 && object.constructor === Object
    },

    async sleep(milliseconds: number) {
        return new Promise((resolve) => setTimeout(resolve, milliseconds))
    },

    shuffle(array: Array<any>) {
        array.sort(() => Math.random() - 0.5)
    },

    sort(array: Array<any>, key: string) {
        array.sort(utility.dynamicSort(key))
    },

    dynamicSort(property) {
        let sortOrder = -1

        if (property[0] === '-') {
            sortOrder = -1
            property = property.substr(1)
        }

        return function (a, b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            let result = a[property] > b[property] ? -1 : a[property] < b[property] ? 1 : 0
            return result * sortOrder
        }
    },

    getCookie(cname: string) {
        let name = cname + '='
        let decodedCookie = decodeURIComponent(document.cookie)
        let ca = decodedCookie.split(';')

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }

        return ''
    },

    setCookie(name: string, value: string, hours = 365 * 24) {
        let date = new Date()

        date.setTime(date.getTime() + hours * 60 * 60 * 1000)

        let expires = 'expires=' + date.toUTCString()
        document.cookie = name + '=' + value + ';' + expires + ';path=/'
    },

    arrayToDictionary(array: Array<any>, columnName: string) {
        const result = {}

        if (!array) {
            return result
        }

        for (let item of array) {
            const id = utility.get(item, columnName)

            if (!id) {
                continue
            }

            result[id] = item
        }

        return result
    },

    randomInteger(min: number, max: number) {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min + 1)) + min
    },

    stringBetween(s: string, prefix: string, suffix: string, strict = false) {
        if (!s) {
            return ''
        }

        let result = s

        if (prefix) {
            const prefixIndex = s.indexOf(prefix)

            if (prefixIndex >= 0) {
                result = s.substring(prefixIndex + prefix.length)
            } else if (strict) {
                return ''
            }
        }

        if (suffix) {
            const suffixIndex = result.indexOf(suffix)

            if (suffixIndex >= 0) {
                result = result.substring(0, suffixIndex)
            } else if (strict) {
                return ''
            }
        }

        return result
    },

    printableDate(dayOnly = false) {
        let result = new Date().toISOString().replace(/:/g, '-')

        if (dayOnly) {
            return utility.stringBetween(result, '', 'T')
        }

        result = result.replace('T', '-')
        return utility.stringBetween(result, '', '.')
    },

    makeId(string: string, maximumLength = 100) {
        if (!string) {
            return ''
        }

        let result = string.replace(/[^A-Za-z0-9\s!?]/g, '')
        result = result.trim().substr(0, maximumLength)
        return result.trim()
    },

    getLines(string: string) {
        if (!string) {
            return []
        }

        const result = []
        let lines = string?.split(/\r?\n/)

        if (!lines) {
            lines = []
        }

        for (let line of lines) {
            if (!line) {
                continue
            }

            line = line.trim()

            if (!line) {
                continue
            }

            result.push(line)
        }

        return result
    },

    clipTo(string: string, length: number) {
        if (string.length <= length) {
            return string
        }

        return string.substring(0, length) + '...'
    },

    firstName(string: string) {
        return utility.stringBetween(string, '', ' ').trim()
    },

    getJsonFromStorage(key: string) {
        if (typeof window === 'undefined') {
            //prevent gatsby build error saying "localStorage is not defined"
            return {}
        }

        const value = localStorage.getItem(key)

        if (!value) {
            return {}
        }

        let result = {}

        try {
            result = JSON.parse(value)
        } catch (error: any) {
            utility.log(error)
        }

        return result
    },
}

export default utility
