import { navigate } from 'gatsby'
import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow, MDBTabsContent, MDBTabsPane, MDBTooltip } from 'mdb-react-ui-kit'
import * as React from 'react'
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import ColorButtons from '../components/ColorButtons'
import Header from '../components/Header'
import HeadTags from '../components/HeadTags'
import Icon from '../components/Icon'
import OrderStep2 from '../components/OrderStep2'
import Search from '../components/Search'
import apiManager from '../utility/apiManager'
import utility from '../utility/utility'
import './global.scss'
import './order.scss'

const customizeState: any = utility.getJsonFromStorage('customizeState')

const Order = (props: any) => {
    const defaultForSelect = { value: '.all.', label: 'All' }
    const [maximumStepIndex, setMaximumStepIndex] = useState(0)
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [name, setName] = useState('')
    const [names, setNames] = useState('')
    const [namesList, setNamesList] = useState(customizeState?.namesList || [])
    const [color, setColor] = useState(customizeState?.color || 'black')
    const [categories, setCategories] = useState(defaultForSelect) as Array<any>
    const [genres, setGenres] = useState(defaultForSelect) as Array<any>
    const [tags, setTags] = useState(defaultForSelect) as Array<any>
    const [user, setUser] = useState({}) as any
    const [likes, setLikes] = useState({}) as any
    const [customQuotes, setCustomQuotes] = useState({}) as any
    const [cartItemCount, setCartItemCount] = useState(0)
    const [multipleNames, setMultipleNames] = useState(customizeState?.names?.length > 0)

    const mainHeading: any = useRef()

    const getStepIndex = () => {
        let result = 1

        const step = utility.stringBetween(props?.location?.search, 'step=', '&')
        const isNumber = /^\d+$/.test(step)

        if (isNumber) {
            result = parseInt(step)
        }

        return result - 1
    }

    const changeStep = useCallback(
        (stepIndex: number) => {
            if (stepIndex === currentStepIndex) {
                return
            }

            if (stepIndex >= maximumStepIndex) {
                setMaximumStepIndex(stepIndex)
            }

            setCurrentStepIndex(stepIndex)

            if (stepIndex) {
                const searchParams = new URLSearchParams(window?.location?.search)
                const cartItemId = searchParams.get('id')
                const idPart = cartItemId ? `&id=${cartItemId}` : ''

                navigate(`/order/?step=${stepIndex + 1}${idPart}`)
            } else {
                navigate('/order/')
            }
        },
        [currentStepIndex, maximumStepIndex]
    )

    const handleSubmit = (event: any) => {
        if (currentStepIndex === 0) {
            event.preventDefault()

            setName(event?.target?.name?.value || '')

            setNames(event?.target?.names?.value || '')
            const list = utility.getLines(event?.target?.names?.value)
            setNamesList(list)

            if (!utility.isEmpty(list)) {
                setName(list[0])
            }

            setColor(event?.target?.color?.value)
            changeStep(1)
        } else if (currentStepIndex === 1) {
            window.location.href = '/checkout'
        }
    }

    const handleChangeName = (event: SyntheticEvent) => {
        const target = event.target as HTMLInputElement
        setName(target.value)
    }

    const handleChangeNames = (event: SyntheticEvent) => {
        const target = event.target as HTMLTextAreaElement
        setNames(target.value)
    }

    const handleChangeColor = (event: any) => {
        const target = event.target as HTMLInputElement

        if (target?.name) {
            setColor(target.name)
        }
    }

    const resetSavedCustomizeState = () => {
        //because if user changes dropdown on step 1, it no longer makes sense to use saved state. because categories, etc. may not match.
        localStorage.setItem('customizeState', '{}')
    }

    const handleChangeTags = (newTags: any[]) => {
        setTags(newTags)
        resetSavedCustomizeState()
    }

    const handleChangeCategories = (newCategories: any[], useDefaults = false) => {
        if (useDefaults) {
            setCategories(defaultForSelect)
        } else {
            setCategories(newCategories)
        }

        setTags(defaultForSelect)
        setGenres(defaultForSelect)
        resetSavedCustomizeState()
    }

    const handleChangeGenres = (newGenres: any[], useDefaults = false) => {
        if (useDefaults) {
            setGenres(defaultForSelect)
        } else {
            setGenres(newGenres)
        }

        setTags(defaultForSelect)
        resetSavedCustomizeState()
    }

    const handleChangeLikes = (destinationType: number, id: number, isUndo: boolean) => {
        const result = {}

        const newLikes = { ...likes }

        const currentCount = utility.getNested(newLikes, [id, 'likeCount'], 0)

        let offset = 1

        if (isUndo) {
            //we want it to go down
            offset = -1
        }

        utility.setNested(newLikes, [id, 'objectId'], id)
        utility.setNested(newLikes, [id, 'userLikeType'], destinationType)
        //increase like count
        utility.setNested(newLikes, [id, 'likeCount'], currentCount + offset)

        result['likeCount'] = currentCount + offset

        setLikes(newLikes)

        return result
    }

    const addCustomQuote = (text: string) => {
        const newCustomQuotes = { ...customQuotes }
        const randomId = -1 * utility.randomInteger(1000, 1000 * 1000)

        newCustomQuotes[randomId] = {
            id: randomId,
            userId: user?.id,
            text: text,
        }

        setCustomQuotes(newCustomQuotes)
    }

    const handleClickRemoveQuoteOrLike = (id: number, isCustom: boolean) => {
        if (isCustom) {
            if (customQuotes[id]) {
                const newCustomQuotes = { ...customQuotes }
                delete newCustomQuotes[id]
                setCustomQuotes(newCustomQuotes)
            }
        } else {
            const newLikes = { ...likes }
            utility.setNested(newLikes, [id, 'userLikeType'], 0)
            setLikes(newLikes)
        }
    }

    useEffect(() => {
        setName(customizeState?.name || '')
        setNames(customizeState?.names || '')

        // need this nested function to avoid a typescript error
        async function fetchData() {
            const response = await apiManager.fetchData()

            if (response?.user) {
                setUser(response?.user)
            }

            if (response?.likes) {
                setLikes(response?.likes)
            }

            if (response?.customQuotes) {
                setCustomQuotes(response?.customQuotes)
            }

            setCartItemCount(utility.getNested(response, ['user', 'cartItemCount'], 0))
        }

        fetchData()
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [])

    useEffect(() => {
        const newStepIndex = getStepIndex()

        if (newStepIndex !== currentStepIndex) {
            setCurrentStepIndex(newStepIndex)

            if (newStepIndex >= maximumStepIndex) {
                setMaximumStepIndex(newStepIndex)
            }
        }
    }, [props.location])

    const onLogin = (user: any) => {
        if (!utility.isEmpty(user)) {
            setUser(user)
        }
    }

    const handleAddedToCart = () => {
        setCartItemCount(cartItemCount + 1)
    }

    const handleClickAddNames = () => {
        if (!multipleNames) {
            setName('')
            setNames(name)
        } else {
            setNames('')
            setNamesList([])
        }

        setMultipleNames(!multipleNames)
    }

    const nameForButton = () => {
        if (!multipleNames && !name) {
            return ''
        }

        if (multipleNames && !names) {
            return ''
        }

        const nameToUse = multipleNames ? 'your group' : name

        return ` for ${nameToUse}`
    }

    const titleForStep = () => {
        if (currentStepIndex === 1) {
            return 'How It Looks'
        }

        return 'Who is this Apron for?'
    }

    useEffect(() => {
        if (props?.location?.search?.includes('group=1')) {
            setMultipleNames(true)
        }
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [])

    return (
        <>
            <HeadTags titlePrefix={'Customize'} />
            <Header username={user.name} cartItemCount={cartItemCount} />

            <MDBContainer ref={mainHeading} fluid className="customize-order mt-3">
                <MDBRow>
                    <MDBCol size="md-7" className="order-column">
                        <h1 className="text-start medium-color fw-bold">{titleForStep()}</h1>
                    </MDBCol>
                    <MDBCol size="md-5" className="d-none d-lg-block text-end number-circle">
                        <MDBTooltip tag="span" title="Step 1: Start" className="pb-3">
                            <div className={`circle mx-2 ${currentStepIndex === 0 ? 'active' : 'inactive'}`}>1</div>
                        </MDBTooltip>
                        <span className="separator inactive">...</span>
                        <MDBTooltip tag="span" title="Step 2: Customize" className="pb-3">
                            <div className={`circle mx-2 ${currentStepIndex === 1 ? 'active' : 'inactive'}`}>2</div>
                        </MDBTooltip>
                        <span className="separator inactive">...</span>
                        <MDBTooltip tag="span" title="Step 3: Checkout" className="pb-3">
                            <div className={`circle mx-2 ${currentStepIndex === 2 ? 'active' : 'inactive'}`}>3</div>
                        </MDBTooltip>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol className="order-column">
                        <MDBTabsContent>
                            <MDBTabsPane show={currentStepIndex === 0}>
                                <div className="text-start">
                                    <p className="mt-2 fs-5 fw-bold medium-color">They're gonna love their Legendary Apron!</p>

                                    <form onSubmit={handleSubmit} className="mt-4">
                                        {!multipleNames && (
                                            <div>
                                                <MDBTooltip tag="span" title="Mom? Dad? Grandpa? Add their name or a nickname!" className="pb-3">
                                                    <MDBInput
                                                        name="name"
                                                        label="Recipient's name"
                                                        wrapperClass="recipient-name-input"
                                                        type="text"
                                                        size="lg"
                                                        value={name}
                                                        onChange={handleChangeName}
                                                    />
                                                </MDBTooltip>

                                                <div>
                                                    <MDBBtn color="link" type="button" className="px-1 medium-color text-start" rippleColor="dark" onClick={handleClickAddNames}>
                                                        <Icon icon="plus" />
                                                        Add more names. Order for your whole company, family or any other group!
                                                    </MDBBtn>
                                                </div>
                                            </div>
                                        )}

                                        {multipleNames && (
                                            <div id="names-container">
                                                <MDBTooltip tag="span" title="Add a list of names here. Each person can have a unique quote on their apron!" className="pb-3">
                                                    <MDBInput
                                                        id="names"
                                                        label="Recipients' names (one per line)"
                                                        name="names"
                                                        textarea
                                                        rows={4}
                                                        value={names}
                                                        onChange={handleChangeNames}
                                                        required
                                                    />
                                                </MDBTooltip>

                                                <div>
                                                    <MDBBtn color="link" type="button" className="px-1 medium-color text-start" rippleColor="dark" onClick={handleClickAddNames}>
                                                        <Icon icon="minus" />
                                                        Enter one name only
                                                    </MDBBtn>
                                                </div>
                                            </div>
                                        )}

                                        <input type="hidden" name="color" value={color} />

                                        <div className="my-2">
                                            <ColorButtons color={color} handleChangeColor={handleChangeColor} />
                                        </div>

                                        <MDBBtn className="mt-3 submit-button" color="danger" size="lg" type="submit">
                                            <Icon icon="arrow-right" />
                                            Go ahead - see fun quotes{nameForButton()}!
                                        </MDBBtn>

                                        <p className="mt-3 fs-4 fw-bold medium-color">Or</p>

                                        <p className="my-3 fs-5 fw-bold medium-color">Filters</p>

                                        <div className="mt-3">
                                            <Search
                                                categories={categories}
                                                genres={genres}
                                                tags={tags}
                                                handleChangeCategories={handleChangeCategories}
                                                handleChangeGenres={handleChangeGenres}
                                                handleChangeTags={handleChangeTags}
                                            />
                                        </div>

                                        <MDBBtn className="mt-3 submit-button" color="danger" size="lg" type="submit">
                                            <Icon icon="arrow-right" />
                                            Continue
                                        </MDBBtn>
                                    </form>
                                </div>
                            </MDBTabsPane>
                            <MDBTabsPane show={currentStepIndex === 1}>
                                {maximumStepIndex >= 1 && (
                                    <OrderStep2
                                        user={user}
                                        likes={likes}
                                        customQuotes={customQuotes}
                                        name={name}
                                        names={names}
                                        namesList={namesList}
                                        color={color}
                                        categories={categories}
                                        genres={genres}
                                        tags={tags}
                                        mainHeading={mainHeading}
                                        handleSubmit={handleSubmit}
                                        handleChangeName={handleChangeName}
                                        handleChangeColor={handleChangeColor}
                                        handleChangeCategories={handleChangeCategories}
                                        handleChangeGenres={handleChangeGenres}
                                        handleChangeTags={handleChangeTags}
                                        handleAddedToCart={handleAddedToCart}
                                        handleChangeLikes={handleChangeLikes}
                                        handleClickRemoveQuoteOrLike={handleClickRemoveQuoteOrLike}
                                        addCustomQuote={addCustomQuote}
                                        onLogin={onLogin}
                                        changeName={(name: string) => setName(name)}
                                        customizeState={customizeState}
                                    />
                                )}
                            </MDBTabsPane>
                        </MDBTabsContent>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    )
}

export default Order
