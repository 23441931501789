const projectUtility = {
    fonts: ['Barlock', 'Bleeding Cowboys', 'Carnevalee Freakshow', 'Delicious Handrawn', 'Fat Frank', 'Impact', 'Sevillana', 'Western Bang Bang'],

    quoteMatches(thingsToCheck: any) {
        for (let thingToCheck of thingsToCheck) {
            //if the categories, etc. are blank, we don't need to consider it
            const isAll = thingToCheck.mustMatch?.value === '.all.'

            if (isAll) {
                continue
            }

            const matches = projectUtility.arraysHaveMatch(thingToCheck.thisQuoteValues, [thingToCheck.mustMatch], 'value')

            //no point checking this quote more
            if (!matches) {
                return false
            }
        }

        return true
    },

    arraysHaveMatch(array1: any[], array2: any[], key: string) {
        for (let thingToCheck of array2) {
            if (array1.includes(thingToCheck[key])) {
                return true
            }
        }

        return false
    },
}

export default projectUtility
