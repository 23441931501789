import * as React from 'react'
import { MDBBtn, MDBTooltip } from 'mdb-react-ui-kit'
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit'
import Icon from '../components/Icon'
import utility from '../utility/utility'
import { navigate } from 'gatsby'

const NamesSelect = (props: any) => {
    if (utility.isEmpty(props.namesList)) {
        return null
    }

    return (
        <MDBContainer className="ps-2 justify-content-start">
            <MDBRow className="align-items-end">
                <MDBCol size={props.showCount ? '11' : 'auto'} className="ps-1 pe-2">
                    <MDBTooltip tag="span" title="You can choose a unique quote for each person" className="pb-3">
                        <label className="d-inline-block mt-2 mb-1">Show which apron?</label>
                    </MDBTooltip>
                    <select
                        className="form-select recipient-name-select"
                        value={props.name}
                        onChange={props.handleChangeName}
                    >
                        {props.namesList.length > 0 &&
                            props.namesList.map((name: string, index: number) => (
                                <option key={index} value={name}>
                                    {name}: {getQuoteText(name)}
                                </option>
                            ))}
                    </select>
                </MDBCol>
                <MDBCol size="1" className="px-0">
                    <MDBTooltip tag="span" title="Edit the list of names">
                        <MDBBtn tag="a" color="none" className="m-1 pb-1 light-color" onClick={() => navigate(-1)}>
                            <Icon fas icon="pen" />
                        </MDBBtn>
                    </MDBTooltip>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )

    function getQuoteText(name) {
        let result = utility.getNested(props.quotesByName, [name, 'quote'], 'No quote yet')
        return utility.clipTo(result, 30)
    }
}

export default NamesSelect
