import * as React from 'react'
import { useEffect, useRef } from 'react'
import { MDBBtn } from 'mdb-react-ui-kit'
import {
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit'

const Confirm = (props: any) => {
    const okButtonRef: any = useRef()

    const onOk = () => {
        props.onCancel()
        props.onOk()
    }

    useEffect(() => {
        // when modal opens
        if (props.modal) {
            okButtonRef?.current?.focus()
        }

        // when modal changes
    }, [props.modal])

    return (
        <MDBModal show={props.modal} getOpenState={(e: any) => props.setModal(e)} tabIndex="-1">
            <MDBModalDialog scrollable centered size="md">
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>{props.title}</MDBModalTitle>
                        <MDBBtn className="btn-close" color="none" onClick={props.onCancel}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <p>{props.message}</p>
                        {props.message2 && <p>{props.message2}</p>}
                    </MDBModalBody>
                    <MDBModalFooter>
                        {props.cancelText !== null && (
                            <MDBBtn color="light" onClick={props.onCancel}>
                                Cancel
                            </MDBBtn>
                        )}
                        <MDBBtn color="success" onClick={onOk} ref={okButtonRef}>
                            {props.okButtonText ? props.okButtonText : 'OK'}
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

export default Confirm
