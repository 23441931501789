import * as React from 'react'
import { useState } from 'react'
import { MDBBtn, MDBInput, MDBModalHeader, MDBModalTitle, MDBSpinner } from 'mdb-react-ui-kit'
import { MDBModal, MDBModalDialog, MDBModalContent, MDBModalBody } from 'mdb-react-ui-kit'
import Icon from './Icon'
import utility from '../utility/utility'
import apiManager from '../utility/apiManager'
import './LoginModal.scss'

const LoginModal = (props: any) => {
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [success, setSuccess] = useState(false)

    const handleSubmit = async (event: any) => {
        event.preventDefault()

        setLoading(true)

        const response = await apiManager.signUp(event?.target?.email?.value, event?.target?.name?.value)

        setLoading(false)

        if (response?.signUp?.user?.id) {
            const cookieName = utility.stringBetween(response?.signUp?.cookie, '', '=', true)
            const cookieValue = utility.stringBetween(response?.signUp?.cookie, '=', '', true)

            if (cookieName && cookieValue) {
                utility.setCookie(cookieName, cookieValue)
            }

            setSuccess(true)
            setErrorMessage('')

            props.onLogin(response?.signUp?.user)
        } else {
            let message = utility.getNested(response, ['errors', 0, 'message'])

            if (!message) {
                message = 'Something went wrong'
            }

            setErrorMessage(message)
        }
    }

    return (
        <MDBModal
            show={props.loginModal}
            getOpenState={(e: any) => props.setLoginModal(e)}
            className="login-modal"
            tabIndex="-1"
        >
            <MDBModalDialog scrollable centered size="md">
                <MDBModalContent>
                    <MDBModalHeader className="text-center">
                        <MDBBtn className="btn-close" color="none" style={{ opacity: 0, cursor: 'default' }}></MDBBtn>
                        <MDBModalTitle>Create account</MDBModalTitle>
                        <MDBBtn className="btn-close" color="none" onClick={props.onCloseLoginModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody className="text-center">
                        <div>
                            With an account, you can like quotes
                            <br /> and save your custom quotes
                        </div>
                        {!success && (
                            <form className="mx-auto mt-3" onSubmit={handleSubmit}>
                                <div>
                                    <Icon icon="envelope" className="text-muted me-2" />
                                    <MDBInput name="email" label="Your email*" type="email" size="lg" required />
                                </div>

                                <div className="mt-3">
                                    <Icon icon="user" className="text-muted me-2" />
                                    <MDBInput name="name" label="Your name" type="text" size="lg" />
                                </div>

                                <div className="d-grid">
                                    <MDBBtn
                                        className="mt-3"
                                        color="success"
                                        size="lg"
                                        type="submit"
                                        disabled={loading || success}
                                    >
                                        {loading && <MDBSpinner size="sm" role="status" tag="span" className="me-2" />}
                                        {!loading && !success && <Icon icon="arrow-right" />}
                                        {!loading && !success && 'Create account'}
                                        {loading && !success && 'Loading...'}
                                        {success && 'Success'}
                                    </MDBBtn>
                                </div>
                            </form>
                        )}

                        {errorMessage && (
                            <div className="alert alert-warning mt-4" role="danger">
                                {errorMessage}
                            </div>
                        )}

                        {success && (
                            <>
                                <div className="alert alert-success" role="success">
                                    Success! We'll email you a password. You can now like and save quotes.
                                </div>
                                <MDBBtn color="primary" size="lg" className="mb-2" onClick={props.onCloseLoginModal}>
                                    Close
                                </MDBBtn>
                            </>
                        )}

                        {!success && (
                            <p className="small text-center text-muted mt-4">
                                Already have an account?{' '}
                                <a href="/my-account" className="fw-bold text-body">
                                    <u>Login here</u>
                                </a>
                            </p>
                        )}
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}

export default LoginModal
